import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import classes from "./completedAppraisal.module.css";
import less from "../../Assets/less.svg";
import next from "../../Assets/next.svg";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import EmployeeImg from "../../Assets/EmployeeImg.png"
import eye from "../../Assets/eye.svg"
import edit from "../../Assets/edit.svg"
import Select from 'react-select';
import deleteimg from "../../Assets/deleteimg.svg"
import { Link } from "react-router-dom";
import search from '../../Assets/search.svg';
import AppraisalCreation from "../../Components/AppraisalCreation/AppraisalCreation";
// import EditAppraisal from "../../Components/EditAppraisal/EditAppraisal";
// import ViewAppraisal from "../../Components/viewAppraisal/viewAppraisal";
import ViewCompletedAppraisals from "../../Components/ViewCompletedAppraisal/ViewCompletedAppraisal";


const CompletedAppraisal = () => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [optionChange, setOptionChange] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);


    const [isModalOpen1, setIsModalOpen1] = useState(false);

    const openModal1 = () => setIsModalOpen1(true);
    const closeModal1 = () => setIsModalOpen1(false);


    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const editModal = () => setIsEditModalOpen(true);
    const closeEditModal = () => setIsEditModalOpen(false);



    const openDeleteModal = (index) => {
        // setDeleteIndex(index);
        setIsDeleteModalOpen(true); 
    };

    const closeDeleteModal = () => {
        // setDeleteIndex(null);
        setIsDeleteModalOpen(false);
    };


    const options1234 = [
         {value: 'All', Label: 'All'},
         {value: 'Submitted', Label: 'Submitted'},
         {value: 'Not Submitted', Label: 'Not Submitted'}
    ]
    const handleOptionChange = (selectedOption) => {
        setOptionChange(selectedOption);
    }
    const customStyles = {
        control: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',    
          height: '40px',          
          width: '180px',
          border: '1px solid #6C757D',
          borderRadius: '4px',
          padding: '0',
          boxSizing: 'border-box', 
          fontSize: '14px',
          cursor: 'pointer',
        }),
        input: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          alignSelf: 'center',     
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: '0 15px',      
          height: '100%',     
        }),
        singleValue: (provided) => ({
          ...provided,
          margin: '0',            
          padding: '0',         
        }),
        placeholder: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          lineHeight: 'normal',
        }),
      };
      


    return (
        <>
            <TopNavBar />
            <div className={classes.toTUpper}>
                <AppraisalCreation isOpen={isModalOpen} onClose={closeModal} />
                {/* <EditAppraisal isOpen={isModalOpen1} onClose={closeModal1} /> */}
                <ViewCompletedAppraisals isOpen={isEditModalOpen} onClose={closeEditModal} />
                <div className={classes.topperText}>
                    <h4>Appraisals</h4>
                    <div className={classes.firstBtn}>
                        <button onClick={openModal} className={classes.policyBtn}>Create New Appraisal</button>
                    </div>
                </div>
                <div className={classes.topt}>
                    <Link to={'/appraisal'}><p>New Appraisals</p></Link>
                    {/* <Link to={'/appraisal'}><p>Appraisals Submissions</p></Link> */}
                    {/* <Link to={'/appraisal'}><p>Appraisals Meeting</p></Link> */}
                    <Link to={'/completed_appraisal'}><p>Completed Appraisals</p></Link>
                </div>
                <div className={classes.topLeave}>
                    <div>
                        <div className={classes.topTextDiv}>
                            <div>
                            <h4 className={classes.topText}>Completed Appraisals</h4>
                            </div>

                            <div className={classes.align}>
                            <img src={search} alt='' />
                            <input
                                type='text'
                                placeholder='Search employee name'
                            />
                             <button>Search</button>
                            </div>

                            <div className={classes.filterDesgn}>
                            <p className={classes.moveFilterText}>Filter By Submission: </p>

                            <Select
                                className={classes.selectGroupInfo}
                                name='department'
                                isClearable
                                placeholder="Submitted"
                                value={optionChange}
                                styles={customStyles}
                                options={options1234}
                                onChange={handleOptionChange}
                            />
                            </div>
                        </div>

                        <div style={{ height: '1px', width: '107%', border: '1px solid #E9ECEF', marginLeft: '-39px' }}></div>

                        <div className={classes.tableContainer}>
                            <table className={classes.tableDiv}>
                                <tr className={classes.tableHeadDiv}>
                                    <th className={classes.tableHeadRow}>S/N</th>
                                    <th className={classes.tableHeadRow}>Employee Picture</th>
                                    <th className={classes.tableHeadpflDiv}>Employee Name</th>
                                    <th className={classes.tableHeadpflDiv}>Title</th>
                                    <th className={classes.tableHeadpflDiv}>Department</th>
                                    <th className={classes.tableHeadpflDiv}>Created on</th>
                                    {/* <th className={classes.tableHeadRow}>Start date</th> */}
                                    {/* <th className={classes.tableHeadRow}>End Date</th> */}
                                    {/* <th className={classes.tableHeadRow}>Job Type</th> */}
                                    {/* <th className={classes.tableHeadRow}>No. of Applicants</th> */}
                                    <th className={classes.tableHeadRow}>Action</th>
                                </tr>
                                <tr className={classes.tableRowBody}>
                                    <td>1</td>

                                    <td className={classes.pflDivImg}><img src={EmployeeImg} alt="" /></td>
                                    <td className={classes.pflDiv}>Amanda Marcel</td>
                                    <td className={classes.pflDiv}>Customer Care Manager</td>
                                    <td className={classes.pflDiv}>Customer Service</td>
                                    <td className={classes.pflDiv}>15/09/2024</td>
                                    <td className={classes.formEye}>
                                        <img src={eye} onClick={editModal} alt="approve" />
                                        {/* <img src={edit} onClick={openModal1} alt="approve" />
                                        <img src={deleteimg} alt="Delete" onClick={openDeleteModal} /> */}
                                    </td>
                                </tr>
                                <tr className={classes.tableRowBody}>
                                    <td>2</td>

                                    <td className={classes.pflDivImg}><img src={EmployeeImg} alt="" /></td>
                                    <td className={classes.pflDiv}>Timothy Owolabi</td>
                                    <td className={classes.pflDiv}>Accountant</td>
                                    <td className={classes.pflDiv}>Accounting</td>
                                    <td className={classes.pflDiv}>15/09/2024</td>
                                    <td className={classes.formEye}>
                                        <img src={eye} onClick={editModal} alt="approve" />
                                        {/* <img src={edit} onClick={openModal1} alt="approve" />
                                        <img src={deleteimg} alt="Delete" onClick={openDeleteModal} /> */}
                                    </td>
                                </tr>
                                <tr className={classes.tableRowBody}>
                                    <td>3</td>

                                    <td className={classes.pflDivImg}><img src={EmployeeImg} alt="" /></td>
                                    <td className={classes.pflDiv}>Catherine Bassey</td>
                                    <td className={classes.pflDiv}>Customer Care Manager</td>
                                    <td className={classes.pflDiv}>Customer Service</td>
                                    <td className={classes.pflDiv}>15/09/2024</td>
                                    <td className={classes.formEye}>
                                        <img src={eye} onClick={editModal} alt="approve" />
                                        {/* <img src={edit} onClick={openModal1} alt="approve" />
                                        <img src={deleteimg} alt="Delete" onClick={openDeleteModal} /> */}
                                    </td>
                                </tr>
                                <tr className={classes.tableRowBody}>
                                    <td>4</td>

                                    <td className={classes.pflDivImg}><img src={EmployeeImg} alt="" /></td>
                                    <td className={classes.pflDiv}>Amanda Marcel</td>
                                    <td className={classes.pflDiv}>Customer Care Manager</td>
                                    <td className={classes.pflDiv}>Customer Service</td>
                                    <td className={classes.pflDiv}>15/09/2024</td>
                                    <td className={classes.formEye}>
                                        <img src={eye} onClick={editModal} alt="approve" />
                                        {/* <img src={edit} onClick={openModal1} alt="approve" />
                                        <img src={deleteimg} alt="Delete" onClick={openDeleteModal} /> */}
                                    </td>
                                </tr>
                                <tr className={classes.tableRowBody}>
                                    <td>5</td>

                                    <td className={classes.pflDivImg}><img src={EmployeeImg} alt="" /></td>
                                    <td className={classes.pflDiv}>Amanda Marcel</td>
                                    <td className={classes.pflDiv}>Customer Care Manager</td>
                                    <td className={classes.pflDiv}>Customer Service</td>
                                    <td className={classes.pflDiv}>15/09/2024</td>
                                    <td className={classes.formEye}>
                                        <img src={eye} onClick={editModal} alt="approve" />
                                        {/* <img src={edit} onClick={openModal1} alt="approve" />
                                        <img src={deleteimg} alt="Delete" onClick={openDeleteModal} /> */}
                                    </td>
                                </tr>
                                <tr className={classes.tableRowBody}>
                                    <td>6</td>

                                    <td className={classes.pflDivImg}><img src={EmployeeImg} alt="" /></td>
                                    <td className={classes.pflDiv}>Amanda Marcel</td>
                                    <td className={classes.pflDiv}>Customer Care Manager</td>
                                    <td className={classes.pflDiv}>Customer Service</td>
                                    <td className={classes.pflDiv}>15/09/2024</td>
                                    <td className={classes.formEye}>
                                        <img src={eye} onClick={editModal} alt="approve" />
                                        {/* <img src={edit} onClick={openModal1} alt="approve" />
                                        <img src={deleteimg} alt="Delete"onClick={openDeleteModal}  /> */}
                                    </td>
                                </tr>
                                <tr className={classes.tableRowBody}>
                                    <td>7</td>

                                    <td className={classes.pflDivImg}><img src={EmployeeImg} alt="" /></td>
                                    <td className={classes.pflDiv}>Amanda Marcel</td>
                                    <td className={classes.pflDiv}>Customer Care Manager</td>
                                    <td className={classes.pflDiv}>Customer Service</td>
                                    <td className={classes.pflDiv}>15/09/2024</td>
                                    <td className={classes.formEye}>
                                        <img src={eye} onClick={editModal} alt="approve" />
                                        {/* <img src={edit} onClick={openModal1} alt="approve" />
                                        <img src={deleteimg} alt="Delete" onClick={openDeleteModal} /> */}
                                    </td>
                                </tr>
                                <tr className={classes.tableRowBody}>
                                    <td>8</td>

                                    <td className={classes.pflDivImg}><img src={EmployeeImg} alt="" /></td>
                                    <td className={classes.pflDiv}>Amanda Marcel</td>
                                    <td className={classes.pflDiv}>Customer Care Manager</td>
                                    <td className={classes.pflDiv}>Customer Service</td>
                                    <td className={classes.pflDiv}>15/09/2024</td>
                                    <td className={classes.formEye}>
                                        <img src={eye} onClick={editModal} alt="approve" />
                                        {/* <img src={edit} onClick={openModal1} alt="approve" />
                                        <img src={deleteimg} alt="Delete" onClick={openDeleteModal} /> */}
                                    </td>
                                </tr>
                                <tr className={classes.tableRowBody}>
                                    <td>9</td>

                                    <td className={classes.pflDivImg}><img src={EmployeeImg} alt="" /></td>
                                    <td className={classes.pflDiv}>Amanda Marcel</td>
                                    <td className={classes.pflDiv}>Customer Care Manager</td>
                                    <td className={classes.pflDiv}>Customer Service</td>
                                    <td className={classes.pflDiv}>15/09/2024</td>
                                    <td className={classes.formEye}>
                                        <img src={eye} onClick={editModal} alt="approve" />
                                        {/* <img src={edit} onClick={openModal1} alt="approve" />
                                        <img src={deleteimg} alt="Delete" onClick={openDeleteModal} /> */}
                                    </td>
                                </tr>
                                <tr className={classes.tableRowBody}>
                                    <td>10</td>

                                    <td className={classes.pflDivImg}><img src={EmployeeImg} alt="" /></td>
                                    <td className={classes.pflDiv}>Amanda Marcel</td>
                                    <td className={classes.pflDiv}>Customer Care Manager</td>
                                    <td className={classes.pflDiv}>Customer Service</td>
                                    <td className={classes.pflDiv}>15/09/2024</td>
                                    <td className={classes.formEye}>
                                        <img src={eye} onClick={editModal} alt="approve" />
                                        {/* <img src={edit} onClick={openModal1} alt="approve" />
                                        <img src={deleteimg} alt="Delete" onClick={openDeleteModal} /> */}
                                    </td>
                                </tr>


                            </table>
                        </div>
                        <div className={classes.gotoNext}>
                            <button className={classes.prev}>
                                <img src={less} alt="less" />
                                Previous
                            </button>
                            <p className={classes.pagination}>Page 1 of 5</p>
                            <button className={classes.next}>
                                Next
                                <img src={next} alt="next" />
                            </button>
                        </div>
                        {/* <Modal
                            isOpen={isDeleteModalOpen}
                        >
                            <div className={classes.modalOverlayWhole}>
                                <div className={classes.modalErrYester}>
                                    <button className={classes.closeButtonModally} onClick={closeDeleteModal}>
                                        <img src={CloseX} alt='' />
                                    </button>
                                    <div className={classes.modalContainerSend}>
                                        <h6>
                                            Delete Employee
                                        </h6>
                                     
                                                <p>
                                                    You are about to delete this appraisal,
                                                    this action cannot be reversed, are you sure you want to continue?
                                                </p>
                                                <button
                                                    type="submit"
                                                    className={classes.addTeamBtn}
                                                >
                                                    Delete
                                                </button>
                                          
                                    </div>
                                </div>
                            </div>
                        </Modal> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CompletedAppraisal;
