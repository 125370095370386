import React, { useState, useEffect } from 'react';
import classes from './ApplicationRejection.module.css';
import cancel from "../../Assets/cancel.svg";
import { BASE_URL } from '../../Pages/api/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const ApplicationRejection = ({ isOpen, onClose, userId }) => {
    const [animate, setAnimate] = useState(false);
    const navigate = useNavigate();
    const [bearer, setBearer] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    
    const readData = async () => {
      try {
        const value = await AsyncStorage.getItem('tokens');
        if (value !== null) {
          setBearer(value);
        }
      } catch (e) {
        alert('Failed to fetch the token from storage');
      }
    };
  
    const handleRejection = async (e) => {
      e.preventDefault(); // Prevent form submission
      setIsLoading(true);
      
      try {
        const response = await axios.get(`${BASE_URL}/job/rejectedCandidate`, {
          params: {"applicant_id": userId},
          headers: {
            Authorization: `Bearer ${bearer}`,
            'Content-Type': 'application/json'
          },
        });
        
        toast.success(response?.data?.message);
        onClose(); // Close the modal after successful rejection
        
      } catch (error) {
        if (error.response?.status === 401) {
          navigate('/login');
        } else {
          const errorMessage = error.response?.data?.message || 'An error occurred';
          toast.error(errorMessage);
        }
      } finally {
        setIsLoading(false);
      }
    };
  
    useEffect(() => {
      readData();
    }, []);
    
    useEffect(() => {
        if (isOpen) {
            const timer = setTimeout(() => {
                setAnimate(true);
            }, 500);
            return () => clearTimeout(timer);
        } else {
            setAnimate(false);
        }
    }, [isOpen]);
    
    if (!isOpen) return null;
    
    return (
      <div className={classes.modalOverlayWhole31}>
        <div className={classes.modalErrYes31}>
          <button className={classes.closeButtonModally31} onClick={onClose}>
            <img src={cancel} alt='' />
          </button>
          <form onSubmit={handleRejection} className={classes.modalContainer231}>
            <h6>Send Rejection email</h6>
            <p>You are about to reject this candidate for the job position, are you sure you want to continue?</p>
            <button 
              type="submit" 
              className={classes.addTeamBtn31} 
              disabled={isLoading}
            >
              {isLoading ? 'Rejecting...' : 'Reject'}
            </button>
          </form>
        </div>
      </div>
    );
}

export default ApplicationRejection;