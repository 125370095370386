import React, { useState, useEffect } from 'react';
import styles from './ViewTask.module.css';
import TopNavBar from '../../Components/TopNavBar/TopNavBar';
import TaskManagmentSideNav from '../../Components/TaskManagmentSideNav/TaskManagmentSideNav';
import person from '../../Assets/person.svg';
import attachment from '../../Assets/attachment.svg';
import Select from 'react-select';
import faces from '../../Assets/EmployeeImg.png'
import close from './CloseX.svg'

const ViewTask = ({ isOpen, onClose }) => {
    const [animate, setAnimate] = useState(false);
    const [activeView, setActiveView] = useState('Board');
    const [showModal, setShowModal] = useState(false);
    const [selectedPriority, setSelectedPriority] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedValue, setSelectedValue] = useState(null); 
    const [selectedImages, setSelectedImages] = useState([]);

    useEffect(() => {
        if (isOpen) {
            // Delay to give a smooth entrance when the modal opens
            const timer = setTimeout(() => {
                setAnimate(true);
            }, 500); // Adjust delay as needed

            return () => clearTimeout(timer);
        } else {
            setAnimate(false); // Reset animation when the modal is closed
        }
    }, [isOpen]);

    if (!isOpen) return null;



    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        const imagesArray = files.map(file => URL.createObjectURL(file)); // Create object URLs for the images
        setSelectedImages((prevImages) => prevImages.concat(imagesArray));
        e.target.value = null; // Reset the input value to allow re-uploading the same file
    };

    const handleTaskCreation = (e) => {
        e.preventDefault();
        // Logic for creating a new task goes here
        console.log("New task created!"); // Placeholder for task creation logic
        setShowModal(false); // Close modal after task creation
    };
    const handlePriorityChange = (selected) => {
        setSelectedPriority(selected);
    };
    const priorityOptions = [
        { value: 'low', label: 'Low' },
        { value: 'medium', label: 'Medium' },
        { value: 'high', label: 'High' },
    ];


    const options = [
        { value: 'task1', label: 'Task 1' },
        { value: 'task2', label: 'Task 2' },
        { value: 'task3', label: 'Task 3' },
    ];

    const handleChange = (option) => {
        setSelectedOption(option);
    };

   

    const handleRadioChange = (value) => {
      if (selectedValue === value) {
        setSelectedValue(null); // Unselect if already selected
      } else {
        setSelectedValue(value); // Select the clicked option
      }
    };
    const customStyles = {
        control: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',    
          height: '50px',          
          width: '300px',
          border: '1px solid #6C757D',
          borderRadius: '4px',
          padding: '0',
          boxSizing: 'border-box', 
          fontSize: '14px',
          cursor: 'pointer',
          marginBottom: '10px'
        }),
        input: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          alignSelf: 'center',     
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: '0 15px',      
          height: '100%',     
        }),
        singleValue: (provided) => ({
          ...provided,
          margin: '0',            
          padding: '0',         
        }),
        placeholder: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          lineHeight: 'normal',
          fontSize: '14px',
          fontWeight: '400',
        }),
      };
      
  return (
    <div>
      <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <span className={styles.close} onClick={onClose}><img src={close}></img></span>
                        <h3>View Task</h3>
                        <div style={{ height: '1px', width: '100%', border: '1px solid #E9ECEF',}}></div>
                        <div className={styles.formmodal}>
                            <p>Title of Task</p>
                            <input type="text" placeholder='Marketing campaign for new product' disabled    />
                        </div>
                        <div className={styles.tasks}>
                            <h4><img src={person} alt='' /> Assign Task</h4>
                            <div className={styles.imagestask}>
                                <div className={styles.firstimage}>
                                    <img src={faces} alt="" />
                                    <p>Amanda Marcel</p>
                                </div>

                                <div className={styles.secondimage}>
                                    <img src={faces} alt="" />
                                    <p>John Doe</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.describe}>
                            <p>Description of Task</p>
                            <div className={styles.describefields}>
                                <textarea className={styles.textarea} placeholder="Enter task details..." />
                                <div className={styles.dropdown}>
                                    <p>Select Bucket</p>
                                    <Select
                                        value={selectedOption}
                                        onChange={handleChange}
                                        options={options}
                                        placeholder="Choose a task..."
                                        styles={customStyles}
                                    />
                                    <p>Priority</p>
                                    <Select
                                        value={selectedPriority}
                                        onChange={handlePriorityChange}
                                        options={priorityOptions}
                                        placeholder="Choose priority..."
                                        styles={customStyles}
                                    />
                                    {/* <div className={styles.dateFields}> */}
                                        <div className={styles.dateField}>
                                            <p>Start Date</p>
                                            <input
                                                type="date"
                                                className={styles.dateInput}
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                            />
                                        </div>
                                        <div className={styles.dateField}>
                                            <p>End Date</p>
                                            <input
                                                type="date"
                                                className={styles.dateInput}
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                            />
                                        </div>
                                    {/* </div> */}
                                </div>

                            </div>
                        </div>
                  
                                            <div 
                    className={styles.addattachment} 
                    onClick={() => document.getElementById('file-upload').click()}
                    >
                    <p>
                        <img src={attachment} alt='' />Add Attachment
                    </p>
                    <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleImageChange}
                        style={{ display: 'none' }} 
                        id="file-upload" 
                    />
                    </div>


                        <div className={styles.imagePreview}>
                            {selectedImages.map((image, index) => (
                                <img key={index} src={image} alt={`Uploaded preview ${index}`} className={styles.image} />
                            ))}
                        </div>
                        <div className={styles.comments}>
                            <div className={styles.commentsstyle}>
                                <p>Comment</p>
                                <textarea className={styles.textarea2} placeholder="write a comment here ..." />
                            </div>
                        </div>
                        <button className={styles.policyBtn2} onClick={() => setShowModal(true)}>
                            Send
                        </button>
                         <div className={styles.Textstyle}>
                                <label>Michael Lanre</label>
                                <p>For this new product, we need to make it bigger than our previous product,a nd I mean way bigger, and better than the last one, I want all of us to research on how we can promote this product  to reach
                                as many people as people, talk to influencers, celebrities, marketers, anybody you can talk to before our meeting next week</p>
                                <p className={styles.OtherTextDecor}>09/07/2024 - 09:39am</p>
                            </div>


                         <div className={styles.Textstyle1}>
                                <label>Amanda Marcel</label>
                               <p>Is there any restrictions to the scope of the marketing for this product?</p>
                                <p className={styles.OtherTextDecor}>09/07/2024 - 09:34am</p>
                            </div>
                    </div>
                </div>
       
    </div>
  )
}

export default ViewTask
