import React, { useState, useEffect } from "react";
import classes from "../Add Employee Modal/AddEmpModal.module.css";
import closeX from "./CloseX.svg";
import axios from "axios";
import Select from "react-select";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ToastContainer, toast } from 'react-toastify'; // Import toast components
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles

const AddEmployeeModal = ({ isOpen, onClose, onEmployeeAdded }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    surname: "",
    email: "",
    phone_number: "",
    hire_date: "",
    title: "",
    department: null,
    designation: null,
  });
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [error, setError] = useState("");
  const [bearer, setBearer] = useState("");

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("tokens");

      if (value !== null) {
        setBearer(value);
      }
    } catch (e) {
      toast.error("Failed to fetch the input from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  useEffect(() => {
    if (isOpen) {
      setFormData({
        firstName: "",
        surname: "",
        email: "",
        phone_number: "",
        // password: "",
        // confirmPassword: "",
        hire_date: "",
        title: "",
        department: null,
      });
      setError("");
    }
  }, [isOpen]);

  useEffect(() => {
    const fetchDepartments = async () => {
      if (!bearer) return;

      try {
        const response = await axios.get(
          "https://hr-api.emas.ng/api/department/fetchAll",
          {
            headers: {
              Authorization: `Bearer ${bearer}`,
            },
          }
        );

        const list = response.data.data;
        setOptions1(
          list.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, [bearer]);


  useEffect(() => {
    const fetchDesignation = async () => {
      if (!bearer) return;

      try {
        const response = await axios.get(
          "https://hr-api.emas.ng/api/designation/fetchAll",
          {
            headers: {
              Authorization: `Bearer ${bearer}`,
            },
          }
        );

        const list = response.data.data;
        setOptions2(
          list.map((item) => ({
            label: item.title,
            value: item.id,
          }))
        );
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDesignation();
  }, [bearer]);

  const handleChange = (e) => {
    if (e && e.target) {
      setError("");
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSelectChange = (selectedOption, name) => {
    setError("");
    setFormData({ ...formData, [name]: selectedOption });
  };


  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const {
      firstName,
      surname,
      email,
      phone_number,
      // password,
      // confirmPassword,
      hire_date,
      title,
      department,
      designation,
    } = formData;

    if (
      !firstName ||
      !surname ||
      !email ||
      !phone_number ||
      // password ||
      // confirmPassword ||
      !hire_date ||
      !title ||
      !department
    ) {
      setError("All fields are required");
      setLoading(false);
      return;
    }

    // if (password !== confirmPassword) {
    //   setError("Passwords do not match");
    //   setLoading(false);
    //   return;
    // }

    try {
      const response = await axios.post(
        "https://hr-api.emas.ng/api/employees/create",
        {
          first_name: firstName,
          last_name: surname,
          email: email,
          phone_number: phone_number,
          // password: password,
          join_date: hire_date,
          position: title,
          company_id: 1,
          department_id: department.value,
          designation_id: designation.id,
        },
        {
          headers: {
            Authorization: `Bearer ${bearer}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Employee added successfully!"); // Show success toast
        onEmployeeAdded();
        onClose();
      }
    } catch (error) {
      console.error("Error adding employee:", error);

      if (error.response) {
        let errorMessage = "An error occurred. Please try again.";
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        setError(`${errorMessage}`);
        toast.error(errorMessage); // Show error toast
      } else {
        setError("Failed to add employee");
        toast.error("Failed to add employee"); // Show error toast
      }
    } finally {
      setLoading(false);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      width: '100%',
      border: '1px solid #6C757D',
      borderRadius: '4px',
      padding: '0',
      boxSizing: 'border-box',
      fontSize: '14px',
      cursor: 'pointer',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
      alignSelf: 'center',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 15px',
      height: '100%',
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
    }),
    placeholder: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
      lineHeight: 'normal',
    }),
  };

  if (!isOpen) return null;

  return (
    <div className={classes.modalOverlayWhole}>
      <ToastContainer /> {/* Include ToastContainer */}
      <div className={classes.modalErrYes}>
        <button className={classes.closeButtonModally} onClick={onClose}>
          <img src={closeX} alt="" />
        </button>
        <div className={classes.modalContainer2}>
          <h6>Add Employee</h6>
          <form onSubmit={handleSubmit}>
            <div className={classes.classGroupCont}>
              <div className={classes.labelandInput}>
                <label>First Name</label>
                <input
                  type="text"
                  name="firstName"
                  placeholder="Enter First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={classes.labelandInput}>
                <label>Last Name</label>
                <input
                  type="text"
                  name="surname"
                  placeholder="Enter Last Name"
                  value={formData.surname}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={classes.labelandInput}>
                <label>Email Address</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter email address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={classes.labelandInput}>
                <label>Phone Number</label>
                <input
                  type="text"
                  name="phone_number"
                  placeholder="Enter phone number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            {/* <div className={classes.classGroupCont}>
              <div className={classes.labelandInput}>
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={classes.labelandInput}>
                <label>Confirm Password</label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
              </div>
            </div> */}
            <div className={classes.classGroupCont}>
              <div className={classes.labelandInput}>
                <label>Joining Date</label>
                <input
                  type="date"
                  name="hire_date"
                  value={formData.hire_date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={classes.labelandInput}>
                <label>Title</label>
                <Select
                  name="title"
                  options={options2}
                  value={formData.title} // Adjust to reference the title from formData
                  onChange={(selectedOption) => handleSelectChange(selectedOption, "title")} // Pass field name
                  placeholder="Select designations"
                  required
                />
              </div>
              <div className={classes.labelandInput}>
                <label>Department</label>
                <Select
                  name="department"
                  options={options1}
                  value={formData.department}
                  onChange={(selectedOption) => handleSelectChange(selectedOption, "department")} // Pass field name
                  styles={customStyles}
                  placeholder="Select department"
                  required
                />
              </div>
            </div>
            {error && <div className={classes.errorMessage}>{error}</div>}
            <button className={classes.addTeamBtn} type="submit" disabled={loading}>
              {loading ? "Adding employee..." : "Add Employee"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEmployeeModal;
