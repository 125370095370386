import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import classes from '../SignUp_Admin/signAdmin.module.css';
import loginImg from '../../Assets/LoginImgImg.png';
import axios from 'axios';
import { BASE_URL } from '../api/api';
import { GoEyeClosed } from "react-icons/go";
import { RxEyeOpen } from "react-icons/rx";
import ErrorModalSign from '../../Components/ErrorModal Sign/ErrorModalSign';
import spinner from '../../Assets/spinnerT.gif';
import { ToastContainer, toast } from 'react-toastify';

function SignUp_Admin() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [messageColor, setMessageColor] = useState('');
    const [showMessage, setShowMessage] = useState('');
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    useEffect(() => {
        if (isModalOpen) {
            const timer = setTimeout(() => {
                setIsModalOpen(false);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [isModalOpen]);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        if (id === 'first-name') setFirstName(value);
        if (id === 'last-name') setLastName(value);
        if (id === 'email') setEmail(value);
        if (id === 'password') setPassword(value);
        // if (id === 'company-address') setCompanyAddress(value);
        // if (id === 'phone-number') setPhoneNumber(value);
    };

    const handleSignUp = async () => {
        setLoading(true);
        setShowMessage('');
        try {
            const response = await axios.post(`https://hr-api.emas.ng/api/company/create`, {
                first_name: firstName,
                last_name: lastName,
                // company_address: companyAddress,
                password: password,
                email: email,
                
                // phone_number: phoneNumber,
            });

            if (response.status === 200) {
                setShowMessage('Sign-Up Successful');
                setMessageColor('#2D995F');
                navigate('/organization');
                toast.success(response?.data?.message || 'Signup successful');
            }
        } catch (error) {
            console.log(error)
             if (error.response) {
                         if (error.response.status === 400) {
                            setShowMessage('Invalid email or password. Please check your credentials.');
                             toast.error('Invalid email or password. Please check your credentials.')
                         } else if (error.response.status === 401) {
                            setShowMessage('Unauthorized. Please check your credentials.');
                             toast.error('Unauthorized. Please check your credentials.')
                         } else {
                            setShowMessage(`Signup failed: ${error.response.data.message || 'An error occurred.'}`);
                             toast.error(`Signup failed: ${error?.response?.data?.message || 'An error occurred.'}`)
                         }
                     } else if (error.request) {
                         // The request was made but no response was received
                         setShowMessage('No response from server. Please try again later.');
                     } else {
                        setShowMessage(`Error: ${error.message}`);
                         toast.error(`Error: ${error.message}`);
                     }
                     toast.error(error)
         } finally {
            setLoading(false);
        }
    };

    return (
        <div className={classes.general}>
            <div className={classes.imgSpace}>
                <img src={loginImg} className={classes.LoginImg} alt="Login" />
            </div>
            <div className={classes.inputField}>
                <span>
                    <p>H</p>
                    <p className={classes.rRed}>R</p>
                    <p>M</p>
                </span>
                <div className={classes.botttomFlex}>
                    <div className={classes.Inpcontaer1}>
                        <h4>Sign Up</h4>
                        <p>Sign up as an Organization</p>
                        <div className={classes.RowDivFunction}>
                            <div className={classes.inputContainer1}>
                                <div className={classes.Inputcnt1}>
                                    <label htmlFor='first-name'>Enter First Name</label>
                                    <input
                                        type='text'
                                        id='first-name'
                                        placeholder='Enter your first name'
                                        value={firstName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className={classes.inputContainer1}>
                                <div className={classes.Inputcnt1}>
                                    <label htmlFor='last-name'>Enter Last Name</label>
                                    <input
                                        type='text'
                                        id='last-name'
                                        placeholder='Enter your last name'
                                        value={lastName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.RowDivFunction}>
                            <div className={classes.inputContainer1}>
                                <div className={classes.Inputcnt1}>
                                    <label htmlFor='email'>Email address</label>
                                    <input
                                        type='text'
                                        id='email'
                                        placeholder='Johndoe@gmail.com'
                                        value={email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className={classes.inputContainer1}>
                                <div className={classes.Inputcnt1}>
                                    <label htmlFor='password'>Password</label>
                                    <input
                                         type={showPassword ? 'text' : 'password'}
                                        id='password'
                                        value={password}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <p
                                    onClick={togglePasswordVisibility}>
                                    {showPassword ? (
                                        <GoEyeClosed />
                                    ) : (
                                        <RxEyeOpen />
                                    )}
                                </p>
                                </div>
                            </div>
                        </div>
                        {/* <div className={classes.RowDivFunction}>
                        <div className={classes.inputContainer1}>
                            <div className={classes.Inputcnt1}>
                                <label htmlFor='company-address'>Company Address</label>
                                <input
                                    type='text'
                                    id='company-address'
                                    placeholder='Enter your company address'
                                    value={companyAddress}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className={classes.inputContainer1}>
                            <div className={classes.Inputcnt1}>
                                <label htmlFor='phone-number'>Phone Number</label>
                                <input
                                    type='text'
                                    id='phone-number'
                                    placeholder='Enter your phone number'
                                    value={phoneNumber}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                        </div> */}
                        <button
                            style={{ backgroundColor: loading ? '#c4c4c4' : '#2D995F' }}
                            disabled={loading}
                            className={classes.loginBtnSign}
                            onClick={handleSignUp}
                        >
                            {loading && (<img src={spinner} alt='' className={classes.spinnerStyle} />)}
                            {loading ? 'Signing Up...' : 'Create your HRM Account'}
                        </button>
                        {/* <p style={{ fontWeight: 900, color: messageColor }}>{showMessage}</p> */}
                       
                        <div className={classes.accountthing}>
                            <p>Already have an account? <NavLink to='/'>Login</NavLink></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUp_Admin;
