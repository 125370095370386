import React, { useState } from 'react';
import styles from './TaskManagmentSideNav.module.css';
import add from './add-circle.svg';
import g from './G.svg';
import m from './M.svg';
import h from './H.svg';
import mm from './MM.svg';
import a from './A.svg';
import l from './L.svg';

const TaskManagmentSideNav = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    

    return (
        <div className={styles.sidebar}>
            <div className={styles.nana}>
                <img src={add} alt="" />
                <h5>Create New group</h5>                
            </div>

            <ul>
                <li onClick={toggleExpand} className={styles.mainItem}>
                    All {isExpanded ? '▲' : '▼'}
                </li>
                {isExpanded && (
                    <ul className={styles.subMenu}>
                        <li className={styles.subItem}>
                            <img src={g} alt="General" />
                            <p>General</p>
                        </li>
                        <li className={styles.subItem}>
                            <img src={m} alt="Mobile Development" />
                            <p>Mobile Development</p>
                        </li>
                        <li className={styles.subItem}>
                            <img src={h} alt="Human Resources" />
                            <p>Human Resources</p>
                        </li>
                        <li className={styles.subItem}>
                            <img src={mm} alt="Marketing" />
                            <p>Marketing</p>
                        </li>
                        <li className={styles.subItem}>
                            <img src={a} alt="Accounting" />
                            <p>Accounting</p>
                        </li>
                        <li className={styles.subItem}>
                            <img src={l} alt="Logistics" />
                            <p>Logistics</p>
                        </li>
                    </ul>
                )}
            </ul>
        </div>
    );
};

export default TaskManagmentSideNav;
