import React, { useState, useEffect } from "react";
import classes from "./LeaveManagement.module.css";
import image1 from "../../Assets/Ellipse 1 (1).png";
import image2 from "../../Assets/Ellipse 1.png";
import approve from "../../Assets/approve.svg";
import decline from "../../Assets/decline.svg";
import view from "../../Assets/view.svg";
import less from "../../Assets/less.svg";
import next from "../../Assets/next.svg";
import plus from "../../Assets/plus.svg";
import axios from 'axios';
// import TopNavBar from "../../Components/ErrorModal/TopNavBar/TopNavBar";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import { Link, useNavigate } from "react-router-dom";
import LeaveRequest from '../LeaveRequest/LeaveRequest'
import CreatePolicy from "../CreatePolicy/CreatePolicy";
import AddLeave from "../AddLeave/AddLeave";
import { BASE_URL } from '../api/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Modal from '../Modal/Modal'
import ModalDecline from "../ModalDecline/ModalDecline";


const LeaveManagement = () => {
  const navigate = useNavigate();
  const [showRequest, setShowRequest] = useState(false)
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [showPolicy, setShowPolicy] = useState(false)
  const [showLeave, setShowLeave] = useState(false)
  const [pendingHandler, setPendingHandler] = useState([]);
const [filteredPendingHandler, setFilteredPendingHandler] = useState([]);

  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [approved, setApproved] = useState('')
  const [disApproved, setDisApproved] = useState('')
  const [approveModal, setApprovedModal] = useState('')
  const [confrimModal, setConfirmModal] = useState(false)
  const [declineModal, setDeclineModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false);




  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('tokens');
      // console.log(value)
      if (value !== null) {
        setBearer(value);
      }
    } catch (e) {
      alert('Failed to fetch the token from storage');
    }
  };



  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };


//fetch records
const fetchBeneficiaries = async () => {
  setIsLoading(true);
  try {
    const response = await axios.get(`${BASE_URL}/leaveRequest/fetchAll`, { headers });
    const results = response.data?.data;
    setTableData(results.leave);
    console.log(results?.leave)
    setUserData(results.employees
    )
    // console.log(results .leave)
    // console.log(results);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Redirect to login page if unauthorized
      navigate('/login');
    } else {
    const errorStatus = error.response?.data?.message;
    console.log(errorStatus);
    setTableData([]);
  }
  } finally {
    setIsLoading(false);
  }
};

const getApprovedHandler = async (id) => {
  const data = {
    "id": id,
    // "id": id,
    
    };

    try {
      const response = await axios.put(`${BASE_URL}/leave/disapprove`, data, { headers });
      const results = response.data?.data
      setApproved(results)

    } catch (error) {

      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setApproved([]);
      }
    } finally {
      setIsLoading(false);

    }

  }

  const getDisApprovedHandler = async (id) => {

    const data = {
      "id": id,
    };

    try {
      const response = await axios.put(`${BASE_URL}/leave/approve`, data, { headers });
      const results = response.data?.data
      setApproved(results)

    } catch (error) {

      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setApproved([]);
      }
    } finally {
      setIsLoading(false);
      fetchBeneficiaries()

    }

  }




  useEffect(() => {

    readData();
  }, []);


  useEffect(() => {
    if (bearer) {
      fetchBeneficiaries();
    }
  }, [bearer]);


  const showPolicyHandler = () => {
    setShowPolicy(true)
  }

  const showLeaveHandler = () => {
    setShowLeave(true)
  }

  useEffect(() => {
    function closeAddLeave(event) {
      if (event.code === 'Escape') {
        setShowLeave(false)
      }
    }
    window.addEventListener('keydown', closeAddLeave)

    return () => {
      window.removeEventListener('keydown', closeAddLeave)
    }
  })

  useEffect(() => {
    function closeAddLeave(event) {
      if (event.code === 'Escape') {
        setShowRequest(false)
      }
    }
    window.addEventListener('keydown', closeAddLeave)

    return () => {
      window.removeEventListener('keydown', closeAddLeave)
    }
  })
  const employeeHandler = (items) => {
    setSelectedEmployeeId(items)
    setShowRequest(true)

  }

  // const pendingHandler = tableData.filter((items) => (
  //   items.status === "0"
  // ))

  
  const confirmHandler = (items) => {
    setConfirmModal(true)
    setApprovedModal(items)
  }

  const deleteHandler = (items) => {
    setDeclineModal(true)
    setDeleteModal(items)
  }

  return (
    <>
      {declineModal && <ModalDecline onShow={setDeclineModal} getId={deleteModal} />}
      {confrimModal && <Modal onShow={setConfirmModal} getId={approveModal} />}
      {showPolicy && <CreatePolicy onShow={setShowPolicy} />}
      {showLeave && <AddLeave onShow={setShowLeave} />}


      {showRequest && <LeaveRequest onShow={setShowRequest} employeeId={selectedEmployeeId} />}

      <TopNavBar />
      <div className={classes.toTUpper}>
        <h4 className={classes.topperText}>Leave Management</h4>
        <div className={classes.firstBtn}>
          {/* <button className={classes.addBtn} onClick={showLeaveHandler}><img src={plus}  alt="plus" />Add Leave</button> */}
        </div>
        <div className={classes.topt}>
          <Link to={'/leave_management'}> <p>Leave Request</p></Link>
          <Link to={'/leave_approved'}><p>Approved</p></Link>
          <Link to={'/leave_decline'}><p>Declined</p></Link>
          <Link to={'/leave_policy'}><p>Leave Policy</p></Link>
          <button className={classes.policyBtn} onClick={showPolicyHandler}>Create Leave Policy</button>

      </div>
      
      <div className={classes.topLeave}>
        <div>
          <div className={classes.topTextDiv}>
            <h4 className={classes.topText}>Leave Request</h4>
          </div>

          <div style={{height:'1px',width:'107%',border:'1px solid #E9ECEF',marginLeft:'-39px'}}></div>

          <table className={classes.tableDiv}>
            <tr className={classes.tableHeadDiv}>
              <th className={classes.tableHeadRow}>S/N</th>
              <th className={classes.tableHeadRow}>Employee Name</th>
              <th className={classes.tableHeadRow}>Designation</th>
              <th className={classes.tableHeadRow}>Start date</th>
              <th className={classes.tableHeadRow}>End date</th>
              <th className={classes.tableHeadRow}>Approve/Decline</th>
              <th className={classes.tableHeadRowV}>View</th>
            </tr>{
              isLoading && <p> Loading ...</p>
            }
            {
              !isLoading && pendingHandler?.length === 0 && <p>No Data Available</p>
            }
            {
              pendingHandler?.map((items,index) => (
                <tr className={classes.tableRowBody} key={items.id}>
                 
              <td>{index + 1}</td>
              <td className={classes.pflDiv}>
                <img src={image1} alt="one" />
                {`${items?.employee?.first_name} ${items?.employee?.last_name}`}
              </td>
              <td>{items?.employee?.position}</td>
              <td>{items.start_date}</td>
              <td>{items.end_date}</td>
              <td className={classes.twoImageClass}>
                <button onClick={() => confirmHandler(items.id)} style={{backgroundColor:'transparent',border:'none',width:'50px',marginLeft:'1px'}}><img src={approve} alt="approve" style={{height:'25px',width:'25px',marginRight:'12px'}}/></button>
                <button  onClick={() => deleteHandler(items.id)} style={{backgroundColor:'transparent',border:'none',width:'1px'}}><img src={decline} alt="decline" style={{height:'25px',width:'25px'}} /></button>
              </td>
              <td>
             <button style={{backgroundColor:'transparent',border:'none'}} onClick={() => employeeHandler(items.id) }><img src={view} alt="approve" style={{height:'25px',width:'25px'}}/></button>
              </td>
            </tr>
              ))
            }
          </table>
          <div className={classes.gotoNext}>
            <button className={classes.prev}>
              <img src={less} alt="less" />
              Previous
            </button>
            <p className={classes.pagination}>Page 1 of 5</p>
            <button className={classes.next}>
              Next
              <img src={next} alt="next" />
            </button>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default LeaveManagement;
