import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import classes from './AddDept.module.css';
import trash from './trash.png';
import edit from './edit.png';
import miss from './miss.png';
import closeX from './CloseX.svg';
import axios from 'axios';
import Modal from 'react-modal';
import AsyncStorage from '@react-native-async-storage/async-storage';
import TopNavBar from '../../Components/TopNavBar/TopNavBar';
import { BASE_URL } from '../../Pages/api/api';
import Select from 'react-select'; // Assuming you are using react-select

Modal.setAppElement('#root');

const AddDept = () => {
    const [employees, setEmployees] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [newDepartment, setNewDepartment] = useState('');
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [departments, setDepartments] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bearer, setBearer] = useState('');
    const [selectedDeptID, setSelectedDeptID] = useState('');
    const [deptToDelete, setDeptToDelete] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null); // For employee selection

    useEffect(() => {
        const fetchData = async () => {
            await readData();
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (bearer) {
            fetchDepartments();
            fetchEmployees();
        }
    }, [bearer]);

    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('tokens');
            if (value !== null) {
                setBearer(value);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    const fetchEmployees = async () => {
        try {
            const employeesResponse = await axios.get(
                'https://hr-api.emas.ng/api/employees/fetchAll',
                {
                    headers: { Authorization: `Bearer ${bearer}` },
                }
            );
            setEmployees(
                employeesResponse.data.data.map((item) => ({
                    label: item.first_name,
                    value: item.id,
                }))
            );
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    const fetchDepartments = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/department/fetchAll`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearer}`,
                },
            });
            if (response.status === 200) {
                setDepartments(response.data.data);
            } else {
                console.error('Failed to fetch departments:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching departments:', error);
        }
    };

    const handleAddDepartmentClick = () => {
        setNewDepartment('');
        setSelectedDepartment('');
        setIsEditing(false);
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
        setNewDepartment('');
        setSelectedDepartment('');
        setEditIndex(null);
        setIsEditing(false);
    };

    const handleAddNewDepartment = async () => {
        if (newDepartment.trim()) {
            const newDept = {
                name: newDepartment.trim(),
                description: selectedEmployee ? selectedEmployee.label : 'Head of Department',
                company_id: '3', // Assuming the company_id is static
            };

            try {
                setLoading(true);
                const response = await axios.post(`${BASE_URL}/department/create`, newDept, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${bearer}`,
                    },
                });

                if (response.status === 201 || response.status === 200) {
                    setDepartments([...departments, response.data.data]);
                    toast.success('Department added successfully!');
                    setLoading(false);
                } else {
                    console.error('Failed to add department:', response.data.message);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error adding department:', error);
            }

            handleCloseModal();
            setLoading(false);
        }
    };

    const handleSaveEdit = async () => {
        setLoading(true);
        if (editIndex !== null && editIndex >= 0 && editIndex < departments.length) {
            const selectedDept = departments[editIndex];
            const deptId = selectedDeptID;
            const updatedDeptName = newDepartment.trim();
            const updatedDeptDescription = selectedEmployee ? selectedEmployee.label : 'Updated description here';

            try {
                const response = await axios.get(`${BASE_URL}/department/update`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${bearer}`,
                    },
                    params: {
                        id: deptId,
                        name: updatedDeptName,
                        description: updatedDeptDescription,
                    },
                });

                if (response.status === 200) {
                    const updatedDepartments = departments.map((dept, idx) =>
                        idx === editIndex ? { ...dept, name: updatedDeptName, description: updatedDeptDescription } : dept
                    );
                    setDepartments(updatedDepartments);
                    toast.success('Department updated successfully!');
                } else {
                    toast.error('Failed to update department.');
                }
            } catch (error) {
                console.error('Error updating department:', error);
                toast.error('An error occurred while updating the department.');
            }

            handleCloseModal();
        }

        setLoading(false);
    };

    const handleEditClick = (id) => {
        const foundDepartment = departments.find((item) => item.id === id);
        setEditIndex(departments.findIndex((item) => item.id === id));
        setSelectedDepartment(foundDepartment?.description);
        setNewDepartment(foundDepartment?.name);
        setSelectedDeptID(foundDepartment?.id || '');
        setIsEditing(true);
        setModalIsOpen(true);
    };

    const handleDeleteClick = (index) => {
        const deptToDelete = departments[index];
        setDeptToDelete(deptToDelete);
        setDeleteModalIsOpen(true);
    };

    const handleConfirmDelete = async () => {
        setLoading(true);
        const departmentId = deptToDelete.id;

        try {
            const response = await axios.get(`${BASE_URL}/department/delete`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearer}`,
                },
                params: {
                    id: departmentId,
                },
            });

            if (response.status === 200) {
                const updatedDepartments = departments.filter((dept) => dept.id !== departmentId);
                setDepartments(updatedDepartments);
                toast.success('Department deleted successfully!');
            } else {
                console.error('Failed to delete department:', response.data.message);
                toast.error('Failed to delete department.');
            }
        } catch (error) {
            console.error('Error deleting department:', error);
            toast.error('An error occurred while deleting the department.');
        } finally{
            setLoading(false);
            setDeleteModalIsOpen(false);
        }
    };

    const handleCancelDelete = () => {
        setDeleteModalIsOpen(false);
        setDeptToDelete(null);
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedEmployee(selectedOption);
    };

    return (
        <div>
            <TopNavBar />
            <div className={classes.AddDept}>
                <div className={classes.textabove}>
                    <h5>Departments</h5>
                    <div className={classes.buttondept} onClick={handleAddDepartmentClick}>
                        Add Department
                    </div>
                </div>
                <div className={classes.tableContainer}>
                    <table className={classes.scheduleTable}>
                        <thead>
                            <tr>
                                <th>S/N</th>
                                <th>Department</th>
                                <th>Head Of Department</th>
                                <th className={classes.actionColumn}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {departments.map((dept, index) => (
                                <tr key={dept.id || index} className={index % 2 === 1 ? classes.highlightRow : ''}>
                                    <td>{index + 1}</td>
                                    <td>{dept.name}</td>
                                    <td>{dept.description}</td>
                                    <td className={classes.actionButtons}>
                                        <button className={classes.editButton}>
                                            <img onClick={() => handleEditClick(dept.id)} src={edit} alt="Edit" />
                                        </button>
                                        <button className={classes.deleteButton}>
                                            <img onClick={() => handleDeleteClick(index)} src={trash} alt="Delete" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={handleCloseModal}
                    contentLabel={isEditing ? 'Edit Department' : 'Add New Department'}

                    className={classes.modal}
                    overlayClassName={classes.overlay}
                >
                    <div className={classes.textandimg}>
                        <h2>{isEditing ? 'Edit Department' : 'Add New Department'}</h2>
                        <img src={miss} alt="Close" onClick={handleCloseModal} style={{ cursor: 'pointer' }} />

                    </div>
                    <hr />
                <div className={classes.inputField}>
                <input
                        type="text"
                        value={newDepartment}
                        onChange={(e) => setNewDepartment(e.target.value)}
                        placeholder="Enter Department Name"
                    />
                </div>
                   
                    <Select
                        className={classes.reactSelect}
                        placeholder="Select Employee"
                        value={selectedEmployee}
                        onChange={handleSelectChange}
                        options={employees}
                    />
                    <div className={classes.submitButtonContainer}>
                        <button
                            className={classes.submitButton}
                            onClick={isEditing ? handleSaveEdit : handleAddNewDepartment}
                            disabled={loading}
                        >
                            {!loading?  (<>{isEditing ? 'Save Changes' : 'Add Department'}</>) : 'Adding...'}
                        </button>
                    </div>
                </Modal>

                <Modal
                    isOpen={deleteModalIsOpen}
                    onRequestClose={handleCancelDelete}
                    contentLabel="Delete Department"
                    // className={classes.modal}
                    overlayClassName={classes.overlay}
                >
                    {/* <div className={classes.textandimg}>
                        <h2>Delete Department</h2>
                        <img src={miss} alt="Close" onClick={handleCancelDelete} style={{ cursor: 'pointer' }} />
                    </div> */}
                    <div className={classes.modalOverlayWhole}>
                                <div className={classes.modalErrYester}>
                                    <button className={classes.closeButtonModally} onClick={handleCancelDelete}>
                                        <img src={closeX} alt='' />
                                    </button>
                                    <div className={classes.modalContainerSend}>
                                        <h6>
                                            Delete Employee
                                        </h6>
                                        {deptToDelete !== null && (
                                            <>
                    <p>You are about to delete the dpartement: <strong>{deptToDelete?.name}</strong>.  
                    This action cannot be reversed. Are you sure you want to continue?</p>
                    {/* <div className={classes.submitButtonContainer}> */}
                        <button className={classes.addTeamBtn} onClick={handleConfirmDelete} disabled={loading}>
                        { loading ? 'Deleting...' : 'Delete' }
                        </button>

                        </>
                    )}
                    </div>
                    </div>
                    </div>
                    {/* </div> */}
                </Modal>
                <ToastContainer />
            </div>
        </div>
    );
};

export default AddDept;
