import React,{useState}from "react";
import classes from "./LeavePolicyModal.module.css";
import image1 from "../../Assets/Ellipse 1 (1).png";
import image2 from "../../Assets/Ellipse 1.png";
import approve from "../../Assets/approve.svg";
import decline from "../../Assets/decline.svg";
import view from "../../Assets/view.svg";
import less from "../../Assets/less.svg";
import next from "../../Assets/next.svg";
import plus from "../../Assets/plus.svg";
// import TopNavBar from "../../Components/ErrorModal/TopNavBar/TopNavBar";
import { Link } from "react-router-dom";
import cancel from "../../Assets/cancel.svg";
import search from '../../Assets/search.svg'
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import CreatePolicy from "../CreatePolicy/CreatePolicy";
import LeavePolicyEdit from "../LeavePolicyEdit/LeavePolicyEdit";

const LeavePolicyModal = ({onShow}) => {

  const [showEdit,setShowEdit] = useState(false);

  const editHandler =() => {
    setShowEdit(true)
  }

  const hideModal = () => {
    onShow(false)
  }
  return (
    <>
      <div className={classes.modalBackdrop} >
        <div className={classes.modalRequest}>
          <div className={classes.divModalTop}>
            <h4 className={classes.requet}>Leave Request</h4>
            <img src={cancel} alt="cancel" onClick={hideModal}/>
          </div>
          <div className={classes.divInfo}>
            <div>
              <img src={image2} alt="person" />
            </div>
            <div className={classes.styleDiv}>
              <div className={classes.nameDiv}>
                <div className={classes.textDiv}>
                  <h2 className={classes.names}>Matthew Olowade</h2>
                  <p className={classes.titleName}>Media Project Manager </p>
                </div>
                <div className={classes.textDiv}>
                  <h2 className={classes.departmt}>Department</h2>
                  <p className={classes.desg}>Digital Media</p>
                </div>
              </div>
              <div className={classes.rowT}>
                <div className={classes.styDv}>
                  <h6 className={classes.department}>Date of Joining</h6>
                  <h4 className={classes.digital}>25/01/2024</h4>
                </div>
                <div className={classes.getSec}>
                  <h6 className={classes.department}>Leave Policy </h6>
                  <h4 className={classes.digital}>19 days</h4>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.btnBtn}>
            {showEdit && <LeavePolicyEdit onShow={setShowEdit}/>}
          <button className={classes.appe} onClick={editHandler}>Edit Leave Policy</button>
          </div>
        </div>
      </div>
      <TopNavBar />
      <div className={classes.toTUpper}>
        <h4 className={classes.topperText}>Leave Management</h4>
        <div className={classes.firstBtn}>
          <button className={classes.policyBtn}>Create Leave Policy</button>
          <button className={classes.addBtn}>
            <img src={plus} alt="plus" />
            Add Leave
          </button>
        </div>
        <div className={classes.topt}>
          <Link to={"/leave_management"}>
            {" "}
            <p>Leave Request</p>
          </Link>
          <Link to={"/leave_approved"}>
            <p>Approved</p>
          </Link>
          <Link to={"/leave_decline"}>
            <p>Declined</p>
          </Link>
          <Link to={"/leave_policy"}>
            <p>Leave Policy</p>
          </Link>
        </div>
        <div className={classes.topLeave}>
          <div>
            <div className={classes.topTextDiv}>
              <h4 className={classes.topText}>Leave Request</h4>
            </div>
            <div className={classes.divSelect}>
            <div style={{position:'relative'}}>
            <input type="text" placeholder="Search employee name" className={classes.sBAr}/>
            <img src={search} alt='search' className={classes.imgSearch}/>
            </div>
            
            <select for='department' required className={classes.selectTag}>
              <option value=''>Select department</option>
              <option value='tech'>Technology</option>
            </select>
            <button className={classes.searchBtn}>Search</button>
          </div>

            <table className={classes.tableDiv}>
              <tr className={classes.tableHeadDiv}>
                <th className={classes.tableHeadRow}>S/N</th>
                <th className={classes.tableHeadRow}>Employee Name</th>
                <th className={classes.tableHeadRow}>Designation</th>
                <th className={classes.tableHeadRow}>Department</th>
                <th className={classes.tableHeadRow}>Date of Joining</th>
                <th className={classes.tableHeadRow}>Leave Policy</th>
                <th className={classes.tableHeadRow}>View</th>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>1</td>
                <td className={classes.pflDiv}>
                  <img src={image1} alt="one" />
                  Matthew Olowade
                </td>
                <td>Media Project Manager </td>
                <td>Digital Media </td>
                <td>25/08/2024</td>
                <td>19 days</td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>2</td>
                <td className={classes.pflDiv}>
                  <img src={image2} alt="two" />
                  Lauren Annabelle
                </td>
                <td>Art Director </td>
                <td>Print Media</td>
                <td>28/08/2024</td>
                <td>13 days</td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>1</td>
                <td className={classes.pflDiv}>
                  <img src={image1} alt="one" />
                  Matthew Olowade
                </td>
                <td>Media Project Manager </td>
                <td>Digital Media </td>
                <td>25/08/2024</td>
                <td>19 days</td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>2</td>
                <td className={classes.pflDiv}>
                  <img src={image2} alt="two" />
                  Lauren Annabelle
                </td>
                <td>Art Director </td>
                <td>Print Media</td>
                <td>28/08/2024</td>
                <td className={classes.btnSet}>13 days</td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
            </table>
            <div className={classes.gotoNext}>
              <button className={classes.prev}>
                <img src={less} alt="less" />
                Previous
              </button>
              <p className={classes.pagination}>Page 1 of 5</p>
              <button className={classes.next}>
                Next
                <img src={next} alt="next" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeavePolicyModal;
