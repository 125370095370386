import { createContext,useState } from "react";

export const ModalCtx = createContext()

export const ModalProvider = ({children}) => {
    const [showDecline,setShowDecline] = useState(false);
    return (
        <>
        <ModalCtx.Provider value={{showDecline,setShowDecline}}>
            {children}
        </ModalCtx.Provider>
        </>
    )
}