import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import classes from "./ExitManagement.module.css";
import less from "../../Assets/less.svg";
import next from "../../Assets/next.svg";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import EmployeeImg from "../../Assets/EmployeeImg.png"
import eye from "../../Assets/eye.svg"
import edit from "../../Assets/edit.svg"
import Select from 'react-select';
import deleteimg from "../../Assets/deleteimg.svg"
import { Link } from "react-router-dom";
import search from '../../Assets/search.svg';
import AppraisalCreation from "../../Components/AppraisalCreation/AppraisalCreation";
// import EditAppraisal from "../../Components/EditAppraisal/EditAppraisal";
// import ViewAppraisal from "../../Components/viewAppraisal/viewAppraisal";
import ViewCompletedAppraisals from "../../Components/ViewCompletedAppraisal/ViewCompletedAppraisal";
import AddTermination from "../../Components/AddTermination/AddTermination";
import TerminatedEmployees from "../../Components/TerminatedEmployees/TerminatedEmployees";
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from "axios";


const ExitManagement = () => {

    const [bearer, setBearer] = useState('');
    const [employees, setEmployees] = useState([]);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);


    const [isModalOpen1, setIsModalOpen1] = useState(false);

    const openModal1 = () => setIsModalOpen1(true);
    const closeModal1 = () => setIsModalOpen1(false);

    const handleViewEmployee = (id) => {
        setSelectedEmployeeId(id);
        setIsModalOpen1(true); // open the modal
    };


    const fetchEmployees = async () => {
        try {
            const response = await axios.get('https://hr-api.emas.ng/api/exit-management/index', {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${bearer}`,
                },
            });
            setEmployees(response.data.data || []);
            console.log('Employees', response.data);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('tokens');
            if (value !== null) setBearer(value);
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    useEffect(() => {
        if (bearer) {
            fetchEmployees();
        }
    }, [bearer]);



    return (
        <div> <>
            <TopNavBar />
            <AddTermination isOpen={isModalOpen} onClose={closeModal} onEmployeeTerminated />
            <TerminatedEmployees
                isOpen={isModalOpen1}
                onClose={() => setIsModalOpen1(false)}
                employeeId={selectedEmployeeId}
                onEmployeeTerminated={fetchEmployees}
            />

            <div className={classes.toTUpper}>
                {/* <AppraisalCreation isOpen={isModalOpen} onClose={closeModal} /> */}
                {/* <EditAppraisal isOpen={isModalOpen1} onClose={closeModal1} /> */}
                {/* <ViewCompletedAppraisals isOpen={isEditModalOpen} onClose={closeEditModal} /> */}
                <div className={classes.topperText}>
                    <h4>Exit Management</h4>
                    <div className={classes.firstBtn}>
                        <button onClick={openModal} className={classes.policyBtn}>Add Termination</button>
                    </div>
                </div>

                <div className={classes.topLeave}>
                    <div>
                        <div className={classes.topTextDiv}>
                            <h4 className={classes.topText}>Exit Management</h4>
                        </div>

                        <div style={{ height: '1px', width: '107%', border: '1px solid #E9ECEF', marginLeft: '-39px' }}></div>

                        <div className={classes.tableContainer}>
                            <table className={classes.tableDiv}>
                                <tr className={classes.tableHeadDiv}>
                                    <th className={classes.tableHeadRow}>S/N</th>
                                    {/* <th className={classes.tableHeadRow}>Employee Picture</th> */}
                                    <th className={classes.tableHeadpflDiv}>Employee Name</th>
                                    <th className={classes.tableHeadpflDiv}>Designation</th>
                                    <th className={classes.tableHeadpflDiv}>Termination Type</th>
                                    <th className={classes.tableHeadpflDiv}>Termination Date</th>
                                    <th className={classes.tableHeadpflDiv}>Notice Date</th>
                                    {/* <th className={classes.tableHeadRow}>End Date</th> */}
                                    {/* <th className={classes.tableHeadRow}>Job Type</th> */}
                                    {/* <th className={classes.tableHeadRow}>No. of Applicants</th> */}
                                    <th className={classes.tableHeadRow}>View</th>
                                </tr>

                                {employees.length > 0 ? (
                                    employees.map((employee, index) => (
                                        <tr key={employee.id} className={classes.tableRowBody}>
                                            <td>{index + 1}</td>
                                            <div className={classes.flexDirChng}>
                                                <td className={classes.pflDivImg}><img src={employee?.employee.personal_details[0]?.image} alt="" /></td>
                                                <td className={classes.pflDiv}>{employee?.employee.first_name} {employee?.employee.last_name}</td>
                                            </div>
                                            <td className={classes.pflDiv}>{employee?.employee.designation_id}</td>
                                            <td className={classes.pflDiv}>{employee?.reason_for_termination}</td>
                                            <td className={classes.pflDiv}>{employee?.exit_date}</td>
                                            <td className={classes.pflDiv}>{employee?.notice_date}</td>
                                            <td className={classes.formEye}>
                                                <img
                                                    src={eye}
                                                    alt="view"
                                                    onClick={() => handleViewEmployee(employee.id)}
                                                    style={{ cursor: 'pointer' }}
                                                />

                                                {/* <img src={edit} onClick={openModal1} alt="approve" />
                                <img src={deleteimg} alt="Delete" onClick={openDeleteModal} /> */}
                                            </td>
                                        </tr>
                                    ))

                                ) : (
                                    <tr>
                                        <td colSpan="7">No employees found</td>
                                    </tr>
                                )}


                            </table>
                        </div>
                        <div className={classes.gotoNext}>
                            <button className={classes.prev}>
                                <img src={less} alt="less" />
                                Previous
                            </button>
                            <p className={classes.pagination}>Page 1 of 5</p>
                            <button className={classes.next}>
                                Next
                                <img src={next} alt="next" />
                            </button>
                        </div>
                        {/* <Modal
                    isOpen={isDeleteModalOpen}
                >
                    <div className={classes.modalOverlayWhole}>
                        <div className={classes.modalErrYester}>
                            <button className={classes.closeButtonModally} onClick={closeDeleteModal}>
                                <img src={CloseX} alt='' />
                            </button>
                            <div className={classes.modalContainerSend}>
                                <h6>
                                    Delete Employee
                                </h6>
                             
                                        <p>
                                            You are about to delete this appraisal,
                                            this action cannot be reversed, are you sure you want to continue?
                                        </p>
                                        <button
                                            type="submit"
                                            className={classes.addTeamBtn}
                                        >
                                            Delete
                                        </button>
                                  
                            </div>
                        </div>
                    </div>
                </Modal> */}
                    </div>
                </div>
            </div>
        </>

        </div>
    )
}

export default ExitManagement
