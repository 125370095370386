import React, { useState, useEffect } from 'react';
import classes from './stopSubmission.module.css';
import { IoWarningOutline } from "react-icons/io5";
import closeX from './CloseX.svg'
import successPayslip from '../../Assets/payslipSuccess.svg'

const StopSubmission = ({ isOpen, onClose }) => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (isOpen) {
            // Delay to give a smooth entrance when the modal opens
            const timer = setTimeout(() => {
                setAnimate(true);
            }, 500); // Adjust delay as needed

            return () => clearTimeout(timer);
        } else {
            setAnimate(false); // Reset animation when the modal is closed
        }
    }, [isOpen]);

    if (!isOpen) return null;


    return (
        <div className={classes.modalOverlayWhole}>
            <div className={classes.modalErrYester}>
                <button className={classes.closeButtonModally} onClick={onClose}>
                    <img src={closeX} alt='' />
                </button>
                <div className={classes.modalContainerSend}>

                    <h6>
                       Stop Recieving Submissions
                    </h6>
                    <p>You will stop receiving submissions for this job application, are you sure you want to continue</p>
                    <button type='submit' className={classes.addTeamBtn}>
                       Stop
                    </button>
                </div>

            </div>
        </div>
    );
};

export default StopSubmission;
