// import React, { useState, useEffect, useContext} from 'react';
// import { NavLink, useNavigate } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
// import classes from '../Login/login.module.css'
// // import loginImg from '../../Assets/loginImg.svg'
// import loginImg from '../../Assets/LoginImgImg.png';
// // import profileImg from '../../Assets/profileIcon.svg'
// import axios from 'axios';
// import { Link } from 'react-router-dom'
// import { GoEyeClosed } from "react-icons/go";
// import ErrorModal from '../../Components/ErrorModal/ErrorModal'
// import { BASE_URL } from "../../Pages/api/api";
// import { RxEyeOpen } from "react-icons/rx";
// // import 'bootstrap/dist/css/bootstrap.min.css';
// import { AuthContext } from '../utils/AuthContext';
// import spinner from '../../Assets/spinnerT.gif'



// function Login() {
//     const [showPassword, setShowPassword] = useState(false);

//     const togglePasswordVisibility = () => {
//         setShowPassword(!showPassword);
//     };


//     const [isModalOpen, setIsModalOpen] = useState(false);

//     const openModal = () => setIsModalOpen(true);
//     const closeModal = () => setIsModalOpen(false);

//     const {setEmail,email,password,setPassword,LoginAction,loading,setLoading}= useContext(AuthContext)

//     useEffect(() => {
//         if (isModalOpen) {
//             const timer = setTimeout(() => {
//                 setIsModalOpen(false);
//             }, 5000);

//             return () => clearTimeout(timer);
//         }
//     }, [isModalOpen]);

//     const handleLogin = () => {
//         LoginAction()
        
//     }
//     useEffect(() => {
//         const handleKeyDown = (event) => {
//             if (event.key === 'Enter') {
//                 event.preventDefault();
//                 handleLogin();
//             }
//         };
    
//         window.addEventListener('keydown', handleKeyDown);
    
//         return () => {
//             window.removeEventListener('keydown', handleKeyDown);
//         };
//     }, []);



//     return (
//         <div className={classes.general}>
//             <div className={classes.imgSpace}>
//                 <img src={loginImg} className={classes.LoginImg} />
//             </div>
//             <div className={classes.inputField}>

//                 <span><p>H</p><p className={classes.rRed}>R</p><p>M</p></span>

//                 <div className={classes.botttomFlex}>

//                     <div className={classes.Inpcontaer}>
//                         <h4>Log In</h4>
//                         <p>Log In as an Admin </p>

//                         <div className={classes.inputContainer}>

//                             <div className={classes.Inputcnt}>
//                                 <label htmlFor='email'>Email address</label>
//                                 <input
//                                     type='email'
//                                     id='email'
//                                     value={email}
//                                     onChange={(e) => setEmail(e.target.value)}
//                                     placeholder='Johndoe@gmail.com'
//                                     required />
//                             </div>
//                         </div>
//                         <div className={classes.inputContainer}>
//                             <div className={classes.Inputcnt}>
//                                 <label htmlFor='password'>Password</label>
//                                 <input type={showPassword ? 'text' : 'password'}
//                                     id='password'
//                                     value={password}
//                                     onChange={(e) => setPassword(e.target.value)}
//                                     required />
//                                 <p
//                                     onClick={togglePasswordVisibility}>
//                                     {showPassword ? (
//                                         <GoEyeClosed />
//                                     ) : (
//                                         <RxEyeOpen />
//                                     )}
//                                 </p>
//                             </div>
//                         </div>


//                         <button
//                             style={{ backgroundColor: loading ? '#c4c4c4' : '#2D995F' }}
//                             disabled={loading}
//                             onClick={handleLogin}
//                             className={classes.loginBtn}
//                         >
//                              {loading && (<img src={spinner} alt='' className={classes.spinnerStyle} />)}
//                             {loading ? 'Logging in, please wait....' : 'Log In'}
//                         </button>
                              

//                         {/* <p style={{fontWeight: 900, color: messageColor}}> {showMessage}</p> */}
//                         < ErrorModal isOpen={isModalOpen} onClose={closeModal} className={classes.slideInRight} />
//                         <div className={classes.account}>
//                             <p>Don't have an account? <NavLink to='/sign_up_admin'>Create Account</NavLink></p>
//                         </div>
//                     </div>

//                 </div>
//             </div>
//         </div>
//     )
// }
// export default Login;




import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import classes from '../Login/login.module.css';
import loginImg from '../../Assets/LoginImgImg.png';
import { GoEyeClosed } from "react-icons/go";
import { RxEyeOpen } from "react-icons/rx";
import { AuthContext } from '../utils/AuthContext';
import spinner from '../../Assets/spinnerT.gif';
import ErrorModal from '../../Components/ErrorModal/ErrorModal';

function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const { setEmail, email, password, setPassword, LoginAction, loading } = useContext(AuthContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);


    useEffect(() => {
        if (isModalOpen) {
            const timer = setTimeout(() => {
                setIsModalOpen(false);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [isModalOpen]
);


    const handleLogin = () => {
           LoginAction()
      
          }


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleLogin();
        }
    };

    return (
        <div className={classes.general}>
            <div className={classes.imgSpace}>
                <img src={loginImg} className={classes.LoginImg} alt="Login" />
            </div>
            <div className={classes.inputField}>
                <span><p>H</p><p className={classes.rRed}>R</p><p>M</p></span>
                <div className={classes.botttomFlex}>
                    <div className={classes.Inpcontaer}>
                        <h4>Log In</h4>
                        <p>Log In as an Admin</p>
                        <div className={classes.inputContainer}>
                            <div className={classes.Inputcnt}>
                                <label htmlFor='email'>Email address</label>
                                <input
                                    type='email'
                                    id='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onKeyDown={handleKeyDown}  
                                    placeholder='Johndoe@gmail.com'
                                    required
                                />
                            </div>
                        </div>
                        <div className={classes.inputContainer}>
                            <div className={classes.Inputcnt}>
                                <label htmlFor='password'>Password</label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id='password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyDown={handleKeyDown}  
                                    required
                                />
                                <p onClick={togglePasswordVisibility}>
                                    {showPassword ? <GoEyeClosed /> : <RxEyeOpen />}
                                </p>
                            </div>
                        </div>

                        <button
                            style={{ backgroundColor: loading ? '#c4c4c4' : '#2D995F' }}
                            disabled={loading}
                            onClick={handleLogin}
                            className={classes.loginBtn}
                        >
                            {loading && (<img src={spinner} alt='' className={classes.spinnerStyle} />)}
                            {loading ? 'Logging in, please wait....' : 'Log In'}
                        </button>

                        <ErrorModal isOpen={isModalOpen} onClose={closeModal} className={classes.slideInRight} />
                        <div className={classes.account}>
                            <p>Don't have an account? <NavLink to='/sign_up_admin'>Create Account</NavLink></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
