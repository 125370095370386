import React, { useState, useEffect } from 'react';
import classes from "./ViewBenefits.module.css";
import closeX from "./CloseX.svg";

const ViewBenefits =  ({ isOpen, onClose }) => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (isOpen) {
            // Delay to give a smooth entrance when the modal opens
            const timer = setTimeout(() => {
                setAnimate(true);
            }, 500); // Adjust delay as needed

            return () => clearTimeout(timer);
        } else {
            setAnimate(false); // Reset animation when the modal is closed
        }
    }, [isOpen]);

    if (!isOpen) return null;
  return (
    <div>
       <div className={classes.modalBackdrop}>
        <div className={classes.modalRequest}>
          <div className={classes.divModalTop}>
            <h4 className={classes.requet}>
            Holiday/Benefits
            </h4>
            <img
              src={closeX}
              alt="cancel"
            onClick={onClose}
              style={{ cursor: 'pointer' }}
            />
          </div>

          <div className={classes.emyInDiv}>
            <h4 className={classes.empyInfo}>Employee Information</h4>
            <div className={classes.divDesc}>
              <div>
                <p className={classes.name}>Name</p>
                <h4 className={classes.nameVal}>
                Lauren Annabelle
                </h4>
              </div>
              <div>
                <p className={classes.name}>Department</p>
                <h4 className={classes.nameVal}>
                Print Media
                </h4>
              </div>
            </div>

            <div className={classes.divDesc}>
              <div>
                <p className={classes.name}>Designation</p>
                <h4 className={classes.nameVal}>
                  Art Director
                </h4>
              </div>
              <div>
                <p className={classes.name}>Email Address</p>
                <h4 className={classes.nameVal}>
                laurena@gmail.com
                </h4>
              </div>
            </div>
          </div>

          <div className={classes.emyInDiv}>
            <h4 className={classes.empyInfo}>Status</h4>
            <div className={classes.divDesc}>
              <div>
                <p className={classes.name}>Eligibility</p>
                <h4 className={classes.nameVal}>
                <button className={classes.BtnDivCenteredTwo}>
                                    Eligible
                                </button>
                </h4>
              </div>
              <div>
                <p className={classes.name}>Date of Joining</p>
                <h4 className={classes.nameVal}>
                25/08/2024
                </h4>
              </div>
            </div>
          </div>

          <div className={classes.emyInDiv}>
            <h4 className={classes.empyInfo}>Benefit</h4>
                <h4 className={classes.nameValsecd}>
                Mr Awolowo Tunde is eligible for 5 days paid vacation for himself and his wife by december 2024
                </h4>
              </div>
        </div>
      </div>

    </div>
  )
}

export default ViewBenefits
