import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import classes from "./employee_profile.module.css";
import back from "../../Assets/back.svg";
import employeeimg from "../../Assets/EmployeeImg.png";
import { Link } from "react-router-dom";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";

const EmployeeProfile = () => {
  const { employeeId } = useParams(); // Get employee ID from URL params
  const [employeeProfile, setEmployeeProfile] = useState(null);
  const [bearer, setBearer] = useState("");
  const [error, setError] = useState(false);

  // Fetch token from AsyncStorage
  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("tokens");
      if (value !== null) {
        setBearer(value);
      }
    } catch (e) {
      alert("Failed to fetch the input from storage");
    }
  };

  // Fetch employee details from API
  const fetchEmployeeProfile = async () => {
    if (!bearer || !employeeId) return;

    try {
      const response = await axios.get(
        `https://hr-api.emas.ng/api/employees/fetch-employees`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${bearer}`,
          },
          params: { id: employeeId },
        }
      );
      // console.log(response)

      setEmployeeProfile(response.data.data); // Store employee data
      console.log(response?.data?.data?.education_background);
    } catch (error) {
      console.log("Error fetching Employee Profile", error);
      setError(true);
    }
  };

  useEffect(() => {
    readData();
  }, []);

  useEffect(() => {
    if (bearer && employeeId) {
      fetchEmployeeProfile();
    }
  }, [bearer, employeeId]);

  return (
    <div className={classes.cover_contain}>
      <TopNavBar />
      <div className={classes.covering_container}>
        <p className={classes.paraMargWeight}>Employees</p>
        <div className={classes.containerCovercontain}>
          <Link to="/employee">
            <div className={classes.navigateBack}>
              <img src={back} alt="" />
              <p className={classes.nameGroupType}>
                {employeeProfile?.first_name} {employeeProfile?.last_name}
              </p>
            </div>
          </Link>
          {employeeProfile ? (
            <div className={classes.adjust_Width}>
              <p className={classes.myProfileprofile}>My Profile</p>
              <div className={classes.straightLineflex}>
                <img src={employeeimg} alt="" />
                <div className={classes.caleb_solos}>
                  <h2>
                    {employeeProfile.first_name} {employeeProfile.last_name}
                  </h2>
                  <p>{employeeProfile.position}</p>
                </div>
              </div>

              <div className={classes.headingPersonal}>
                <p className={classes.paraFontDesgn}>Personal Information</p>
                <div className={classes.headingPersonalDetails}>
                  <div className={classes.designOne}>
                    <p className={classes.para_Desgn}>First Name</p>
                    <p className={classes.para_design}>
                      {employeeProfile.first_name}
                    </p>
                  </div>
                  <div className={classes.designTwo}>
                    <p className={classes.para_Desgn}>Last Name</p>
                    <p className={classes.para_design}>
                      {employeeProfile.last_name}
                    </p>
                  </div>
                  <div className={classes.designThree}>
                    <p className={classes.para_Desgn}>Email Address</p>
                    <p className={classes.para_design}>
                      {employeeProfile.email}
                    </p>
                  </div>
                  <div className={classes.designFour}>
                    <p className={classes.para_Desgn}>Phone Number</p>
                    <p className={classes.para_design}>
                      {employeeProfile.phone_number}
                    </p>
                  </div>
                  <div className={classes.designFive}>
                    <p className={classes.para_Desgn}>Date of Birth</p>
                    <p className={classes.para_design}>
                      {employeeProfile.date_of_birth || "N/A"}
                    </p>
                  </div>
                  <div className={classes.designFive}>
                    <p className={classes.para_Desgn}>Home Address</p>
                    <p className={classes.para_design}>
                      {employeeProfile.home_address || "N/A"}
                    </p>
                  </div>
                </div>
              </div>
              <div className={classes.headingPersonal}>
             
              {
                employeeProfile?.education_background.length > 0 ? ( employeeProfile?.education_background?.map((items, index) => (
                  < React.Fragment key={index}>
                    <p className={classes.paraFontDesgn}>
                      Educational Background {index + 1}
                    </p>
                    <div className={classes.headingPersonalDetails}>
                      <div className={classes.designOne}>
                        <p className={classes.para_Desgn}>Program name</p>
                        <p className={classes.para_design}>
                          {items?.program_name || "N/A"}
                        </p>
                      </div>
                      <div className={classes.designTwo}>
                        <p className={classes.para_Desgn}>
                          Institution attended
                        </p>
                        <p className={classes.para_design}>
                          {items?.institution_name || "N/A"}
                        </p>
                      </div>
                      <div className={classes.designThree}>
                        <p className={classes.para_Desgn}>Start date</p>
                        <p className={classes.para_design}>
                          {items?.start_date || "N/A"}
                        </p>
                      </div>
                      <div className={classes.designOne}>
                        <p className={classes.para_Desgn}>Completion date</p>
                        <p className={classes.para_design}>
                          {items?.end_date || "N/A"}
                        </p>
                      </div>
                    </div>
                  </ React.Fragment>
                ))) : (
                  <>
                  <p className={classes.paraFontDesgn}> Educational Background</p>
                  <p style={{marginLeft:20}}>No data available</p>
                  </>)
              }
               
              </div>

              <div className={classes.headingPersonal}>
           
              {
                employeeProfile?.professional_qualification.length > 0 ? (employeeProfile?.professional_qualification?.map(
                  (items, index) => (
                    <React.Fragment key={index}>
                      <p className={classes.paraFontDesgn}>
                        Professional Qualifications {index + 1}
                      </p>
                      <div className={classes.headingPersonalDetails}>
                        <div className={classes.designOne}>
                          <p className={classes.para_Desgn}>Certification</p>
                          <p className={classes.para_design}>
                            {items?.qualification || "N/A"}
                          </p>
                        </div>
                        <div className={classes.designTwo}>
                          <p className={classes.para_Desgn}>Institution</p>
                          <p className={classes.para_design}>
                            {items?.institution || "N/A"}
                          </p>
                        </div>
                        <div className={classes.designTwo}>
                          <p className={classes.para_Desgn}>Date attained</p>
                          <p className={classes.para_design}>
                            {items?.date_attained || "N/A"}
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  )
                )) : (  <>
                   <p className={classes.paraFontDesgn}>Professional Qualifications</p>
                  <p style={{marginLeft:20}}>No data available</p>
                  </>)
              }
                
              </div>
              <div className={classes.headingPersonal}>
            
              {
                employeeProfile?.next_of_kin.length > 0 ? (employeeProfile?.next_of_kin?.map((items, index) => (
                  <React.Fragment key={index}>
                    <p className={classes.paraFontDesgn}>
                      Next of Kin {index + 1}
                    </p>

                    <div className={classes.headingPersonalDetails}>
                      <div className={classes.designOne}>
                        <p className={classes.para_Desgn}>First Name</p>
                        <p className={classes.para_design}>
                          {items?.first_name || "N/A"}
                        </p>
                      </div>
                      <div className={classes.designTwo}>
                        <p className={classes.para_Desgn}>Last Name</p>
                        <p className={classes.para_design}>
                          {items?.last_name || "N/A"}
                        </p>
                      </div>
                      <div className={classes.designThree}>
                        <p className={classes.para_Desgn}>Email Address</p>
                        <p className={classes.para_design}>
                          {items?.email || "N/A"}
                        </p>
                      </div>
                      <div className={classes.designOne}>
                        <p className={classes.para_Desgn}> Phone Number</p>
                        <p className={classes.para_design}>
                          {items?.phone_number || "N/A"}
                        </p>
                      </div>
                      <div className={classes.designTwo}>
                        <p className={classes.para_Desgn}>Gender</p>
                        <p className={classes.para_design}>
                          {items?.gender || "N/A"}
                        </p>
                      </div>
                      <div className={classes.designThree}>
                        <p className={classes.para_Desgn}>Occupation</p>
                        <p className={classes.para_design}>
                          {items?.relationship || "N/A"}
                        </p>
                      </div>
                    </div>
                  </React.Fragment>
                ))) : ( <>
                   <p className={classes.paraFontDesgn}> Next of Kin</p>
                 <p style={{marginLeft:20}}>No data available</p>
                 </>)
              }
                
              </div>

              <div className={classes.headingPersonal}>
              <p className={classes.paraFontDesgn}> Bank Details</p>
              {
                employeeProfile?.bank_details.length > 0 ? (employeeProfile?.bank_details.map((items,index) => (
                  <React.Fragment key={index}>
                
                    
          <div className={classes.headingPersonalDetails}>
            <div className={classes.designOne}>
              <p className={classes.para_Desgn}>Account name</p>
              <p className={classes.para_design}>
                {items?.account_name || "N/A"}
              </p>
            </div>
            <div className={classes.designTwo}>
              <p className={classes.para_Desgn}>Account number</p>
              <p className={classes.para_design}>
                {items?.account_number || "N/A"}
              </p>
            </div>
            <div className={classes.designTwo}>
              <p className={classes.para_Desgn}>Bank</p>
              <p className={classes.para_design}>
                {items?.bank_name || "N/A"}
              </p>
            </div>
          </div>
                  </React.Fragment>
              ))) : (<p style={{marginLeft:20}}>No data available</p>)
              }
                
              </div>


              <div className={classes.headingPersonal}>
            
              {
                 employeeProfile?.referee.length > 0 ? ( employeeProfile?.referee.map((items,index) => (
                  <React.Fragment key={index}>
                       <p className={classes.paraFontDesgn}>Referee {index + 1}</p>
                       <div className={classes.headingPersonalDetails}>
            <div className={classes.designOne}>
              <p className={classes.para_Desgn}>First Name</p>
              <p className={classes.para_design}>
                {items?.first_name || "N/A"}
              </p>
            </div>
            <div className={classes.designTwo}>
              <p className={classes.para_Desgn}>Last Name</p>
              <p className={classes.para_design}>
                {items?.last_name || "N/A"}
              </p>
            </div>
            <div className={classes.designThree}>
              <p className={classes.para_Desgn}>Email Address</p>
              <p className={classes.para_design}>
                {items?.email || "N/A"}
              </p>
            </div>
            <div className={classes.designOne}>
              <p className={classes.para_Desgn}>Phone number</p>
              <p className={classes.para_design}>
                {items?.phone || "N/A"}
              </p>
            </div>
            <div className={classes.designTwo}>
              <p className={classes.para_Desgn}>Gender</p>
              <p className={classes.para_design}>
                {items?.gender || "N/A"}
              </p>
            </div>
            <div className={classes.designThree}>
              <p className={classes.para_Desgn}>Occupation</p>
              <p className={classes.para_design}>
                {items?.occupation || "N/A"}
              </p>
            </div>
          </div>
                  </React.Fragment>
               ))) : ( <>
                <p className={classes.paraFontDesgn}>Referee </p>
               <p style={{marginLeft:20}}>No data available</p>
               </>)
              }
              </div>
              <div className={classes.headingPersonal}>
           
              {
                employeeProfile?.experience.length > 0 ? ( employeeProfile?.experience.map((items,index) => (
                  <React.Fragment key={index}>
                     
                       <p className={classes.experinceIndex}>Experience {index + 1}</p>
                       <div className={classes.headingPersonalDetails}>
            <div className={classes.designOne}>
              <p className={classes.para_Desgn}>Name of company</p>
              <p className={classes.para_design}>
                {items?.company || "N/A"}
              </p>
            </div>
            <div className={classes.designTwo}>
              <p className={classes.para_Desgn}>Job role</p>
              <p className={classes.para_design}>
                {items?.position || "N/A"}
              </p>
            </div>
            <div className={classes.designThree}>
              <p className={classes.para_Desgn}>Start date</p>
              <p className={classes.para_design}>
                {items?.start_date || "N/A"}
              </p>
            </div>
            <div className={classes.designOne}>
              <p className={classes.para_Desgn}>Completion date</p>
              <p className={classes.para_design}>
                {items?.end_date || "N/A"}
              </p>
            </div>
          </div>

                  </React.Fragment>
               ))
          ) : ( <>
               <p className={classes.paraFontDesgn}>Work Experience</p>
           <p style={{marginLeft:20}}>No data available</p>
           </>)
              }
                
                    
            
              </div>
              <div className={classes.headingPersonal}>
                <p className={classes.paraFontDesgn}>KPI</p>
                <div className={classes.headingPersonalDetails}>
                  <div className={classes.designOne}>
                    <p className={classes.para_Desgn}>Productivity</p>
                    <p className={classes.para_design}>
                      {employeeProfile.productivity || "N/A"}
                    </p>
                  </div>
                  <div className={classes.designTwo}>
                    <p className={classes.para_Desgn}>Time Management</p>
                    <p className={classes.para_design}>
                      {employeeProfile.timeThing || "N/A"}
                    </p>
                  </div>
                  <div className={classes.designThree}>
                    <p className={classes.para_Desgn}>Quality of Work</p>
                    <p className={classes.para_design}>
                      {employeeProfile.quality_work || "N/A"}
                    </p>
                  </div>
                  <div className={classes.designFour}>
                    <p className={classes.para_Desgn}>Goal Attainment</p>
                    <p className={classes.para_design}>
                      {employeeProfile.goal_attainment || "N/A"}
                    </p>
                  </div>
                  <div className={classes.designFive}>
                    <p className={classes.para_Desgn}>Team Collaboration</p>
                    <p className={classes.para_design}>
                      {employeeProfile.team_things || "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p style={{ fontWeight: "bold", marginLeft: 100 }}>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployeeProfile;
