import React, { useState, useEffect } from 'react';
import classes from './ActivateJobApplication.module.css';
import closeX from './CloseX.svg';

const ActivateJobApplication = ({ isOpen, onClose }) => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (isOpen) {
            // Delay to give a smooth entrance when the modal opens
            const timer = setTimeout(() => {
                setAnimate(true);
            }, 500); // Adjust delay as needed

            return () => clearTimeout(timer);
        } else {
            setAnimate(false); // Reset animation when the modal is closed
        }
    }, [isOpen]);

    if (!isOpen) return null;


    return (
        <div className={classes.modalOverlayWhole}>
            <div className={classes.modalErrYester}>
                <button className={classes.closeButtonModally} onClick={onClose}>
                    <img src={closeX} alt='' />
                </button>
                <div className={classes.modalContainerSend}>

                    <h6>
                        Activate Job Application
                    </h6>
                    <p>You are about to activate this Job application that has been previously archived,
                         this Job post will be duplicated and a new copy of it will be on the new applications tab, 
                         you can make changes to the job application in the new applications 
                         tab before you share the job application online, 
                         are you sure you want to continue?</p>
                    <button type='submit' className={classes.addTeamBtn}>
                       Activate
                    </button>
                </div>

            </div>
        </div>
    );
};

export default ActivateJobApplication;
