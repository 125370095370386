import React, { useState, useEffect } from "react";
import classes from "./ViewShortlistedCandidates.module.css";
// import TopNavBar from "../../Components/ErrorModal/TopNavBar/TopNavBar";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import cancel from "../../Assets/cancel.svg";
import job from "../../Assets/job.svg";
import download from '../../Assets/download.svg';
import SendInterviewInvite from "../sendInterviewInvite/sendInterviewInvite";
import SendOfferLetter from "../sendOfferLetter/sendOfferLetter";
import RejectionMail from "../rejectionMail/rejectionMail";
import EmployCandidate from "../EmployCandidate/EmployCandidate";
import ApplicationRejection from "../ApplicationRejection/ApplicationRejection";


const ViewShortlistedCandidates = ({ isOpen, onClose,userId,userData }) => {
    // console.log(userId)
    const [isModalOpen1, setIsModalOpen1] = useState(false);


    const openModal1 = () => setIsModalOpen1(true);
    const closeModal1 = () => setIsModalOpen1(false);


    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);


    const [isModalOpen2, setIsModalOpen2] = useState(false);

    const openModal2 = () => setIsModalOpen2(true);
    const closeModal2 = () => setIsModalOpen2(false);


    const [isModalOpen3, setIsModalOpen3] = useState(false);

    const openModal3 = () => setIsModalOpen3(true);
    const closeModal3 = () => setIsModalOpen3(false);

    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (isOpen) {
            // Delay to give a smooth entrance when the modal opens
            const timer = setTimeout(() => {
                setAnimate(true);
            }, 500); // Adjust delay as needed

            return () => clearTimeout(timer);
        } else {
            setAnimate(false); // Reset animation when the modal is closed
        }
    }, [isOpen]);


    const downloadCV = () => {
        if (!userData?.resume) {
          console.log("CV not available for download.");
          return;
        }
        const fileName = `${userData.first_name}_${userData.other_name}_CV.pdf`;
    
        const completeURL = userData.resume.startsWith("http") 
        ? userData.resume 
        : `https://hr-api.emas.ng${userData.resume}`;
    
        const link = document.createElement("a");
        link.href = completeURL;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    const downloadCover= () => {
        if (!userData?.cover_letter) {
          console.log("CV not available for download.");
          return;
        }
        const fileName = `${userData.first_name}_${userData.other_name}_CV.pdf`;
    
        const completeURL = userData.resume.startsWith("http") 
        ? userData.resume 
        : `https://hr-api.emas.ng${userData.cover_letter

        }`;
    
        const link = document.createElement("a");
        link.href = completeURL;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

   
    if (!isOpen) return null;


    
    return (
        <>
        <SendInterviewInvite isOpen={isModalOpen1} onClose={closeModal1} userId={userId}/>
        <SendOfferLetter isOpen={isModalOpen} onClose={closeModal} userId={userId}/>
        {/* <ApplicationRejection isOpen={isModalOpen2} onClose={closeModal2} userId={userId} /> */}
        <ApplicationRejection isOpen={isModalOpen2} onClose={closeModal2} userId={userId}/>
        {/* <RejectionMail isOpen={isModalOpen2} onClose={closeModal2} /> */}
        <EmployCandidate isOpen={isModalOpen3} onClose={closeModal3} userId={userId} />
            <div className={classes.modalBackdrop}>
                <div className={classes.modalRequest}>
                    <div className={classes.divModalTop}>
                        <h4 className={classes.requet}>Shortlisted Candidates</h4>
                        <img src={cancel} alt="cancel" onClick={onClose} />
                    </div>

                    <h4 className={classes.jobText}>Job Summary</h4>
                    <div className={classes.divInfo}>
                        <div className={classes.sub1}>
                            <div className={classes.jDiv}>
                                <img src={job} alt="job" />
                                <p className={classes.textAcct}>{userData?.job?.title}</p>
                            </div>
                            <div className={classes.jDiv}>
                                <img src={job} alt="job" />
                                <p className={classes.textAcct}>{userData?.job?.job_type}</p>
                            </div>
                            <div className={classes.jDiv}>
                                <img src={job} alt="job" />
                                <p className={classes.textAcct}>{userData?.job?.experience} years of experience required</p>
                            </div>
                            <div className={classes.jDiv}>
                                <img src={job} alt="job" />
                                <p className={classes.textAcct}>Estimated pay for the role -  N{userData?.job?.salary_range_from} to N{userData?.job?.salary_range_to}</p>
                            </div>
                            <div className={classes.jDiv}>
                                <img src={job} alt="job" />
                                <p className={classes.textAcct}>Start date: {userData?.job?.start_date} - End date: {userData?.job?.end_date}</p>
                            </div>
                            <div className={classes.jDiv}>
                                <img src={job} alt="job" />
                                <p className={classes.textAcct}>Number to be hired: {userData?.job?.vacancies}</p>
                            </div>
                        </div>
                    </div>

                    <h4 className={classes.candOne}>Candidate</h4>
                    <div className={classes.secondDiv}>
                        <div className={classes.secDiv}>
                            <div>
                                <p className={classes.firstN}>First Name</p>
                                <h4 className={classes.firstPc}>{userData?.first_name}</h4>
                            </div>
                            <div>
                                <p className={classes.firstN}>Last Name</p>
                                <h4 className={classes.firstPc}>{userData?.other_name}</h4>
                            </div>
                            <div>
                                <p className={classes.firstN}>Email address</p>
                                <h4 className={classes.firstPc}>{userData?.email}</h4>
                            </div>
                            <div>
                                <p className={classes.firstN}>Phone number</p>
                                <h4 className={classes.firstPc}>{userData?.phone_number}</h4>
                            </div>
                        </div>

                        <div className={classes.Btnstylus}>
                        <div className={classes.syDwn}>
                        <p className={classes.downloadTx} onClick={downloadCV} style={{ cursor: 'pointer' }}>
            Download CV <img src={download} alt="download" />
          </p>
                        <p className={classes.downloadTx} onClick={downloadCover} style={{ cursor: 'pointer' }}>
                        Download Cover Letter <img src={download} alt="download" />
          </p>
                                    {/* <p className={classes.downloadTx}>Download CV<img src={download} alt='download' /></p> */}
                                    {/* <p className={classes.downloadTx}>Download Cover Letter<img src={download} alt='download' /></p> */}
                                    <button onClick={openModal1} className={classes.shrBtn223}>Schedule Interview</button>
                                </div>
                            <div className={classes.btnStyT}>
                                <div className={classes.shrtTop}>
                                    <button onClick={openModal3} className={classes.shrBtn21}>Employ Candidate</button>
                                    <button onClick={openModal} className={classes.shrBtn21}>Send offer letter</button>
                                    <button onClick={openModal2} className={classes.shrBtn121}>Send rejection mail</button>
                                </div>
                               
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </>
    );
};

export default ViewShortlistedCandidates;
