import React, { useState, useEffect } from 'react';
import classes from './Analytics.module.css';
import { Link } from 'react-router-dom';
import TopNavBar from '../../Components/TopNavBar/TopNavBar';
import job_application from '../../Assets/job_application.svg';
import postedjobs from '../../Assets/postedJobs.svg';
import TotalJobs from '../../Assets/TotalJobs.svg';
import TotalEmployed from '../../Assets/TotalEmployed.svg';
import TotalRejected from '../../Assets/TotalRejected.svg';
import TotalInterviews from '../../Assets/TotalInterviews.svg'
import JobApplicationModal from '../../Components/Job Application/JobApllicationModal';


const Analytics = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    return (
        <div>
            <TopNavBar />
            <div className={classes.toTUpper}>
            <JobApplicationModal isOpen={isModalOpen} onClose={closeModal} />
                <div className={classes.topperText}>
                    <h4>Job Applications</h4>
                    <div className={classes.firstBtn}>
                        <button onClick={openModal} className={classes.policyBtn}>Create New Job Application</button>
                    </div>
                </div>
                <div className={classes.topt}>
                    <Link to={'/job_application'}><p>New Application</p></Link>
                    <Link to={'/application'}><p>Application Submission</p></Link>
                    <Link to={'/shortlisted_candidates'}><p>Shortlisted Candidates</p></Link>
                    <Link to={'/employed_candidates'}><p>Employed Candidates</p></Link>
                    <Link to={'/archives'}><p>Archives</p></Link>
                    <Link to={'/analytics'}><p>Analytics</p></Link>
                </div>
                <div className={classes.topLeave}>
                    <div>
                        <div className={classes.topTextDiv}>
                            <h4 className={classes.topText}>Analytics</h4>
                        </div>
                    </div>
                </div>

                <div className={classes.workerAnalytics}>
                    <div className={classes.workerjobApplication}>
                            <div className={classes.imageBackgroundcolor}>
                                <img src={job_application}></img>
                        </div>
                        <p><span>548</span> <br /> Jobs applications created </p>
                    </div>
                   


                    <div className={classes.workerjobApplication}>
                            <div className={classes.imageBackgroundcolor}>
                                <img src={postedjobs}></img>
                        </div>
                        <p><span>472</span> <br /> Jobs applications posted </p>
                    </div>
                   


                    <div className={classes.workerjobApplication}>
                            <div className={classes.imageBackgroundcolor}>
                                <img src={TotalJobs}></img>
                        </div>
                        <p><span>4,590</span> <br /> Total Job application submissions </p>
                    </div>
                 

                    <div className={classes.workerjobApplication}>
                            <div className={classes.imageBackgroundcolor}>
                                <img src={TotalEmployed}></img>
                        </div>
                        <p><span>890</span> <br /> Total Employed candidates </p>
                    </div>


                    <div className={classes.workerjobApplication}>
                            <div className={classes.imageBackgroundcolor}>
                                <img src={TotalRejected}></img>
                        </div>
                        <p><span>3,700</span> <br /> Total rejected candidates </p>
                    </div>


                    <div className={classes.workerjobApplication}>
                            <div className={classes.imageBackgroundcolor}>
                                <img src={TotalInterviews}></img>
                        </div>
                        <p><span>1,493</span> <br /> Total Interviews scheduled </p>
                    </div>
                    </div>
                    </div>
            </div>
    )
}

export default Analytics;
