import React, { useEffect, useState } from 'react';
import classes from './ViewCreatedJobApplicationModal.module.css';
import closeX from './CloseX.svg';
import addNew from '../../Assets/addNew.svg';
import attach from '../../Assets/attachfile.svg';
import briefcase from '../../Assets/briefcase.svg';
import facebook from '../../Assets/facebook.svg';
import linked from '../../Assets/linkedIn.svg';
import twitter from '../../Assets/twitter.svg';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

const ViewCreatedJobApplicationModal = ({ isOpen, onClose, jobId }) => {
    // const [animate, setAnimate] = useState(false);
    const [filename, setFileName] = useState("Attach your resume/CV")
    const [filename1, setFileName1] = useState("Attach your cover letter")
    const [loading, setLoading] = useState(false);
    const [jobData, setJobData] = useState({});
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [bearer, setBearer] = useState('');
    const [errorMessage, setErrorMessage] = useState('');



    useEffect(() => {
        const readData = async () => {
            try {
                const value = await AsyncStorage.getItem('tokens');
                if (value !== null && value !== bearer) {
                    setBearer(value); 
                }
            } catch (e) {
                alert('Failed to fetch the token from storage');
            }
        };
        readData();
    }, [bearer]);

    // Fetch job details when modal is open
    useEffect(() => {
        if (isOpen && jobId && bearer) {
            const fetchJobDetails = async () => {
                setErrorMessage('');
                try {
                    const response = await axios.get('https://hr-api.emas.ng/api/job/update', {
                        params: { id: jobId },
                        headers: {
                            Authorization: `Bearer ${bearer}`,
                        },
                    });
                    setJobData(response.data.data);
                } catch (error) {
                    console.error('Error fetching job details:', error);
                    setErrorMessage('Failed to fetch job details');
                }
            };
            fetchJobDetails();
        }
    }, [isOpen, jobId, bearer]);

    // Fetch departments only when bearer token changes
    useEffect(() => {
        if (bearer) {
            const fetchDepartments = async () => {
                try {
                    const response = await axios.get('https://hr-api.emas.ng/api/department/fetchAll', {
                        headers: {
                            Authorization: `Bearer ${bearer}`,
                        },
                    });
                    setDepartments(response.data.data.map(item => ({
                        label: item.name,
                        value: item.id,
                    })));
                } catch (error) {
                    console.error('Error fetching departments:', error);
                    setErrorMessage('Failed to fetch departments');
                }
            };
            fetchDepartments();
        }
    }, [bearer]);

    // Update selectedDepartment based on jobData.department_id
    useEffect(() => {
        if (departments.length > 0 && jobData.department_id) {
            const dept = departments.find(dept => dept.value === jobData.department_id);
            if (dept && dept !== selectedDepartment) {
                setSelectedDepartment(dept); // Only set if it's different
            }
        }
    }, [departments, jobData.department_id, selectedDepartment]);

    if (!isOpen) return null;

    const getFileHandler = (e) => {
        const file = e.target.value.slice(12);
        setFileName(file || "Attach your resume/CV");
    };

    const getFileHandler1 = (e) => {
        const file = e.target.value.slice(12);
        setFileName1(file || "Attach your cover letter");
    };

    return (
        <div className={classes.modalOverlayWhole}>
            <div className={classes.modalErrYes}>
                <button className={classes.closeButtonModally} onClick={onClose}>
                    <img src={closeX} alt='' />
                </button>
                <div className={classes.bodilyBody}>
                    <h4>COMPANY LOGO</h4>

                    <div className={classes.secondBodily}>
                        <h6>{jobData.title}</h6>
                        <p>Lancaster Trust Limited</p>
                        <pre>{jobData.location}</pre>
                    </div>
                    <div className={classes.thirdBodily}>
                        <h6>Job Information</h6>
                        <div className={classes.rowImgFlex}>
                            <img src={briefcase} alt='' />
                            <p>{jobData.job_type} . {jobData.work_type}</p>
                        </div>
                        <div className={classes.rowImgFlex}>
                            <img src={briefcase} alt='' />
                            <p>{jobData.experience} years of experience required</p>
                        </div>
                        <div className={classes.rowImgFlex}>
                            <img src={briefcase} alt='' />
                            <p>Estimated pay for the role - {jobData.salary_range_from} to {jobData.salary_range_to}</p>
                        </div>
                    </div>
                    <div className={classes.thirdBodily}>
                        <h6>Why Lancaster Trust Limited</h6>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                            eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                            voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                            voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                            velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut
                            enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
                            consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel
                            illum qui dolorem eum fugiat quo voluptas nulla pariatur</p>
                    </div>
                    <div className={classes.thirdBodily}>
                        <h6>KPI</h6>
                        <p>{jobData.KPI || 'N/A'}</p>
                    </div>
                    <div className={classes.thirdBodily}>
                        <h6>Description</h6>
                        <p>{jobData.description}</p>
                    </div>
                    <div className={classes.theFormThings}>
                        <h6>Apply for this Job</h6>
                        <form>
                            <div className={classes.labelandInputsDiv}>
                                <label htmlFor='startDate'>First Name</label>
                                <input type='text' id='first Name' required placeholder='Enter Your First Name' />
                            </div>
                            <div className={classes.labelandInputsDiv}>
                                <label htmlFor='startDate'>Last Name</label>
                                <input type='text' id='Last Name' required placeholder='Enter Your Last Name' />
                            </div>
                            <div className={classes.labelandInputsDiv}>
                                <label htmlFor='startDate'>Email Address</label>
                                <input type='email' id='email' required placeholder='Enter Your Email Address' />
                            </div>
                            <div className={classes.labelandInputsDiv}>
                                <label htmlFor='startDate'>Phone Number</label>
                                <input type='text' id='phone number' required placeholder='Enter Your Phone Number' />
                            </div>
                            <div className={classes.labelandInputsDiv}>

                                <label htmlFor='startDate'>Resume/CV</label>
                                <div className={classes.hiddenDiv}><p>{filename}</p> <img src={attach} alt='' /></div>
                                <input type='file' required placeholder='Attach your Resume or CV' className={classes.hidenInput} onChange={getFileHandler} />
                            </div>
                            <div className={classes.labelandInputsDiv}>
                                <label htmlFor='startDate'>Cover Letter</label>
                                <div className={classes.hiddenDiv}><p>{filename1}</p> <img src={attach} alt='' /> </div>
                                <input type='file' id='first Name' required placeholder='Attach your cover letter' className={classes.hidenInput} onChange={getFileHandler1} />
                            </div>

                        </form>

                    </div>
                    <button type='submit' className={classes.addTeamBtn101}>
                        Submit Application
                    </button>
                    <div className={classes.socialsThings}>
                        <h5>Share Job on social media</h5>
                        <div className={classes.socialities}>
                            <div className={classes.sicialgroup}>
                                <img src={facebook} alt='' />
                                <p>Share on Facebook</p>
                            </div>
                            <div className={classes.sicialgroup}>
                                <img src={linked} alt='' />
                                <p>Share on LinkedIn</p>
                            </div>
                            <div className={classes.sicialgroup}>
                                <img src={twitter} alt='' />
                                <p>Share on Twitter</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewCreatedJobApplicationModal;
