import React, { useState, useEffect } from 'react';
import axios from 'axios';
import classes from './Designation.module.css';
import trash from './trash.png';
import edit from './edit.png';
import miss from './miss.png';
import Modal from 'react-modal';
import Select from 'react-select';
import TopNavBar from '../../Components/TopNavBar/TopNavBar';
import AsyncStorage from '@react-native-async-storage/async-storage';
import CloseX from './CloseX.svg';

// Modal.setAppElement('#root');

const Designation = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [newDesignation, setNewDesignation] = useState('');
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [designations, setDesignations] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [bearer, setBearer] = useState('');
    const [animate, setAnimate] = useState(false);
    const [modalIsOpen1, setModalIsOpen1] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null); // new state to store the index




    const customStyles = {
        control: (styles) => ({
            ...styles,
            width: '540px',
            minHeight: '28px',
            height: '40px',
            padding: '0',
            marginTop: '5px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '14px',
        }),
        input: (styles) => ({
            ...styles,
            margin: '0px',
            padding: '1px',
        }),
        indicatorsContainer: (styles) => ({
            ...styles,
            height: '40px',
        }),
        placeholder: (styles) => ({
            ...styles,
            paddingBottom: '5px',
        }),
    };

    // Fetch bearer token
    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('tokens');
            if (value !== null) {
                setBearer(value);
            }
        } catch (e) {
            alert('Failed to fetch the token from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            await readData();
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (bearer) {
            const fetchDesignations = async () => {
                try {
                    const response = await axios.get('https://hr-api.emas.ng/api/designation/fetchAll', {
                        headers: {
                            Authorization: `Bearer ${bearer}`,
                        },
                    });
                    setDesignations(response.data.data);
                } catch (error) {
                    console.error('Error fetching designations:', error);
                }
            };

            const fetchDepartments = async () => {
                if (!bearer) return; // Wait for the token to be set

                try {
                    const response = await axios.get('https://hr-api.emas.ng/api/department/fetchAll', {
                        headers: {
                            Authorization: `Bearer ${bearer}`,
                        },
                    });

                    const list = response.data.data;
                    setDepartments(list.map(item => ({
                        label: item.name,
                        value: item.id,
                    })));
                    console.log(response.data)
                } catch (error) {
                    console.error('Error fetching departments:', error);
                }
            };

            fetchDesignations();
            fetchDepartments();
        }
    }, [bearer]);

    const handleAddDesignationClick = () => {
        setNewDesignation('');
        setSelectedDepartment('');
        setEditIndex(null);
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };
    const onClose1 = () => {
        setModalIsOpen1(false);
    };

    // Add or edit designation
    const handleAddNewDesignation = async () => {
        if (newDesignation.trim() && selectedDepartment) {
            try {
                const payload = {
                    title: newDesignation.trim(),
                    description: selectedDepartment.label,
                };

                if (editIndex !== null) {

                    setLoading(true)
                    // Edit designation
                    await axios.get(`https://hr-api.emas.ng/api/designation/update`, {
                        params: {
                            id: designations[editIndex].id,
                            title: newDesignation.trim(),
                            description: selectedDepartment.label,
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${bearer}`,
                        },
                    });
                    const updatedDesignations = [...designations];
                    updatedDesignations[editIndex] = { ...updatedDesignations[editIndex], ...payload };
                    setDesignations(updatedDesignations);
                    console.log(departments);
                    console.log(designations)
                } else {
                    // Add new designation
                    setLoading(true);
                    const response = await axios.post('https://hr-api.emas.ng/api/designation/create', payload, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${bearer}`,
                        },
                    });
                    setDesignations([...designations, response.data.data]);

                }

                setNewDesignation('');
                setSelectedDepartment('');
                handleCloseModal();
            } catch (error) {
                console.error('Error adding/editing designation:', error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleEditClick = (index) => {
        setEditIndex(index);
        setNewDesignation(designations[index].title);
        setSelectedDepartment({ value: designations[index].description, label: designations[index].description });
        setModalIsOpen(true);
    };


    // Delete designation
    const handleDeleteClick = async () => {
        try {
            if (deleteIndex !== null) { // check if a valid index is selected
                setLoading(true);
                await axios.get(`https://hr-api.emas.ng/api/designation/delete`, {
                    params: { id: designations[deleteIndex].id },
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${bearer}`,
                    },
                });
                const updatedDesignations = designations.filter((_, desigIndex) => desigIndex !== deleteIndex);
                setDesignations(updatedDesignations);
                setDeleteIndex(null); // Reset after deletion
            }
        } catch (error) {
            console.error('Error deleting designation:', error);
        } finally {
            setModalIsOpen1(false);
            setLoading(false);
        }
    };


    return (
        <div>
            <TopNavBar />
            <div className={classes.Designation}>
                <div className={classes.textabove}>
                    <h5>Designations</h5>
                    <div className={classes.buttondesig} onClick={handleAddDesignationClick}>
                        Add Designation
                    </div>
                </div>
                <div className={classes.tableContainer}>
                    <table className={classes.scheduleTable}>
                        <thead>
                            <tr>
                                <th>S/N</th>
                                <th>Designation</th>
                                <th>Department</th>
                                <th className={classes.actionColumn}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {designations.map((desig, index) => (
                                <tr key={index} className={index % 2 === 1 ? classes.highlightRow : ''}>
                                    <td>{index + 1}</td>
                                    <td>{desig.title}</td>
                                    <td>{desig.description}</td>
                                    <td className={classes.actionButtons}>
                                        <button className={classes.editButton} >
                                            <img src={edit} alt="Edit" onClick={() => handleEditClick(index)} />
                                        </button>
                                        <button className={classes.deleteButton}>
                                            <img src={trash} alt="Delete" onClick={() => { setDeleteIndex(index); setModalIsOpen1(true); }} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={handleCloseModal}
                    contentLabel={editIndex !== null ? "Edit Designation" : "Add New Designation"}
                    className={classes.modal}
                    overlayClassName={classes.overlay}
                >
                    <div className={classes.textandimg}>
                        <h2>{editIndex !== null ? "Edit Designation" : "Add Designation"}</h2>
                        <img
                            src={miss}
                            alt="Close"
                            onClick={handleCloseModal}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                    <br />
                    <hr />
                    <br />
                    <p>Designation</p>
                    <input
                        type="text"
                        value={newDesignation}
                        onChange={(e) => setNewDesignation(e.target.value)}
                        placeholder="Enter designation"
                        className={classes.modalInput}
                    />
                    <p>Select Department</p>
                    <Select
                        options={departments}
                        value={selectedDepartment}
                        onChange={(selectedOption) => setSelectedDepartment(selectedOption)}
                        styles={customStyles}
                    />
                    <button onClick={handleAddNewDesignation} className={classes.modalButton} disabled={loading}>
                        {!loading ? (
                            <>
                                {editIndex !== null ? "Save changes" : "Add Designation"}

                            </>
                        ) : (<>Loading...</>)}
                    </button>
                </Modal>


                <Modal 
    isOpen={modalIsOpen1}
>
    <div className={classes.modalOverlayWhole}>
        <div className={classes.modalErrYester}>
            <button className={classes.closeButtonModally} onClick={onClose1}>
                <img src={CloseX} alt='' />
            </button>
            <div className={classes.modalContainerSend}>
                <h6>
                    Delete Designation
                </h6>
                {deleteIndex !== null && (
                    <>
                        <p>
                            You are about to delete the designation: <strong>{designations[deleteIndex]?.title}</strong>. 
                            This action cannot be reversed. Are you sure you want to continue?
                        </p>
                        <button 
                            onClick={handleDeleteClick} 
                            type="submit" 
                            className={classes.addTeamBtn} 
                            disabled={loading}
                        > 
                            {loading ? 'Deleting...' : 'Delete'} 
                        </button>
                    </>
                )}
            </div>
        </div>
    </div>
</Modal>


            </div>
        </div>
    );
};

export default Designation;
