import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import classes from "./JobApplication.module.css";
import less from "../../Assets/less.svg";
import next from "../../Assets/next.svg";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import { Link } from "react-router-dom";
import JobApplicationModal from "../../Components/Job Application/JobApllicationModal";
import eye from '../../Assets/eye.svg';
import edit from "../../Assets/edit.svg";
import deleteimg from "../../Assets/deleteimg.svg";
import ViewCreatedJobApplicationModal from "../../Components/ViewCreatedJobModal/ViewCreatedJobApllicationModal";
import EditJobApplicationModal from "../../Components/Edit Job Application Modal/EditJobApllicationModal";
// import DeleteModal1 from "../../Components/DeleteJobApplication/DeleteJobApplication";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CloseX from '../Employees/CloseX.svg'


const JobApplication = () => {
  // const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [jobApplications, setJobApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  // const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [bearer, setBearer] = useState('');
  const navigate = useNavigate();
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);


  const [isEditModalOpen1, setIsEditModalOpen1] = useState(false);

  const openEditModal1 = (jobId) => {
    setSelectedJobId(jobId);
    setIsEditModalOpen1(true);
  };

  const closeEditModal1 = () => {
    setIsEditModalOpen1(false);
    setSelectedJobId(null);
  };



  const openDeleteModal = (index) => {
    setDeleteIndex(index);
    setIsDeleteModalOpen(true); // Use same modal for deletion confirmation
  };

  const closeDeleteModal = () => {
    setDeleteIndex(null);
    setIsDeleteModalOpen(false);
  };

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('tokens');
      if (value !== null) setBearer(value);
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };


  useEffect(() => {
    readData();
  }, []);

  const fetchJobApplications = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://hr-api.emas.ng/api/job/fetchAll', {
        headers: { Authorization: `Bearer ${bearer}` },
      });
      setJobApplications(response.data.data.job || []);
      console.log(response.data)
      setFilteredApplications(response.data.data.job || []);  // Set both jobApplications and filteredApplications initially
    } catch (error) {
      console.error('Error fetching job applications:', error);
      console.log(error)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {

    if (bearer) {
      fetchJobApplications();
    }
  }, [bearer]);


  // Handle delete (Assuming the delete endpoint is available)
  const handleDelete = async () => {
    try {
      if (deleteIndex !== null) {
        setLoading(true);
        await axios.get(`https://hr-api.emas.ng/api/job/delete`, {
          params: { id: jobApplications[deleteIndex].id },
          headers: {
            Authorization: `Bearer ${bearer}`,
            'Content-Type': 'application/json'
          },
        });
        const updatedJobs = jobApplications.filter((_, desigIndex) => desigIndex !== deleteIndex);
        setJobApplications(updatedJobs);
        setDeleteIndex(null);
      }
    } catch (error) {
      console.error('Error deleting job application:', error);
      console.log(error)
    }
    setLoading(false);
    closeDeleteModal()
    fetchJobApplications()
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [isModalOpen1, setIsModalOpen1] = useState(false);

  const openModal1 = (jobId) => {
    setSelectedJobId(jobId);
    setIsModalOpen1(true);
  }

  const closeModal1 = () => {
    setIsModalOpen1(false);
    setSelectedJobId(null);
  }



  // const [isModalDelete, setIsModalDelete] = useState(false);

  // const deleteModal = () => setIsModalDelete(true);
  // const deleteCloseModal = () => setIsModalDelete(false);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TopNavBar />
      <div className={classes.toTUpper}>
        <JobApplicationModal isOpen={isModalOpen} onClose={closeModal} />
        <ViewCreatedJobApplicationModal isOpen={isModalOpen1} onClose={closeModal1} jobId={selectedJobId} />
        <EditJobApplicationModal isOpen={isEditModalOpen1} onClose={closeEditModal1} jobId={selectedJobId}
          onJobUpdated={fetchJobApplications} />


        <div className={classes.topperText}>
          <h4>Job Applications</h4>
          <div className={classes.firstBtn}>
            <button onClick={openModal} className={classes.policyBtn}>Create New Job Application</button>
          </div>
        </div>
        <div className={classes.topt}>
          <Link to={'/job_application'}><p>New Application</p></Link>
          <Link to={'/application'}><p>Application Submission</p></Link>
          <Link to={'/shortlisted_candidates'}><p>Shortlisted Candidates</p></Link>
          <Link to={'/employed_candidates'}><p>Employed Candidates</p></Link>
          <Link to={'/archives'}><p>Archives</p></Link>
          <Link to={'/analytics'}><p>Analytics</p></Link>
        </div>
        <div className={classes.topLeave}>
          <div>
            <div className={classes.topTextDiv}>
              <h4 className={classes.topText}>Create Job Application</h4>
            </div>

            <div style={{ height: '1px', width: '107%', border: '1px solid #E9ECEF', marginLeft: '-39px' }}></div>

            <div className={classes.tableContainer}>
              <table className={classes.tableDiv}>
                <tr className={classes.tableHeadDiv}>
                  <th className={classes.tableHeadRow}>S/N</th>
                  <th className={classes.tableHeadpflDiv}>Name</th>
                  <th className={classes.tableHeadpflDiv}>Email</th>
                  <th className={classes.tableHeadRow}>Phone No</th>
                  <th className={classes.tableHeadRow}>Job Type</th>
                  <th className={classes.tableHeadRow}>Department</th>
                  {/* <th className={classes.tableHeadRow}>No. of Applicants</th> */}
                  <th className={classes.tableHeadRow}>Action</th>
                </tr>
                {filteredApplications.length > 0 ? (
                  filteredApplications.map((application, index) => (
                    <tr className={classes.tableRowBody} key={application.id}>
                      <td>{index + 1}</td>
                      <td className={classes.pflDiv}>
                        {application.first_name} {application.last_name}
                      </td>
                      <td className={classes.pflDiv}>{application.email}</td>
                      <td>{application.phone}</td>
                      <td>{application.job_type}</td>
                      <td>{application.department.name}</td>
                      {/* <td>{application.applicants_count}</td> */}
                      <td className={classes.formEye}>
                        <img src={eye} alt="approve" onClick={() => openModal1(application.id)} />
                        <img src={edit} alt="approve" onClick={() => openEditModal1(application.id)} />
                        <img src={deleteimg} alt="approve" onClick={() => openDeleteModal(index)} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" style={{ paddingTop: 10 }}>No job applications found.</td>
                  </tr>
                )}
              </table>
            </div>
            <div className={classes.gotoNext}>
              <button className={classes.prev}>
                <img src={less} alt="less" />
                Previous
              </button>
              <p className={classes.pagination}>Page 1 of 5</p>
              <button className={classes.next}>
                Next
                <img src={next} alt="next" />
              </button>
            </div>
            <Modal
              isOpen={isDeleteModalOpen}
            >
              <div className={classes.modalOverlayWhole}>
                <div className={classes.modalErrYester}>
                  <button className={classes.closeButtonModally} onClick={closeDeleteModal}>
                    <img src={CloseX} alt='' />
                  </button>
                  <div className={classes.modalContainerSend}>
                    <h6>
                      Delete Employee
                    </h6>
                    {deleteIndex !== null && (
                      <>
                        <p>
                          You are about to delete the Job Application: <strong>{jobApplications[deleteIndex]?.first_name} {jobApplications[deleteIndex]?.last_name} </strong>.
                          This action cannot be reversed. Are you sure you want to continue?
                        </p>
                        <button
                          onClick={handleDelete}
                          type="submit"
                          className={classes.addTeamBtn}
                          disabled={loading}
                        >
                          {loading ? 'Deleting...' : 'Delete'}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobApplication;
