import React, { useState, useEffect } from 'react';
import classes from './viewAppraisal.module.css'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import logout from '../../Assets/logout.png'
import closeX from './CloseX.svg'
import iconl from '../../Assets/booklgo.svg'
import { Navbar, Container, Button } from 'react-bootstrap';
import download from '../../Assets/newdownload.svg';



function ViewAppraisal({ isOpen, onClose }) {
    const [key, setKey] = useState('Personal Details');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (isOpen) {
            // Delay to give a smooth entrance when the modal opens
            const timer = setTimeout(() => {
                setAnimate(true);
            }, 500); // Adjust delay as needed

            return () => clearTimeout(timer);
        } else {
            setAnimate(false); // Reset animation when the modal is closed
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return (

        <div className={classes.modalOverlayWhole}>
            <div className={classes.modalErrYes}>
                <button className={classes.closeButtonModally} onClick={onClose}>
                    <img src={closeX} alt='' />
                </button>
                <div className={classes.modalContainer22}>
                    <h6>Appraisal</h6>
                </div>
                <div className={classes.headerNameDept}>
                    <h4>Amanda Marcel</h4>
                    <div className={classes.stylepNameDept}>
                        <p>Marketing Manager</p>
                        <p>Marketing Department</p>
                    </div>
                </div>
                <div className={classes.appcontainer}>
                    <div className={classes.maincontent}>
                        <div className={classes.wholediv1}>
                            <div className={classes.generalDiv}>
                                <div className={classes.gees}>

                                    <div className={classes.rqstobj}>
                                        <div className={classes.straightenOutP}>
                                            <img src={iconl} className={classes.styleicon} />
                                            <div className={classes.pTagDesgn}>
                                                <p className={classes.rqsttxt}>Personal Goals</p>
                                                <p className={classes.rqsttxt1001}>View and review the submission of employee personal goals</p>
                                            </div>
                                        </div>
                                        <div className={classes.dtxt}>
                                            <p className={classes.rqsttxt2}>Reviewed by: Lara Oluyemi, Kunle Afolade</p>
                                            <div className={classes.downloadParag}>
                                                <p className={classes.rqsttxt3}>Deadline: 15th September, 2024</p>
                                                <p className={classes.downloadTx}>Download Submission<img src={download} alt='download' /></p>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className={classes.generalDiv}>
                                <div className={classes.gees}>

                                    <div className={classes.rqstobj}>
                                        <div className={classes.straightenOutP}>
                                            <img src={iconl} className={classes.styleicon} />
                                            <div className={classes.pTagDesgn}>
                                                <p className={classes.rqsttxt}>Team Goals</p>
                                                <p className={classes.rqsttxt1001}>View and review the submission of employee personal goals</p>
                                            </div>
                                        </div>
                                        <div className={classes.dtxt}>
                                            <p className={classes.rqsttxt2}>Reviewed by: Lara Oluyemi, Kunle Afolade</p>
                                            <div className={classes.downloadParag}>
                                                <p className={classes.rqsttxt3}>Deadline: 15th September, 2024</p>
                                                <p className={classes.downloadTx}>Download Submission<img src={download} alt='download' /></p>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className={classes.generalDiv}>
                                <div className={classes.gees}>

                                    <div className={classes.rqstobj}>
                                        <div className={classes.straightenOutP}>
                                            <img src={iconl} className={classes.styleicon} />
                                            <div className={classes.pTagDesgn}>
                                                <p className={classes.rqsttxt}>General Goals</p>
                                                <p className={classes.rqsttxt1001}>View and review the submission of employee personal goals</p>
                                            </div>
                                        </div>
                                        <div className={classes.dtxt}>
                                            <p className={classes.rqsttxt2}>Reviewed by: Lara Oluyemi, Kunle Afolade</p>
                                            <div className={classes.downloadParag}>
                                                <p className={classes.rqsttxt3}>Deadline: 15th September, 2024</p>
                                                <p className={classes.downloadTx}>Download Submission<img src={download} alt='download' /></p>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className={classes.generalDiv}>
                                <div className={classes.gees}>

                                    <div className={classes.rqstobj}>
                                        <div className={classes.straightenOutP}>
                                            <img src={iconl} className={classes.styleicon} />
                                            <div className={classes.pTagDesgn}>
                                                <p className={classes.rqsttxt}>Training and Certification Goals</p>
                                                <p className={classes.rqsttxt1001}>View and review the submission of employee personal goals</p>
                                            </div>
                                        </div>
                                        <div className={classes.dtxt}>
                                            <p className={classes.rqsttxt2}>Reviewed by: Lara Oluyemi, Kunle Afolade</p>
                                            <div className={classes.downloadParag}>
                                                <p className={classes.rqsttxt3}>Deadline: 15th September, 2024</p>
                                                <p className={classes.downloadTx}>Download Submission<img src={download} alt='download' /></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={classes.wholediv12345}>
                                <h5 className={classes.ProfileHead}>Professional Excellence</h5>
                                <div className={classes.tableContainer}>
                                    <table className={classes.scheduleTable}>
                                        <thead>
                                            <tr>
                                                <th>S/N</th>
                                                <th>Key Result Area</th>
                                                <th>Key Performance Inidcator</th>
                                                <th>Points Weightage</th>
                                                <th>Points Scored</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td> 1</td>
                                                <td>Work Quality</td>
                                                <td>Quality of work and turn around time</td>
                                                <td><p className={classes.borderAdd}>30</p></td>
                                                <td>24</td>
                                                {/* <td>08:00am - 05:00pm</td> */}
                                            </tr>
                                            <tr className={classes.highlightRow}>
                                                <td> 2</td>
                                                <td>Process Improvement</td>
                                                <td>Execution of new Ideas</td>
                                                <td>10</td>
                                                <td>10</td>
                                                {/* <td>08:00am - 05:00pm</td> */}
                                            </tr>
                                            <tr>
                                                <td> 3</td>
                                                <td>Team Management</td>
                                                <td>Team productivity, attendance and attrition</td>
                                                <td>10</td>
                                                <td>5</td>
                                                {/* <td>08:00am - 05:00pm</td> */}
                                            </tr>
                                            <tr className={classes.highlightRow}>
                                                <td> 4</td>
                                                <td>Knowledge Sharing</td>
                                                <td>Sharing of knowledge among team members</td>
                                                <td>5</td>
                                                <td>4</td>
                                            </tr>
                                            <tr>
                                                <td> 5</td>
                                                <td>Reporting and Communication</td>
                                                <td>Emails, reports, calls, texts, etc</td>
                                                <td>5</td>
                                                <td>5</td>

                                            </tr>
                                            <tr className={classes.highlightRow}>
                                                <td></td>
                                                <td>Total Score</td>
                                                <td></td>
                                                <td>60</td>
                                                <td>48</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>




                            <div className={classes.wholedivide12345}>
                                <h5 className={classes.ProfileHeadFive}>Personal Excellence</h5>
                                <div className={classes.tableContainer}>
                                    <table className={classes.scheduleTable}>
                                        <thead>
                                            <tr>
                                                <th>S/N</th>
                                                <th>Key Result Area</th>
                                                <th>Key Performance Inidcator</th>
                                                <th>Points Weightage</th>
                                                <th>Points Scored</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td> 1</td>
                                                <td>Work Quality</td>
                                                <td>Quality of work and turn around time</td>
                                                <td>30</td>
                                                <td>24</td>
                                                {/* <td>08:00am - 05:00pm</td> */}
                                            </tr>
                                            <tr className={classes.highlightRow}>
                                                <td> 2</td>
                                                <td>Process Improvement</td>
                                                <td>Execution of new Ideas</td>
                                                <td>10</td>
                                                <td>10</td>
                                                {/* <td>08:00am - 05:00pm</td> */}
                                            </tr>
                                            <tr>
                                                <td> 3</td>
                                                <td>Team Management</td>
                                                <td>Team productivity, attendance and attrition</td>
                                                <td>10</td>
                                                <td>5</td>
                                                {/* <td>08:00am - 05:00pm</td> */}
                                            </tr>
                                            <tr className={classes.highlightRow}>
                                                <td> 4</td>
                                                <td>Knowledge Sharing</td>
                                                <td>Sharing of knowledge among team members</td>
                                                <td>5</td>
                                                <td>4</td>
                                            </tr>
                                            <tr>
                                                <td> 5</td>
                                                <td>Reporting and Communication</td>
                                                <td>Emails, reports, calls, texts, etc</td>
                                                <td>5</td>
                                                <td>5</td>

                                            </tr>
                                            <tr className={classes.highlightRow}>
                                                <td></td>
                                                <td>Total Score</td>
                                                <td></td>
                                                <td>60</td>
                                                <td>48</td>

                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                                <div className={classes.contentRewper}>
                            <h4>Comments on Employee</h4>
                            <textarea rows='13' className={classes.pTextParagraph}
                                name='description'
                               
                                placeholder='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.'>

                            </textarea>
                        </div>
                        <div className={classes.Btnalign}>                       
                            <button type='submit' className={classes.additionTeamBtns}>Mark Appraisal as completed</button>
                            </div>
 
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default ViewAppraisal;