import React from "react";
import classes from "./ViewCandidate.module.css";
import image1 from "../../Assets/Ellipse 1 (1).png";
import image2 from "../../Assets/Ellipse 1.png";
import approve from "../../Assets/approve.svg";
import decline from "../../Assets/decline.svg";
import view from "../../Assets/view.svg";
import less from "../../Assets/less.svg";
import next from "../../Assets/next.svg";
import plus from "../../Assets/plus.svg";
// import TopNavBar from "../../Components/ErrorModal/TopNavBar/TopNavBar";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import cancel from "../../Assets/cancel.svg";
import job from "../../Assets/job.svg";
import download from '../../Assets/download.svg'

const ViewCandidate = () => {
  return (
    <>
      <div className={classes.modalBackdrop}>
        <div className={classes.modalRequest}>
          <div className={classes.divModalTop}>
            <h4 className={classes.requet}>View Candidates</h4>
            <img src={cancel} alt="cancel" />
          </div>

          <h4 className={classes.jobText}>Job Summary</h4>
          <div className={classes.divInfo}>
            <div className={classes.sub1}>
              <div className={classes.jDiv}>
                <img src={job} alt="job" />
                <p className={classes.textAcct}>Accountant</p>
              </div>
              <div className={classes.jDiv}>
                <img src={job} alt="job" />
                <p className={classes.textAcct}>On-site</p>
              </div>
              <div className={classes.jDiv}>
                <img src={job} alt="job" />
                <p className={classes.textAcct}>3 years of experience required</p>
              </div>
              <div className={classes.jDiv}>
                <img src={job} alt="job" />
                <p className={classes.textAcct}>Estimated pay for the role - N250,000 to N350,000</p>
              </div>
              <div className={classes.jDiv}>
                <img src={job} alt="job" />
                <p className={classes.textAcct}>Start date: 21/08/2024 - End date: 31/08/2024</p>
              </div>
              <div className={classes.jDiv}>
                <img src={job} alt="job" />
                <p className={classes.textAcct}>Number to be hired: 2</p>
              </div>
            </div>
            <div className={classes.btnSub}>
              <button className={classes.btnB}>Stop accepting submissions</button>
            </div>
          </div>

          <h4 className={classes.candOne}>Candidate 1</h4>
          <div className={classes.secondDiv}>
            <div className={classes.secDiv}>
            <div>
              <p className={classes.firstN}>First Name</p>
              <h4 className={classes.firstPc}>Joshua</h4>
            </div>
            <div>
              <p className={classes.firstN}>Last Name</p>
              <h4 className={classes.firstPc}>Olowo</h4>
            </div>
            <div>
              <p className={classes.firstN}>Email address</p>
              <h4 className={classes.firstPc}>Joshua@gmail.com</h4>
            </div>
            <div>
              <p className={classes.firstN}>Phone number</p>
              <h4 className={classes.firstPc}>08090010002</h4>
            </div>
            </div>

            <div className={classes.btnStyT}>
              <div className={classes.syDwn}>
              <p className={classes.downloadTx}>Download CV<img src={download} alt='download'/></p>
              <p className={classes.downloadTx}>Download CV<img src={download} alt='download'/></p>
              </div>
              <div className={classes.shrtTop}>
                <button className={classes.shrBtn}>Shortlist Candidate</button>
                <button className={classes.shrBtn2}>Send rejection mail</button>
              </div>
            </div>
          </div>
          <h4 className={classes.candOne}>Candidate 2</h4>
          <div className={classes.secondDiv}>
            <div className={classes.secDiv}>
            <div>
              <p className={classes.firstN}>First Name</p>
              <h4 className={classes.firstPc}>Joshua</h4>
            </div>
            <div>
              <p className={classes.firstN}>Last Name</p>
              <h4 className={classes.firstPc}>Olowo</h4>
            </div>
            <div>
              <p className={classes.firstN}>Email address</p>
              <h4 className={classes.firstPc}>Joshua@gmail.com</h4>
            </div>
            <div>
              <p className={classes.firstN}>Phone number</p>
              <h4 className={classes.firstPc}>08090010002</h4>
            </div>
            </div>

            <div className={classes.btnStyT}>
              <div className={classes.syDwn}>
              <p className={classes.downloadTx}>Download CV<img src={download} alt='download'/></p>
              <p className={classes.downloadTx}>Download CV<img src={download} alt='download'/></p>
              </div>
              <div className={classes.shrtTop}>
                <button className={classes.shrBtn}>Shortlist Candidate</button>
                <button className={classes.shrBtn2}>Send rejection mail</button>
              </div>
            </div>
          </div>
          <h4 className={classes.candOne}>Candidate 3</h4>
          <div className={classes.secondDiv}>
            <div className={classes.secDiv}>
            <div>
              <p className={classes.firstN}>First Name</p>
              <h4 className={classes.firstPc}>Joshua</h4>
            </div>
            <div>
              <p className={classes.firstN}>Last Name</p>
              <h4 className={classes.firstPc}>Olowo</h4>
            </div>
            <div>
              <p className={classes.firstN}>Email address</p>
              <h4 className={classes.firstPc}>Joshua@gmail.com</h4>
            </div>
            <div>
              <p className={classes.firstN}>Phone number</p>
              <h4 className={classes.firstPc}>08090010002</h4>
            </div>
            </div>

            <div className={classes.btnStyT}>
              <div className={classes.syDwn}>
              <p className={classes.downloadTx}>Download CV<img src={download} alt='download'/></p>
              <p className={classes.downloadTx}>Download CV<img src={download} alt='download'/></p>
              </div>
              <div className={classes.shrtTop}>
                <button className={classes.shrBtn}>Shortlist Candidate</button>
                <button className={classes.shrBtn2}>Send rejection mail</button>
              </div>
            </div>
          </div>
          <h4 className={classes.candOne}>Candidate 4</h4>
          <div className={classes.secondDiv}>
            <div className={classes.secDiv}>
            <div>
              <p className={classes.firstN}>First Name</p>
              <h4 className={classes.firstPc}>Joshua</h4>
            </div>
            <div>
              <p className={classes.firstN}>Last Name</p>
              <h4 className={classes.firstPc}>Olowo</h4>
            </div>
            <div>
              <p className={classes.firstN}>Email address</p>
              <h4 className={classes.firstPc}>Joshua@gmail.com</h4>
            </div>
            <div>
              <p className={classes.firstN}>Phone number</p>
              <h4 className={classes.firstPc}>08090010002</h4>
            </div>
            </div>

            <div className={classes.btnStyT}>
              <div className={classes.syDwn}>
              <p className={classes.downloadTx}>Download CV<img src={download} alt='download'/></p>
              <p className={classes.downloadTx}>Download CV<img src={download} alt='download'/></p>
              </div>
              <div className={classes.shrtTop}>
                <button className={classes.shrBtn}>Shortlist Candidate</button>
                <button className={classes.shrBtn2}>Send rejection mail</button>
              </div>
            </div>
          </div>
          <h4 className={classes.candOne}>Candidate 5</h4>
          <div className={classes.secondDiv}>
            <div className={classes.secDiv}>
            <div>
              <p className={classes.firstN}>First Name</p>
              <h4 className={classes.firstPc}>Joshua</h4>
            </div>
            <div>
              <p className={classes.firstN}>Last Name</p>
              <h4 className={classes.firstPc}>Olowo</h4>
            </div>
            <div>
              <p className={classes.firstN}>Email address</p>
              <h4 className={classes.firstPc}>Joshua@gmail.com</h4>
            </div>
            <div>
              <p className={classes.firstN}>Phone number</p>
              <h4 className={classes.firstPc}>08090010002</h4>
            </div>
            </div>

            <div className={classes.btnStyT}>
              <div className={classes.syDwn}>
              <p className={classes.downloadTx}>Download CV<img src={download} alt='download'/></p>
              <p className={classes.downloadTx}>Download CV<img src={download} alt='download'/></p>
              </div>
              <div className={classes.shrtTop}>
                <button className={classes.shrBtn}>Shortlist Candidate</button>
                <button className={classes.shrBtn2}>Send rejection mail</button>
              </div>
            </div>
          </div>
          <h4 className={classes.candOne}>Candidate 6</h4>
          <div className={classes.secondDiv}>
            <div className={classes.secDiv}>
            <div>
              <p className={classes.firstN}>First Name</p>
              <h4 className={classes.firstPc}>Joshua</h4>
            </div>
            <div>
              <p className={classes.firstN}>Last Name</p>
              <h4 className={classes.firstPc}>Olowo</h4>
            </div>
            <div>
              <p className={classes.firstN}>Email address</p>
              <h4 className={classes.firstPc}>Joshua@gmail.com</h4>
            </div>
            <div>
              <p className={classes.firstN}>Phone number</p>
              <h4 className={classes.firstPc}>08090010002</h4>
            </div>
            </div>

            <div className={classes.btnStyT}>
              <div className={classes.syDwn}>
              <p className={classes.downloadTx}>Download CV<img src={download} alt='download'/></p>
              <p className={classes.downloadTx}>Download CV<img src={download} alt='download'/></p>
              </div>
              <div className={classes.shrtTop}>
                <button className={classes.shrBtn}>Shortlist Candidate</button>
                <button className={classes.shrBtn2}>Send rejection mail</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TopNavBar />
      <div className={classes.toTUpper}>
        <h4 className={classes.topperText}>Leave Management</h4>
        <div className={classes.firstBtn}>
          <button className={classes.policyBtn}>Create Leave Policy</button>
          <button className={classes.addBtn}>
            <img src={plus} alt="plus" />
            Add Leave
          </button>
        </div>
        <div className={classes.topt}>
          <p>Leave Request</p>
          <p>Approved</p>
          <p>Declined</p>
          <p>Leave Policy</p>
        </div>
        <div className={classes.topLeave}>
          <div>
            <div className={classes.topTextDiv}>
              <h4 className={classes.topText}>Leave Request</h4>
            </div>

            <table className={classes.tableDiv}>
              <tr className={classes.tableHeadDiv}>
                <th className={classes.tableHeadRow}>S/N</th>
                <th className={classes.tableHeadRow}>Employee Name</th>
                <th className={classes.tableHeadRow}>Designation</th>
                <th className={classes.tableHeadRow}>Start date</th>
                <th className={classes.tableHeadRow}>End date</th>
                <th className={classes.tableHeadRow}>Status</th>
                <th className={classes.tableHeadRow}>View</th>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>1</td>
                <td className={classes.pflDiv}>
                  <img src={image1} alt="one" />
                  Matthew Olowade
                </td>
                <td>Media Project Manager </td>
                <td>21/08/2024</td>
                <td>25/08/2024</td>
                <td>
                  <p className={classes.approvedDiv}>Appproved</p>
                </td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>2</td>
                <td className={classes.pflDiv}>
                  <img src={image2} alt="two" />
                  Lauren Annabelle
                </td>
                <td>Art Director </td>
                <td>27/08/2024</td>
                <td>28/08/2024</td>
                <td>
                  <p className={classes.approvedDiv}>Appproved</p>
                </td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>1</td>
                <td className={classes.pflDiv}>
                  <img src={image1} alt="one" />
                  Matthew Olowade
                </td>
                <td>Media Project Manager </td>
                <td>21/08/2024</td>
                <td>25/08/2024</td>
                <td>
                  <p className={classes.approvedDiv}>Appproved</p>
                </td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>2</td>
                <td className={classes.pflDiv}>
                  <img src={image2} alt="two" />
                  Lauren Annabelle
                </td>
                <td>Art Director </td>
                <td>27/08/2024</td>
                <td>28/08/2024</td>
                <td className={classes.btnSet}>
                  <p className={classes.approvedDiv}>Appproved</p>
                </td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
            </table>
            <div className={classes.gotoNext}>
              <button className={classes.prev}>
                <img src={less} alt="less" />
                Previous
              </button>
              <p className={classes.pagination}>Page 1 of 5</p>
              <button className={classes.next}>
                Next
                <img src={next} alt="next" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCandidate;
