import React, { useState,useContext,useEffect } from "react";
import classes from "./LeaveManagementDecline.module.css";
import image1 from "../../Assets/Ellipse 1 (1).png";
import image2 from "../../Assets/Ellipse 1.png";
import approve from "../../Assets/approve.svg";
import decline from "../../Assets/decline.svg";
import view from "../../Assets/view.svg";
import less from "../../Assets/less.svg";
import next from "../../Assets/next.svg";
import plus from "../../Assets/plus.svg";
// import TopNavBar from "../../Components/ErrorModal/TopNavBar/TopNavBar";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import { Link } from "react-router-dom";
import LeaveDeclineModal from "../LeaveDeclineModal/LeaveDeclineModal";
import CreatePolicy from "../CreatePolicy/CreatePolicy";
import AddLeave from "../AddLeave/AddLeave";
import { ModalCtx } from "../../ModalContext";
import {useNavigate } from "react-router-dom";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL } from '../api/api';
import axios from 'axios';


const LeaveManagementDecline = () => {
  const navigate = useNavigate(); 
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [decline,setDecline] = useState([])
  const [showRequest,setShowRequest] = useState(false)
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

  const {showDecline,setShowDecline} = useContext(ModalCtx);

  const [showPolicy,setShowPolicy] = useState(false)
  const [showLeave,setShowLeave] = useState(false)
  
  const showRequestHandler = () => {
    setShowDecline(true)
  }

  const showPolicyHandler = () => {
    setShowPolicy(true)
  }

  const showLeaveHandler  = () => {
    setShowLeave(true)
  }

 
  const readData = async () => {
    try {
        const value = await AsyncStorage.getItem('tokens');
        // console.log(value)
        if (value !== null) {
            setBearer(value);
        }
    } catch (e) {
        alert('Failed to fetch the token from storage');
    }
};

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const fetchBeneficiaries = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/leave/fetch-disapproved-leaves`, { headers });
      const results = response.data?.data?.leave;
      console.log(results)
      // console.log(response.data?.data)
      setDecline(results);
    
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setDecline([]);
    }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    function closeAddLeave (event) {
    if(event.code === 'Escape') {
      setShowLeave(false)
    }
    }
    window.addEventListener('keydown',closeAddLeave)

    return () => {
      window.removeEventListener('keydown',closeAddLeave)
    }
  })

  useEffect(() => {
    function closeAddLeave (event) {
    if(event.code === 'Escape') {
      setShowRequest(false)
    }
    }
    window.addEventListener('keydown',closeAddLeave)

    return () => {
      window.removeEventListener('keydown',closeAddLeave)
    }
  })
  
useEffect(() => {

  readData();
  }, []);


useEffect(() => {

  if (bearer) {
    fetchBeneficiaries();

  }
}, [bearer,showLeave]);

const employeeHandler = (items) => {
  setSelectedEmployeeId(items)
  setShowDecline(true)
}
useEffect(() => {
  function closeAddLeave (event) {
  if(event.code === 'Escape') {
    setShowDecline(false)
  }
  }
  window.addEventListener('keydown',closeAddLeave)

  return () => {
    window.removeEventListener('keydown',closeAddLeave)
  }
})

useEffect(() => {
  function closeAddLeave (event) {
  if(event.code === 'Escape') {
    setShowDecline(false)
  }
  }
  window.addEventListener('keydown',closeAddLeave)

  return () => {
    window.removeEventListener('keydown',closeAddLeave)
  }
})

  
  return (
    <>
     {showPolicy &&  <CreatePolicy onShow={setShowPolicy}/>}
     {showLeave &&  <AddLeave onShow={setShowLeave}/>}
    {showDecline &&  <LeaveDeclineModal onShow={setShowDecline} employeeId={selectedEmployeeId}/>}
   
    <TopNavBar />
    <div className={classes.toTUpper}>
    <h4 className={classes.topperText}>Leave Management</h4>
    <div className={classes.firstBtn}>
    <button className={classes.policyBtn} onClick={showPolicyHandler}>Create Leave Policy</button>
    {/* <button className={classes.addBtn} onClick={showLeaveHandler}><img src={plus}  alt="plus" />Add Leave</button> */}
    </div>
      <div className={classes.topt}>
       
      <Link to={'/leave_management'}> <p>Leave Request</p></Link>
       <Link to={'/leave_approved'}><p>Approved</p></Link>
       <Link to={'/leave_decline'}><p>Declined</p></Link>
       <Link to={'/leave_policy'}><p>Leave Policy</p></Link>
      </div>
      <div className={classes.topLeave}>
        <div>
          <div className={classes.topTextDiv}>
            <h4 className={classes.topText}>Leave Request</h4>
          </div>
          {/* <div></div> */}
          <div style={{height:'1px',width:'107%',border:'1px solid #E9ECEF',marginLeft:'-39px'}}></div>

          <table className={classes.tableDiv}>
            <tr className={classes.tableHeadDiv}>
              <th className={classes.tableHeadRow}>S/N</th>
              <th className={classes.tableHeadRow}>Employee Name</th>
              <th className={classes.tableHeadRow}>Designation</th>
              <th className={classes.tableHeadRow}>Start date</th>
              <th className={classes.tableHeadRow}>End date</th>
              <th className={classes.tableHeadRow}>Status</th>
              <th className={classes.tableHeadRow}>View</th>
            </tr>
            {
              isLoading && <p> Loading ...</p>
            }
            {
              !isLoading && decline.length === 0 && <p>No Data Available</p>
            }
            {
              decline.map((items,index) => (
                <tr className={classes.tableRowBody} key={items.id}>

                <td>{index + 1}</td>
                <td className={classes.pflDiv}>
                  <img src={image1} alt="one" />
                  {`${items?.employee?.first_name} ${items?.employee?.last_name}`}
                </td>
                <td>{items?.employee?.position} </td>
                <td>{items?.start_date}</td>
                <td>{items?.end_date}</td>
                <td>
                <p className={classes.approvedDiv}>Declined</p>
                </td>
                <td>
               <button onClick={() => employeeHandler(items.id)} style={{background:'transparent',border:'none'}}> <img src={view} alt="approve" style={{height:'25px',width:'25px'}}/></button> 
                </td>
              </tr>
              ))
            }
        
            {/* <tr className={classes.tableRowBody}>
              <td>2</td>
              <td className={classes.pflDiv}>
                <img src={image2} alt="two" />
                Lauren Annabelle
              </td>
              <td>Art Director </td>
              <td>27/08/2024</td>
              <td>28/08/2024</td>
              <td className={classes.btnSet}>
              <p className={classes.approvedDiv}>Declined</p>
              </td>
              <td>
              <img src={view} alt="approve" style={{height:'25px',width:'25px'}} onClick={showRequestHandler}/>
              </td>
            </tr> */}
          </table>
          <div className={classes.gotoNext}>
            <button className={classes.prev}>
              <img src={less} alt="less" />
              Previous
            </button>
            <p className={classes.pagination}>Page 1 of 5</p>
            <button className={classes.next}>
              Next
              <img src={next} alt="next" />
            </button>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default LeaveManagementDecline;
