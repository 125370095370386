import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import classes from './employee.module.css';
import TopNavBar from '../../Components/TopNavBar/TopNavBar';
import search from '../../Assets/search.svg';
import AddEmployeeModal from '../../Components/Add Employee Modal/AddEmpModal';
import axios from 'axios';
import profile from '../../Assets/profileIcon.svg';
import AsyncStorage from '@react-native-async-storage/async-storage';
import plus from '../../Assets/plus.svg';
import Select from 'react-select';
import eye from '../../Assets/eye.svg';
import edit from "../../Assets/edit.svg";
import deleteimg from "../../Assets/deleteimg.svg";
import EditEmployee from '../../Components/EditEmployee/EditEmployee';
import CloseX from './CloseX.svg';
// Modal.setAppElement('#root'); // Uncomment if using in a non-root element

function Employees() {
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [bearer, setBearer] = useState('');
    const [options1, setOptions1] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [searchOn, setSearchOn] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
    

    const openAddModal = () => setIsAddModalOpen(true);
    const closeAddModal = () => setIsAddModalOpen(false);

    const openEditModal = (employeeId) => {
        setSelectedEmployeeId(employeeId);
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
        setSelectedEmployeeId(null);
    };

    const openDeleteModal = (index) => {
        setDeleteIndex(index);
        setIsDeleteModalOpen(true); // Use same modal for deletion confirmation
    };

    const closeDeleteModal = () => {
        setDeleteIndex(null);
        setIsDeleteModalOpen(false);
    };


    const customStyles = {
        control: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',    
          height: '40px',          
          width: '250px',
          border: '1px solid #6C757D',
          borderRadius: '4px',
          padding: '0',
          boxSizing: 'border-box', 
          fontSize: '14px',
          cursor: 'pointer',
        }),
        input: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          alignSelf: 'center',     
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: '0 15px',      
          height: '100%',     
        }),
        singleValue: (provided) => ({
          ...provided,
          margin: '0',            
          padding: '0',         
        }),
        placeholder: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          lineHeight: 'normal',
        }),
      };
      


      
  

    useEffect(() => {
        const fetchDepartments = async () => {
            if (!bearer) return;
            try {
                const response = await axios.get('https://hr-api.emas.ng/api/department/fetchAll', {
                    headers: { Authorization: `Bearer ${bearer}` },
                });

                const list = response.data.data;
                setOptions1(list.map(item => ({
                    label: item.name,
                    value: item.id,
                })));
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        };
        fetchDepartments();
    }, [bearer]);

    const fetchEmployees = async () => {
        try {
            const response = await axios.get('https://hr-api.emas.ng/api/employees/fetchAll', {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${bearer}`,
                },
            });
            setEmployees(response.data.data || []);
            setFilteredEmployees(response.data.data || []); 
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('tokens');
            if (value !== null) setBearer(value);
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    useEffect(() => {
        if (bearer) {
            fetchEmployees();
        }
    }, [bearer]);

    const filterEmployees = () => {
        let filtered = employees;

        if (searchText) {
            setSearchOn(true);
            filtered = filtered.filter(employee =>
                `${employee.first_name} ${employee.last_name}`.toLowerCase().includes(searchText.toLowerCase())
            );
        }

        if (selectedDepartment) {
            setSearchOn(true);
            filtered = filtered.filter(employee =>
                employee.department && employee.department.id === selectedDepartment.value
            );
        }

        setFilteredEmployees(filtered);
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
        filterEmployees();
    };

    const handleDepartmentChange = (selectedOption) => {
        setSelectedDepartment(selectedOption);
        filterEmployees();
    };

    const handleViewAll = () => {
        setSearchText('');
        setSelectedDepartment(null);
        setFilteredEmployees(employees);
        setSearchOn(false);
    };

    const handleView = (employeeId) => {
        navigate(`/employee_profile/${employeeId}`);
        setSearchOn(false);
    };

    const handleDeleteClick = async () => {
        try {
            if (deleteIndex !== null) {
                setLoading(true);
                await axios.get(`https://hr-api.emas.ng/api/employees/delete`, {
                    params: { id: employees[deleteIndex].id },
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${bearer}`,
                    },
                });
                const updatedEmployees = employees.filter((_, desigIndex) => desigIndex !== deleteIndex);
                setEmployees(updatedEmployees);
                setDeleteIndex(null);
            }
        } catch (error) {
            console.error('Error deleting Employee:', error);
        } finally {
            closeDeleteModal();
            setLoading(false);
        }
        fetchEmployees()
    };

    return (
        <div>
            <TopNavBar />
            <div className={classes.cover}>
                <AddEmployeeModal isOpen={isAddModalOpen} onClose={closeAddModal} onEmployeeAdded={fetchEmployees} />
                <EditEmployee
                    isOpen={isEditModalOpen}
                    onClose={closeEditModal}
                    employeeId={selectedEmployeeId}
                    onEmployeeUpdated={fetchEmployees}
                />
                <p className={classes.paraWeightfont}>Employees</p>
                <div className={classes.containerContain}>
                    <p>Employee Summary</p>
                    <div className={classes.headerBar}>
                        <div className={classes.align}>
                            <img src={search} alt='' />
                            <input
                                type='text'
                                placeholder='Search employee name'
                                value={searchText}
                                onChange={handleSearchChange}
                            />
                            <Select
                                className={classes.selectGroupInfo}
                                name='department'
                                options={options1}
                                value={selectedDepartment}
                                onChange={handleDepartmentChange}
                                isClearable
                                styles={customStyles}
                            />
                            <button onClick={filterEmployees}>Search</button>

                            {searchOn && (
                                <button onClick={handleViewAll}>View All</button>
                            )}
                        </div>
                        <button className={classes.decorate} onClick={openAddModal}>
                            <img src={plus} alt='' />
                            <p>Add Employee</p>
                        </button>
                    </div>
                    <div className={classes.tableContainer}>
                        <table className={classes.tableDiv}>
                            <thead>
                                <tr className={classes.tableHeadDiv}>
                                    <th>S/N</th>
                                    <th className={classes.tableRowBodyCenterWrap}>Employee Picture</th>
                                    <th>Employee Name</th>
                                    <th>Email Address</th>
                                    <th>Title</th>
                                    <th>Department</th>
                                    <th className={classes.actionMove}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredEmployees.length > 0 ? (
                                    filteredEmployees.map((employee, index) => (
                                        <tr key={employee.id} className={classes.tableRowBody}>
                                            <td>{index + 1}</td>
                                            <td className={classes.imgPaddingNone}><img src={employee?.personal_details[0]?.image || profile} alt='profile' style={{width:40,height:40,borderRadius:4}}/></td>
                                            <td className={classes.tableRowBodyLeft}>{employee.first_name} {employee.last_name}</td>
                                            <td className={classes.tableRowBodyLeft}>{employee.email}</td>
                                            <td className={classes.tableRowBodyLeft}>{employee.position}</td>
                                            <td className={classes.tableRowBodyDept}>{employee.department ? employee.department.name : 'No Department'}</td>
                                            <td className={classes.formEye}>
                                                <img src={eye} alt='View' onClick={() => handleView(employee.id)} />
                                                <img src={edit} alt='Edit' onClick={() => openEditModal(employee.id)} />
                                                <img src={deleteimg} alt='Delete' onClick={() => openDeleteModal(index)} />
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7">No employees found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <Modal
                            isOpen={isDeleteModalOpen}
                        >
                            <div className={classes.modalOverlayWhole}>
                                <div className={classes.modalErrYester}>
                                    <button className={classes.closeButtonModally} onClick={closeDeleteModal}>
                                        <img src={CloseX} alt='' />
                                    </button>
                                    <div className={classes.modalContainerSend}>
                                        <h6>
                                            Delete Employee
                                        </h6>
                                        {deleteIndex !== null && (
                                            <>
                                                <p>
                                                    You are about to delete the employee: <strong>{employees[deleteIndex]?.first_name} {employees[deleteIndex]?.last_name}</strong>.
                                                    This action cannot be reversed. Are you sure you want to continue?
                                                </p>
                                                <button
                                                    onClick={handleDeleteClick}
                                                    type="submit"
                                                    className={classes.addTeamBtn}
                                                    disabled={loading}
                                                >
                                                    {loading ? 'Deleting...' : 'Delete'}
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Employees;
