import React, { useState, useContext } from 'react';
import classes from '../Organization Details/org_details.module.css';
import Sidebar from '../../Components/SideBar/SideBar';
import { BiRightArrowAlt } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { Navbar, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { FormContext } from '../../Components/FormContext';


const Org_Details = () => {

    const navigate = useNavigate();

    const handleNext = () => {
        navigate('/address'); // Updated path to /work
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { formData, setFormData } = useContext(FormContext);



    return (
        <div className={classes.containers}>
            <div className={classes.sidenav}>
                <Navbar expand="lg" className={`d-none d-md-block ${classes.navbar}`}>
                    <Container fluid>
                    </Container>
                </Navbar>
                <Navbar bg="light" expand={false} className="d-md-none">
                    <Container fluid>
                        <Button variant="success" onClick={handleShow}>
                            ☰
                        </Button>
                    </Container>
                </Navbar>
            </div>

            <Sidebar show={show} handleClose={handleClose} />
            <section className={classes.skiped}>
                    <Link to='/sign_up'><a href=''><HiOutlineArrowNarrowLeft className={classes.arrowIconss} />Back</a></Link>
                </section>

            <div className={classes.contentSpace1011}>
                
                <div className={classes.heading}>
                    <h3 >Organization Details</h3>
                    <p>Please enter your organization details</p>
                </div>

                <div className={classes.formGroupCnt}>
                    <div className={classes.rowLabelColumn}>
                        <label>
                            Organization name
                        </label>
                        <input
                             type='text'
                             value={formData.name || ''}
                             onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            placeholder='Lancaster Trust Limited'
                        />
                    </div>
                    <div className={classes.rowLabelColumn}>
                        <label>
                            Organization size
                        </label>
                        <select  value={formData.organization_size || ''}
                                onChange={(e) => setFormData({ ...formData, organization_size: e.target.value })}>
                            <option value=''>Select Size</option>
                            <option value='0-20'>Less than 20 members</option>
                            <option value='21-50'>21 to 50 members</option>
                            <option value='51-80'>51 to 80 members</option>
                            <option value='81-150'>81 to 150 members</option>
                            <option value='151+'>Over 151 members</option>
                            
                        </select>
                    </div>
                    <div className={classes.rowLabelColumn}>
                            <label>Date of Establishment</label>
                            <input
                                type='date'
                                value={formData.date_of_establishment || ''}
                                onChange={(e) => setFormData({ ...formData, date_of_establishment: e.target.value })}
                            />
                        </div>
                </div>

                <div className={classes.newDivs20}>
                    <div className={`btn btn-success ${classes.btn}`} style={{ textAlign: 'center' }} onClick={handleNext}>
                        Next
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Org_Details;
