import React,{useState,useEffect} from "react";
import classes from "./LeaveManagementApproved.module.css";
import image1 from "../../Assets/Ellipse 1 (1).png";
import image2 from "../../Assets/Ellipse 1.png";
import approve from "../../Assets/approve.svg";
import decline from "../../Assets/decline.svg";
import view from "../../Assets/view.svg";
import less from "../../Assets/less.svg";
import next from "../../Assets/next.svg";
import plus from "../../Assets/plus.svg";
// import TopNavBar from "../../Components/ErrorModal/TopNavBar/TopNavBar";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import { Link } from "react-router-dom";
import LeaveManagementModal from "../LeaveManagementModal/LeaveManagementModal";
import CreatePolicy from "../CreatePolicy/CreatePolicy";
import AddLeave from "../AddLeave/AddLeave";
import axios from 'axios';
import { BASE_URL } from '../api/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useNavigate } from "react-router-dom";

const LeaveManagementApproved = () => {
  const navigate = useNavigate(); 
  const [showRequest,setShowRequest] = useState(false)
  const [showPolicy,setShowPolicy] = useState(false)
  const [showLeave,setShowLeave] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [approved,setApproved] = useState([])
  const [bearer, setBearer] = useState('');
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  
  const showRequestHandler = () => {
    setShowRequest(!showRequest)
  }

  const showPolicyHandler = () => {
    setShowPolicy(true)
  }

  const showLeaveHandler  = () => {
    setShowLeave(true)
  }

  
  const readData = async () => {
    try {
        const value = await AsyncStorage.getItem('tokens');
        // console.log(value)
        if (value !== null) {
            setBearer(value);
        }
    } catch (e) {
        alert('Failed to fetch the token from storage');
    }
};

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const fetchBeneficiaries = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/leave/fetch-approved-leaves`, { headers });
      const results = response.data?.data?.leave;
      console.log(results)
      // console.log(response.data?.data)
      setApproved(results);
    
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setApproved([]);
    }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    function closeAddLeave (event) {
    if(event.code === 'Escape') {
      setShowLeave(false)
    }
    }
    window.addEventListener('keydown',closeAddLeave)

    return () => {
      window.removeEventListener('keydown',closeAddLeave)
    }
  })

  useEffect(() => {
    function closeAddLeave (event) {
    if(event.code === 'Escape') {
      setShowRequest(false)
    }
    }
    window.addEventListener('keydown',closeAddLeave)

    return () => {
      window.removeEventListener('keydown',closeAddLeave)
    }
  })
  
useEffect(() => {

  readData();
  }, []);


useEffect(() => {

  if (bearer) {
    fetchBeneficiaries();

  }
}, [bearer,showLeave]);

const employeeHandler = (items) => {
  setSelectedEmployeeId(items)
  setShowRequest(true)
}
  
  return (
    <>
     {showPolicy &&  <CreatePolicy onShow={setShowPolicy}/>}
     {showLeave &&  <AddLeave onShow={setShowLeave}/>}
     {showRequest &&  <LeaveManagementModal onShow={setShowRequest} employeeId={selectedEmployeeId}/>}
    <TopNavBar />
    <div className={classes.toTUpper}>
    <h4 className={classes.topperText}>Leave Management</h4>
    <div className={classes.firstBtn}>
    <button className={classes.policyBtn} onClick={showPolicyHandler}>Create Leave Policy</button>
    {/* <button className={classes.addBtn} onClick={showLeaveHandler}><img src={plus}  alt="plus" />Add Leave</button> */}
    </div>
      <div className={classes.topt}>
       
      <Link to={'/leave_management'}> <p>Leave Request</p></Link>
       <Link to={'/leave_approved'}><p>Approved</p></Link>
       <Link to={'/leave_decline'}><p>Declined</p></Link>
       <Link to={'/leave_policy'}><p>Leave Policy</p></Link>
      </div>
      <div className={classes.topLeave}>
        <div>
          <div className={classes.topTextDiv}>
            <h4 className={classes.topText}>Leave Request</h4>
          </div>

          <div style={{height:'1px',width:'107%',border:'1px solid #E9ECEF',marginLeft:'-39px'}}></div>

          <table className={classes.tableDiv}>
            <tr className={classes.tableHeadDiv}>
              <th className={classes.tableHeadRow}>S/N</th>
              <th className={classes.tableHeadRow}>Employee Name</th>
              <th className={classes.tableHeadRow}>Designation</th>
              <th className={classes.tableHeadRow}>Start date</th>
              <th className={classes.tableHeadRow}>End date</th>
              <th className={classes.tableHeadRow}>Status</th>
              <th className={classes.tableHeadRow}>View</th>
            </tr>
            {
              isLoading && <p> Loading ...</p>
            }
             {
              !isLoading && approved?.length === 0 && <p>No Data Available</p>
            }
            {
              approved?.map((items,index) => (
                <tr className={classes.tableRowBody} key={items.id}>

                <td>{index + 1}</td>
                <td className={classes.pflDiv}>
                  <img src={image1} alt="one" />
                  {`${items?.employee?.first_name} ${items?.employee?.last_name}`}
                </td>
                <td>{items?.employee?.position} </td>
                <td>{items?.start_date}</td>
                <td>{items?.end_date}</td>
                <td>
                <p className={classes.approvedDiv}>Appproved</p>
                </td>
                <td>
               <button onClick={() => employeeHandler(items.id)} style={{background:'transparent',border:'none'}}> <img src={view} alt="approve" style={{height:'25px',width:'25px'}}/></button> 
                </td>
              </tr>
              ))
            }
           
          </table>
          <div className={classes.gotoNext}>
            <button className={classes.prev}>
              <img src={less} alt="less" />
              Previous
            </button>
            <p className={classes.pagination}>Page 1 of 5</p>
            <button className={classes.next}>
              Next
              <img src={next} alt="next" />
            </button>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default LeaveManagementApproved;
