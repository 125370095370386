import React, { useState, useEffect } from 'react'
import classes from './EditShift.module.css';
import closeX from './CloseX.svg';
import addNew from '../../Assets/addNew.svg';
import attach from '../../Assets/attachfile.svg'
import axios from 'axios';
import Select from 'react-select';  

const EditShift = ({ isOpen, onClose }) => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (isOpen) {
            // Delay to give a smooth entrance when the modal opens
            const timer = setTimeout(() => {
                setAnimate(true);
            }, 500); // Adjust delay as needed

            return () => clearTimeout(timer);
        } else {
            setAnimate(false); // Reset animation when the modal is closed
        }
    }, [isOpen]);

    if (!isOpen) return null;
    const customStyles = {
        control: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',    
          height: '40px',          
          width: '100%',
          border: '1px solid #6C757D',
          borderRadius: '4px',
          padding: '0',
          boxSizing: 'border-box', 
          fontSize: '14px',
          cursor: 'pointer',
        }),
        input: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          alignSelf: 'center',     
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: '0 15px',      
          height: '100%',     
        }),
        singleValue: (provided) => ({
          ...provided,
          margin: '0',            
          padding: '0',         
        }),
        placeholder: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          lineHeight: 'normal',
        }),
      };
      
  return (
    <div>
       <div className={classes.modalOverlayWhole}>
            <div className={classes.modalErrYes}>

            
            <button className={classes.closeButtonModally} onClick={onClose}>
                    <img src={closeX} alt='' />
                </button>
            
                <form  className={classes.modalContainer22}>     
                <h6>Edit Employee Shift</h6>               
                    <div>
                    <div className={classes.labelandInput}>
                            <label>Enter Name of Employee</label>
                            <input type='text' placeholder='Enter Employee Name...'/> 
                        </div>
                        <div className={classes.labelandInput}>
                            <label>Select Department</label>
                            <Select
                                name='department'
                                required
                                styles={customStyles}
                                placeholder='Select Department...'
                            />
                            </div>
                        <div className={classes.labelandInput}>
                            <label>Select Week</label>
                            <Select
                                name='department'
                                required
                                styles={customStyles}
                                placeholder='Select Week...'
                            />
                            </div>
                        <div className={classes.labelandInput}>
                            <label>Select Sunday Shift</label>
                            <Select
                                name='department'
                                required
                                styles={customStyles}
                                placeholder='Select Sunday Shift...'
                            />
                            </div>
                        <div className={classes.labelandInput}>
                            <label>Select Monday Shift</label>
                            <Select
                                name='department'
                                required
                                styles={customStyles}
                                placeholder='Select Monday Shift...'
                            />
                            </div>
                        <div className={classes.labelandInput}>
                            <label>Select Tuesday Shift</label>
                            <Select
                                name='department'
                                required
                                styles={customStyles}
                                placeholder='Select Tuesday Shift...'
                            />
                            </div>
                        <div className={classes.labelandInput}>
                            <label>Select Wednesday Shift</label>
                            <Select
                                name='department'
                                required
                                styles={customStyles}
                                placeholder='Select Wednesday Shift...'
                            />
                            </div>
                        <div className={classes.labelandInput}>
                            <label>Select Thursday Shift</label>
                            <Select
                                name='department'
                                required
                                styles={customStyles}
                                placeholder='Select Thursday Shift...'
                            />
                            </div>
                        <div className={classes.labelandInput}>
                            <label>Select Friday Shift</label>
                            <Select
                                name='department'
                                required
                                styles={customStyles}
                                placeholder='Select Friday Shift...'
                            />
                            </div>
                        <div className={classes.labelandInput}>
                            <label>Select Saturday Shift</label>
                            <Select
                                name='department'
                                required
                                styles={customStyles}
                                placeholder='Select Saturday Shift...'
                            />
                            </div>
                           
                        </div>
                        <button type='submit' className={classes.submitbtnDesgn}>Save Changes</button>
                        </form>

                       
                        </div>
                        </div>
    </div>
  )
}

export default EditShift
