import React, { useContext, useState, useEffect } from "react";
import classes from "./TopNavBar.module.css";
// import home from '../../Assets/Home.svg'
import home from "../../Assets/Home.svg";
import notification from "../../Assets/notification.svg";
import { Link } from "react-router-dom";
// import { Dropdown, DropdownButton } from "react-bootstrap";
import { AuthContext } from "../../Pages/utils/AuthContext";
import Dropdown from '../Select/Select'
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

const TopNavBar = () => {
  const [dropdown, setDropdown] = useState(false);
  const { logOut } = useContext(AuthContext);

 
  const dropdownOption = {
    label: 'Logout',
    onClick: logOut
  };

  const [companyProfile, setCompanyProfile] = useState(null);
  const [bearer, setBearer] = useState('');
  const [error, setError] = useState(false);

  // Fetch token from AsyncStorage
  const readData = async () => {
      try {
          const value = await AsyncStorage.getItem('tokens');
          if (value !== null) {
              setBearer(value);
          }
      } catch (e) {
          alert('Failed to fetch the input from storage');
      }
  };

  // Fetch employee details from API
  const fetchEmployeeProfile = async () => {
      if (!bearer ) return;

      try {
          const response = await axios.get(`https://hr-api.emas.ng/api/company/fetch_by_id`, {
              headers: {
                  'Content-type': 'application/json',
                  'Authorization': `Bearer ${bearer}`,
              }
          });

          setCompanyProfile(response.data.data); // Store employee data
          console.log(response.data)
      } catch (error) {
          console.log('Error fetching Employee Profile', error);
          setError(true);
      }
  };

  useEffect(() => {
      readData();
  }, []);

  useEffect(() => {
      if (bearer) {
          fetchEmployeeProfile();
      }
  }, [bearer]);


  return (
    <div>
      <div className={classes.topTopNav}>
        <div className={classes.top2}>
          <div className={classes.homeDiv}>
            <Link to="/dashboard">
              {" "}
              <img src={home} alt="home" />
              <p className={classes.home}>Home</p>
            </Link>
          </div>
          <div className={classes.secondSTyle}>
            <div className={classes.styledDiv}>
              <p className={classes.company}>COMPANY</p>
              <p className={classes.companyName}>{companyProfile?.company.name}</p>
            </div>
            <div style={{position:"relative"}}>
              <img
                src={notification}
                alt="notification"
                onClick={() => setDropdown(false)}
              />
                <div className="App">
      <Dropdown option={dropdownOption} />
    </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNavBar;
