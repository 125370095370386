import React, { useState, useEffect } from 'react';
import classes from "./PayrollModal.module.css";
import cancel from "../../Assets/cancel.svg";
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const PayrollModal = () => {
  const { employeeId } = useParams(); // Get employee ID from URL params
  const [employeeProfile, setEmployeeProfile] = useState(null);
  const [bearer, setBearer] = useState('');
  const [error, setError] = useState(false);
  const navigate = useNavigate(); // Hook to navigate programmatically

  // Read token from AsyncStorage
  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('tokens');
      if (value !== null) {
        setBearer(value);
      }
    } catch (e) {
      alert('Failed to fetch the token from storage');
    }
  };

  // Fetch employee profile from API
  const fetchEmployeeProfile = async () => {
    if (!bearer || !employeeId) return;

    try {
      const response = await axios.get(`https://hr-api.emas.ng/api/payroll/fetchByID`, {
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${bearer}`,
        },
        params: { id: employeeId },
      });

      console.log('API response:', response.data); // Debugging the response
      setEmployeeProfile(response.data.data[0]); // Assuming data is an array
    } catch (error) {
      console.log('Error fetching Employee Profile', error);
      setError(true);
    }
  };

  // Read bearer token from storage on component mount
  useEffect(() => {
    readData();
  }, []);

  // Fetch employee profile when bearer and employeeId are available
  useEffect(() => {
    console.log('Bearer token:', bearer);
    console.log('Employee ID:', employeeId);

    if (bearer && employeeId) {
      fetchEmployeeProfile();
    }
  }, [bearer, employeeId]);

  // Function to handle cancel action (e.g., closing the modal)
  const handleCancel = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <>
      <div className={classes.modalBackdrop}>
        <div className={classes.modalRequest}>
          <div className={classes.divModalTop}>
            <h4 className={classes.requet}>
              {employeeProfile && employeeProfile.employee
                ? `${employeeProfile.employee.first_name} ${employeeProfile.employee.last_name}`
                : 'Loading...'}
            </h4>
            <img
              src={cancel}
              alt="cancel"
              onClick={handleCancel}
              style={{ cursor: 'pointer' }}
            />
          </div>

          <div className={classes.emyInDiv}>
            <h4 className={classes.empyInfo}>Employee Information</h4>
            <div className={classes.divDesc}>
              <div>
                <p className={classes.name}>Name</p>
                <h4 className={classes.nameVal}>
                  {employeeProfile && employeeProfile.employee
                    ? `${employeeProfile.employee.first_name} ${employeeProfile.employee.last_name}`
                    : 'Loading...'}
                </h4>
              </div>
              <div>
                <p className={classes.name}>Department</p>
                <h4 className={classes.nameVal}>
                  {employeeProfile && employeeProfile.department
                    ? employeeProfile.department.name
                    : 'Loading...'}
                </h4>
              </div>
            </div>

            <div className={classes.divDesc}>
              <div>
                <p className={classes.name}>Designation</p>
                <h4 className={classes.nameVal}>
                  {employeeProfile && employeeProfile.designation
                    ? employeeProfile.designation.title
                    : 'Loading...'}
                </h4>
              </div>
            </div>
          </div>

          <div className={classes.emyInDiv}>
            <h4 className={classes.empyInfo}>Payslip date range</h4>
            <div className={classes.divDesc}>
              <div>
                <p className={classes.name}>Start date</p>
                <h4 className={classes.nameVal}>
                  {employeeProfile ? employeeProfile.start_date : 'Loading...'}
                </h4>
              </div>
              <div>
                <p className={classes.name}>End date</p>
                <h4 className={classes.nameVal}>
                  {employeeProfile ? employeeProfile.end_date : 'Loading...'}
                </h4>
              </div>
            </div>
          </div>

          <div className={classes.emyInDiv}>
            <h4 className={classes.empyInfo}>Earnings</h4>
            <div className={classes.divDesc}>
              <div>
                <p className={classes.name}>Basic Salary</p>
                <h4 className={classes.nameVal}>
                  {employeeProfile ? employeeProfile.salary : 'Loading...'}
                </h4>
              </div>
              <div>
                <p className={classes.name}>House Allowance</p>
                <h4 className={classes.nameVal}>
                  {employeeProfile ? employeeProfile.house_allowance : 'Loading...'}
                </h4>
              </div>
            </div>

            <div className={classes.divDesc}>
              <div>
                <p className={classes.name}>Transport Allowance</p>
                <h4 className={classes.nameVal}>
                  {employeeProfile ? employeeProfile.transport_allowance : 'Loading...'}
                </h4>
              </div>
              <div>
                <p className={classes.name}>Other Allowance</p>
                <h4 className={classes.nameVal}>
                  {employeeProfile ? employeeProfile.bonus : 'Loading...'}
                </h4>
              </div>
            </div>
          </div>

          <div className={classes.emyInDiv}>
            <h4 className={classes.empyInfo}>Deductions</h4>
            <div className={classes.divDesc}>
              <div>
                <p className={classes.name}>PAYE Tax</p>
                <h4 className={classes.nameVal}>
                  {employeeProfile ? employeeProfile.tax : 'Loading...'}
                </h4>
              </div>
              <div>
                <p className={classes.name}>Pension</p>
                <h4 className={classes.nameVal}>
                  {employeeProfile ? employeeProfile.pension : 'Loading...'}
                </h4>
              </div>
            </div>

            <div className={classes.divDesc}>
              <div>
                <p className={classes.name}>Loan</p>
                <h4 className={classes.nameVal}>
                  {employeeProfile ? employeeProfile.loan : 'Loading...'}
                </h4>
              </div>
              <div>
                <p className={classes.name}>Pay Date</p>
                <h4 className={classes.nameVal}>
                  {employeeProfile ? employeeProfile.pay_date : 'Loading...'}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Error handling */}
      {error && <p>Error fetching employee data. Please try again.</p>}
    </>
  );
};

export default PayrollModal;
