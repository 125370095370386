import React,{useEffect, useState} from "react";
import classes from "./AddLeave.module.css";
import image1 from "../../Assets/Ellipse 1 (1).png";
import image2 from "../../Assets/Ellipse 1.png";
import approve from "../../Assets/approve.svg";
import decline from "../../Assets/decline.svg";
import view from "../../Assets/view.svg";
import less from "../../Assets/less.svg";
import next from "../../Assets/next.svg";
import plus from "../../Assets/plus.svg";
// import TopNavBar from "../../Components/ErrorModal/TopNavBar/TopNavBar";
import { Link, useNavigate } from "react-router-dom";
import cancel from "../../Assets/cancel.svg";
import search from '../../Assets/search.svg'
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { BASE_URL } from '../api/api';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const AddLeave = ({onShow}) => {
  const navigate = useNavigate(); 
  const [bearer, setBearer] = useState('');
  const [name,setName] = useState('')
  const [department,setDepartment] = useState('')
  const [startDate,setStartDate] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [endDate,setEndDate] = useState('')
  const [policy,setPolicy] = useState('')
  const [message,setMessage] = useState('')
  const [userData, setUserData] = useState([]);

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('tokens');
      if (value !== null) {
        setBearer(value);
      }
    } catch (e) {
      alert('Failed to fetch the token from storage');
    }
  };

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const createLeaveHandler = async () => {
    setIsLoading(true)
    const data = {
    "employee_id": name,
    "type": "Company",
    "description": message,
    "start_date": startDate,
    "end_date": endDate,
    };

    try {
      const response = await axios.post(`${BASE_URL}/leave/create`, data, { headers });
      // console.log(response?.messages);
      toast.success('Successfully created leave')
    } catch (error) {
      const errors = error?.response?.data?.errors;
      if (errors) {
        Object.entries(errors).forEach(([field, messages]) => {
          // Assuming messages is an array, you can display each message
          messages.forEach(message => {
            toast.error(message);
          });
        });
      }
      else{
        toast.error('Failed to create leave')
      }
      
    } finally {
      setIsLoading(false);
      setName('')
      setStartDate('')
      setEndDate('')
      setMessage('')
      onShow(false);
    }

  }

  const getLeaveHandler = async () => {

    try {
      const response = await axios.get(`${BASE_URL}/leave/fetchAll`, { headers });
      const results = response.data?.data;
      setUserData(results?.employees)
  
    } catch (error) {
      
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setUserData([]);
    }
  } finally {
    setIsLoading(false);
   
  }
    
  }

  

  useEffect(() => {

    readData();
    }, []);
  
  
  useEffect(() => {
  
    if (bearer) {
      getLeaveHandler();
  
    }
  }, [bearer]);
  
  
  

  return (
    <>
      <div className={classes.modalBackdrop}>
        <div className={classes.modalRequest}>
          <div className={classes.divModalTop}>
            <h4 className={classes.requet}>Add Leave</h4>
            <img src={cancel} alt="cancel" onClick={() => onShow(false)}/>
          </div>
          <div className={classes.divInfo}>
            <div className={classes.teamDiv}>
              <select htmlFor="name" className={classes.stDivT} onChange={(e) => setName(e.target.value)}>
                <option required>Enter name of Team member</option>
              {
                
                userData.map((items) => (
                  <option id={items.id} value={items.id}>{`${items.first_name} ${items.last_name}`}</option>
                ))
              }
              </select>
            </div>
            {/* <div className={classes.teamDiv}>
              <label htmlFor="department" className={classes.textText}>Select Department</label>
              <select for='department' id='department' className={classes.stDivT} onChange={(e) => setDepartment(e.target.value)}>
                <option value='digital'>Digital Media</option>
                </select>
            </div> */}
            <div className={classes.teamDiv}>
              <label htmlFor="date" className={classes.textText}>Start date</label>
              <input type ='date' id='date' className={classes.stDivT} onChange={(e) => setStartDate(e.target.value)}/>
            </div>
            <div className={classes.teamDiv}>
              <label htmlFor="date" className={classes.textText}>End date</label>
              <input type ='date' id='date' className={classes.stDivT}  onChange={(e) => setEndDate(e.target.value)}/>
            </div>
            {/* <div className={classes.teamDiv}>
              <label htmlFor="policy" className={classes.textText}>No of days being applied for</label>
              <input type ='text' id='policy' className={classes.stDivT} value='3' onChange={(e) => setPolicy(e.target.value)}/>
              <p className={classes.policyText}>*You have 12 days of Leave Policy left this year</p>
            </div> */}
            <div className={classes.teamDiv}>
              <label htmlFor="policy" className={classes.textText}>Reason for Leave</label>
              <input type ='text' id='policy' className={classes.stDivT} placeholder='sick leave' onChange={(e) => setMessage(e.target.value)}/>
            </div>
            </div>
          <div className={classes.btnBtn}>
            <button className={classes.appe} onClick={createLeaveHandler}>{isLoading ? 'Loading...':'Create Leave Policy'}</button>
          </div>
        </div>
      </div>
      {/* <TopNavBar /> */}
      <div className={classes.toTUpper}>
        <h4 className={classes.topperText}>Leave Management</h4>
        <div className={classes.firstBtn}>
          <button className={classes.policyBtn}>Create Leave Policy</button>
          <button className={classes.addBtn}>
            <img src={plus} alt="plus" />
            Add Leave
          </button>
        </div>
        <div className={classes.topt}>
          <Link to={"/leave_management"}>
            {" "}
            <p>Leave Request</p>
          </Link>
          <Link to={"/leave_approved"}>
            <p>Approved</p>
          </Link>
          <Link to={"/leave_decline"}>
            <p>Declined</p>
          </Link>
          <Link to={"/leave_policy"}>
            <p>Leave Policy</p>
          </Link>
        </div>
        <div className={classes.topLeave}>
          <div>
            <div className={classes.topTextDiv}>
              <h4 className={classes.topText}>Leave Request</h4>
            </div>
            <div className={classes.divSelect}>
            <div style={{position:'relative'}}>
            <input type="text" placeholder="Search employee name" className={classes.sBAr}/>
            <img src={search} alt='search' className={classes.imgSearch}/>
            </div>
            
            <select for='department' required className={classes.selectTag}>
              <option value=''>Select department</option>
              <option value='tech'>Technology</option>
            </select>
            <button className={classes.searchBtn}>Search</button>
          </div>

            <table className={classes.tableDiv}>
              <tr className={classes.tableHeadDiv}>
                <th className={classes.tableHeadRow}>S/N</th>
                <th className={classes.tableHeadRow}>Employee Name</th>
                <th className={classes.tableHeadRow}>Designation</th>
                <th className={classes.tableHeadRow}>Department</th>
                <th className={classes.tableHeadRow}>Date of Joining</th>
                <th className={classes.tableHeadRow}>Leave Policy</th>
                <th className={classes.tableHeadRow}>View</th>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>1</td>
                <td className={classes.pflDiv}>
                  <img src={image1} alt="one" />
                  Matthew Olowade
                </td>
                <td>Media Project Manager </td>
                <td>Digital Media </td>
                <td>25/08/2024</td>
                <td>19 days</td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>2</td>
                <td className={classes.pflDiv}>
                  <img src={image2} alt="two" />
                  Lauren Annabelle
                </td>
                <td>Art Director </td>
                <td>Print Media</td>
                <td>28/08/2024</td>
                <td>13 days</td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>1</td>
                <td className={classes.pflDiv}>
                  <img src={image1} alt="one" />
                  Matthew Olowade
                </td>
                <td>Media Project Manager </td>
                <td>Digital Media </td>
                <td>25/08/2024</td>
                <td>19 days</td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>2</td>
                <td className={classes.pflDiv}>
                  <img src={image2} alt="two" />
                  Lauren Annabelle
                </td>
                <td>Art Director </td>
                <td>Print Media</td>
                <td>28/08/2024</td>
                <td className={classes.btnSet}>13 days</td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
            </table>
            <div className={classes.gotoNext}>
              <button className={classes.prev}>
                <img src={less} alt="less" />
                Previous
              </button>
              <p className={classes.pagination}>Page 1 of 5</p>
              <button className={classes.next}>
                Next
                <img src={next} alt="next" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLeave;
