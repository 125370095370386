import React, { useState, useContext, useEffect } from 'react';
import classes from '../Personal Details/personalDetails.module.css';
import Sidebar from '../../Components/SideBar/SideBar';
import { BiRightArrowAlt } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { Navbar, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { FormContext } from '../../Components/FormContext';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';


const Personal = () => {
    const { formData, setFormData } = useContext(FormContext);
    const navigate = useNavigate();
    const [bearer, setBearer] = useState('');
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        setLoading(true);
        const dataToSubmit = { ...formData };
        if(!bearer) return;
        try {
            const response = await axios.post('https://hr-api.emas.ng/api/company/update', dataToSubmit, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearer}`,
                },
            });
            console.log('Response:', response.data);
            if (response.status === 200) {
                navigate('/welcomes');
            }
        } catch (error) {
            console.error('Error posting data:', error);
        }finally{
            setLoading(false);
        }
    };

    const readData = async () => {
        try{
            const value = await AsyncStorage.getItem('tokens');

            if (value !== null){
                setBearer(value);
            }
        } catch(e) {
            alert('Failed to fetch the input from storage')
        }
    }; 
    
    useEffect(() => {
        readData();
    }, []);


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className={classes.containers}>
            <div className={classes.sidenav}>
                <Navbar expand="lg" className={`d-none d-md-block ${classes.navbar}`}>
                    <Container fluid>
                    </Container>
                </Navbar>
                <Navbar bg="light" expand={false} className="d-md-none">
                    <Container fluid>
                        <Button variant="success" onClick={handleShow}>
                            ☰
                        </Button>
                    </Container>
                </Navbar>
            </div>

            <Sidebar show={show} handleClose={handleClose} />
            <section className={classes.skiped}>
                <Link to='/'><HiOutlineArrowNarrowLeft className={classes.arrowIconss} /><a href=''>Back</a></Link>
            </section>

            <div className={classes.contentSpace11011}>

                <div className={classes.heading}>
                    <h3 >Personal Details</h3>
                    <p>Please enter your personal details</p>
                </div>

                <div className={classes.formGroupCntTnt}>
                    <div className={classes.rowDivFunction}>
                        <div className={classes.rowLabelColumn}>
                            <label>
                                First Name
                            </label>
                            <input
                                type='text'
                                value={formData.first_name || ''}
                                onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
                                placeholder='Enter your first name'
                            />
                        </div>

                        <div className={classes.rowLabelColumn}>
                            <label>
                                Last Name
                            </label>
                            <input
                                type='text'
                                value={formData.last_name || ''}
                                onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
                                placeholder='Enter your last name'
                            />
                        </div>
                    </div>
                    <div className={classes.rowDivFunction}>
                        <div className={classes.rowLabelColumn}>
                            <label>
                                Date of birth
                            </label>
                            <input
                               type='date'
                            // placeholder='Lagos, Island'
                            />
                        </div>
                    
                    <div className={classes.rowLabelColumn}>
                        <label>
                            Phone number
                        </label>
                        <input
                           type='text'
                           value={formData.phone_number || ''}
                                onChange={(e) => setFormData({ ...formData, phone_number: e.target.value })}
                            placeholder='Enter your phone number'
                        />
                    </div>
                </div>
                </div>

                <div className={classes.newDivs2021}>
                    <div className={`btn btn-success ${classes.btn}`}  onClick={handleSubmit}  style={{ backgroundColor: loading ? '#c4c4c4' : '#2D995F', textAlign: 'center' }}
                            disabled={loading}>
                       {loading ? 'please wait...' : ' Next'}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Personal;
