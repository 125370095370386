import React, { useState, useEffect } from 'react';
import classes from '../SendPayslipModal/SendPayslipModal.module.css';
import { IoWarningOutline } from "react-icons/io5";
import closeX from './CloseX.svg'
import SentOutPayslipModal from '../SentOutPayslipModal/SentOutPaySlipModal';


const SendPayslipModal = ({ isOpen, onClose }) => {
    const [animate, setAnimate] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {setIsModalOpen(true);

    }
    const closeModal = () => setIsModalOpen(false);

    useEffect(() => {
        if (isOpen) {
            // Delay to give a smooth entrance when the modal opens
            const timer = setTimeout(() => {
                setAnimate(true);
            }, 500); // Adjust delay as needed

            return () => clearTimeout(timer);
        } else {
            setAnimate(false); // Reset animation when the modal is closed
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className={classes.modalOverlayWhole}>
            <SentOutPayslipModal isOpen={isModalOpen} onClose={closeModal} />
            <div className={classes.modalErrYester}>
                <button className={classes.closeButtonModally} onClick={onClose}>
                    <img src={closeX} alt='' />
                </button>
                <div className={classes.modalContainerSend}>

                    <h6>
                        Send out PaySlip
                    </h6>
                    <p>You are about to send out the payslips for all the employees in your organization, this
                    action cannot be reversed, are you sure you want to continue?</p>
                    <button onClick={openModal} type='submit' className={classes.addTeamBtn}>
                       Send out Payslip
                    </button>
                </div>

            </div>
        </div>
    );
};

export default SendPayslipModal;
