import React, { useState,useContext,useEffect } from "react";
import classes from "./LeaveRequest.module.css";
import image1 from "../../Assets/Ellipse 1 (1).png";
import image2 from "../../Assets/Ellipse 1.png";
import approve from "../../Assets/approve.svg";
import decline from "../../Assets/decline.svg";
import view from "../../Assets/view.svg";
import less from "../../Assets/less.svg";
import next from "../../Assets/next.svg";
import plus from "../../Assets/plus.svg";
// import TopNavBar from "../../ComponentsS/TopNavBar/TopNavBar";
import { Link, useNavigate } from "react-router-dom";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import cancel from "../../Assets/cancel.svg";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL } from '../api/api';
import axios from 'axios';


const LeaveRequest = ({onShow,employeeId}) => {
  const navigate = useNavigate(); 
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [approved,setApproved] = useState('')

  const hideModal = () => {
    onShow(false)
  }

  const readData = async () => {
    setIsLoading(true)
    try {
      const value = await AsyncStorage.getItem('tokens');
      if (value !== null) {
        setBearer(value);
      }
    } catch (e) {
      alert('Failed to fetch the token from storage');
    }
  };

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const getLeaveHandler = async () => {

    try {
      const response = await axios.get(`${BASE_URL}/leaveRequest/fetchByID?id=${employeeId}`, { headers });
      const results = response.data?.data
      setUserData(results)
      console.log(results)
  
    } catch (error) {
      
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setUserData([]);
    }
  } finally {
    setIsLoading(false);
   
  }
    
  }

  const getDisApprovedHandler = async () => {
    setIsLoading(true)
    const data = {
      "id": employeeId,
      };
  
    try {
      const response = await axios.put(`${BASE_URL}/leave/disapprove`,data, { headers });
      const results = response.data?.data
      setApproved(results)
  
    } catch (error) {
      
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setApproved([]);
    }
  } finally {
    setIsLoading(false);
    onShow(false)
   
  }
    
  }


  const getApprovedHandler = async () => {
    setIsLoading(true)
    const data = {
      "id":employeeId,
      };
  
    try {
      const response = await axios.put(`${BASE_URL}/leave/approve`,data, { headers });
      const results = response.data?.data
      setApproved(results)
  
    } catch (error) {
      
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setApproved([]);
    }
  } finally {
    setIsLoading(false);
    onShow(false)
   
  }
    
  }

  

  useEffect(() => {

    readData();
    }, []);
  
  
  useEffect(() => {
  
    if (bearer) {
      getLeaveHandler();
  
    }
  }, [bearer]);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }
  
  return (
    <>
     <div className={classes.modalBackdrop}>
      {
      isLoading ? 'Loading Data ...':
     
        (<div className={classes.modalRequest}>
       
       <div className={classes.divModalTop}>
         <h4 className={classes.requet}>Leave Request</h4>
         <img src={cancel} alt="cancel" onClick={hideModal}/>
       </div>
       <div className={classes.divInfo}>
         <div>
           <img src={image2} alt="person" />
         </div>
         <div>
           <div className={classes.textDiv}>
             <h2 className={classes.names}>{`${userData?.employee?.first_name} ${userData?.employee?.last_name}`}</h2>
             <p className={classes.titleName}>{userData?.employee?.position} </p>
           </div>
           <div className={classes.rowT}>
             <div>
               <h6 className={classes.department}>Department</h6>
               <h4 className={classes.digital}>{userData?.employee?.department?.name}</h4>
             </div>
             <div >
               <h6 className={classes.department}>Start date</h6>
               <h4 className={classes.digital}>{userData?.leave?.start_date}</h4>
             </div>
             <div>
               <h6 className={classes.department}>End date</h6>
               <h4 className={classes.digital}>{userData?.leave?.end_date}</h4>
             </div>
             <div>
               <h6 className={classes.department}>Leave Policy left</h6>
               <h4 className={classes.digital}>8 days</h4>
             </div>
             
           </div>
           <div className={classes.third}>
               <h6 className={classes.reason}>Reason for Leave</h6>
               <h4 className={classes.ubText}>
               {userData?.leave?.description}
               </h4>
             </div>
         </div>
       </div>
       <div className={classes.secondDiv}>
         <div>
         <h4 className={classes.boldTop}>Leave history</h4>
         <p className={classes.request}>2 Request this year</p>
         </div>
         <div>
         <h4 className={classes.department}>Leave</h4>
         <p className={classes.digital}>Start date - 02/02/2024 to 05/02/2024  -   Approved</p>
         </div>
         <div>
         <h4 className={classes.department}>Leave</h4>
         <p className={classes.digital}>Start date - 16/05/2024 to 20/05/2024  -   Denied</p>
         </div>
       </div>
       <div className={classes.thirdDiv}>
         <div>
           <h4  className={classes.depertments}>Department Leave Request</h4>
           <p className={classes.requestOne}>1 Request </p>
         </div>
         <div>
           <h4 className={classes.department}>Rachael Awoyaya - Design Intern</h4>
           <p className={classes.digital}>Start date - {userData?.leave?.start_date} to {userData?.leave?.end_date}  -   {userData?.leave?.status === "0" ? 'Declined' : "Approved"}</p>
         </div>
       </div>
       <div className={classes.btnBtn}>
        <button className={classes.appe} onClick={getApprovedHandler}>{isLoading ? 'Loading...' : 'Approve'}</button>
         <button className={classes.dln} onClick={getDisApprovedHandler}>{isLoading ? 'Loading...' : 'Disapprove'}</button>
       </div>
       </div>)
        }
      </div>
      {/* <TopNavBar /> */}
      <div className={classes.toTUpper}>
        <h4 className={classes.topperText}>Leave Management</h4>
        <div className={classes.firstBtn}>
          <button className={classes.policyBtn}>Create Leave Policy</button>
          <button className={classes.addBtn}>
            <img src={plus} alt="plus" />
            Add Leave
          </button>
        </div>
        <div className={classes.topt}>
          <p>Leave Request</p>
          <p>Approved</p>
          <p>Declined</p>
          <p>Leave Policy</p>
        </div>
        <div className={classes.topLeave}>
          <div>
            <div className={classes.topTextDiv}>
              <h4 className={classes.topText}>Leave Request</h4>
            </div>

            <table className={classes.tableDiv}>
              <tr className={classes.tableHeadDiv}>
                <th className={classes.tableHeadRow}>S/N</th>
                <th className={classes.tableHeadRow}>Employee Name</th>
                <th className={classes.tableHeadRow}>Designation</th>
                <th className={classes.tableHeadRow}>Start date</th>
                <th className={classes.tableHeadRow}>End date</th>
                <th className={classes.tableHeadRow}>Approve/Decline</th>
                <th className={classes.tableHeadRow}>View</th>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>1</td>
                <td className={classes.pflDiv}>
                  <img src={image1} alt="one" />
                  Matthew Olowade
                </td>
                <td>Media Project Manager </td>
                <td>21/08/2024</td>
                <td>25/08/2024</td>
                <td>
                  <img src={approve} alt="approve" />
                  <img src={decline} alt="decline" />
                </td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>2</td>
                <td className={classes.pflDiv}>
                  <img src={image2} alt="two" />
                  Lauren Annabelle
                </td>
                <td>Art Director </td>
                <td>27/08/2024</td>
                <td>28/08/2024</td>
                <td>
                  <img src={approve} alt="approve" />
                  <img src={decline} alt="decline" />
                </td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>1</td>
                <td className={classes.pflDiv}>
                  <img src={image1} alt="one" />
                  Matthew Olowade
                </td>
                <td>Media Project Manager </td>
                <td>21/08/2024</td>
                <td>25/08/2024</td>
                <td>
                  <img src={approve} alt="approve" />
                  <img src={decline} alt="decline" />
                </td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>2</td>
                <td className={classes.pflDiv}>
                  <img src={image2} alt="two" />
                  Lauren Annabelle
                </td>
                <td>Art Director </td>
                <td>27/08/2024</td>
                <td>28/08/2024</td>
                <td className={classes.btnSet}>
                  <img src={approve} alt="approve" />
                  <img src={decline} alt="decline" />
                </td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
            </table>
            <div className={classes.gotoNext}>
              <button className={classes.prev}>
                <img src={less} alt="less" />
                Previous
              </button>
              <p className={classes.pagination}>Page 1 of 5</p>
              <button className={classes.next}>
                Next
                <img src={next} alt="next" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveRequest;
