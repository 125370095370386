import React, { useState, useEffect } from 'react';
import classes from "./LeavePolicy.module.css";
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import image1 from "../../Assets/Ellipse 1 (1).png";
import image2 from "../../Assets/Ellipse 1.png";
import view from "../../Assets/view.svg";
import less from "../../Assets/less.svg";
import next from "../../Assets/next.svg";
import search from '../../Assets/search.svg';
import LeavePolicyModal from "../LeavePolicyModal/LeavePolicyModal";
import CreatePolicy from "../CreatePolicy/CreatePolicy";
import AddLeave from "../AddLeave/AddLeave";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import { BASE_URL } from '../api/api';
import { Link, useNavigate } from "react-router-dom";

const LeavePolicy = () => {
  const navigate = useNavigate(); 
  const [showRequest,setShowRequest] = useState(false)
   const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [showPolicy,setShowPolicy] = useState(false)
  const [showLeave,setShowLeave] = useState(false)
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [approved,setApproved] = useState('')
 

  


  const readData = async () => {
    try {
        const value = await AsyncStorage.getItem('tokens');
        // console.log(value)
        if (value !== null) {
            setBearer(value);
        }
    } catch (e) {
        alert('Failed to fetch the token from storage');
    }
};



 const headers = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${bearer}`
};


//fetch records
const fetchBeneficiaries = async () => {
  setIsLoading(true);
  try {
    const response = await axios.get(`${BASE_URL}/leave/fetchAll`, { headers });
    const results = response.data?.data?.leave;
    setTableData(results);
    console.log(results)
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Redirect to login page if unauthorized
      navigate('/login');
    } else {
    const errorStatus = error.response?.data?.message;
    console.log(errorStatus);
    setTableData([]);
  }
  } finally {
    setIsLoading(false);
  }
};

useEffect(() => {

  readData();
  }, []);


  useEffect(() => {
    if (bearer) {
      fetchBeneficiaries();
  
    }
  },[bearer])

  const showPolicyHandler = () => {
    setShowPolicy(true)
  }

  return (
    <>
      {showPolicy && <CreatePolicy onShow={setShowPolicy} />}
      {showLeave && <AddLeave onShow={setShowLeave} />}
      {showRequest && <LeavePolicyModal onShow={setShowRequest} />}

      <TopNavBar />
      <div className={classes.toTUpper}>
        <h4 className={classes.topperText}>Leave Management</h4>
        <div className={classes.firstBtn}>
          <button className={classes.policyBtn}  onClick={showPolicyHandler}>Create Leave Policy</button>
        </div>
        <div className={classes.topt}>
          <Link to={'/leave_management'}><p>Leave Request</p></Link>
          <Link to={'/leave_approved'}><p>Approved</p></Link>
          <Link to={'/leave_decline'}><p>Declined</p></Link>
          <Link to={'/leave_policy'}><p>Leave Policy</p></Link>
        </div>
        <div className={classes.topLeave}>
          <div>
            <div className={classes.topTextDiv}>
              <h4 className={classes.topText}>Leave Policy</h4>
            </div>

            <div style={{ height: '1px', width: '107%', border: '1px solid #E9ECEF', marginLeft: '-39px', marginBottom: '36px' }}></div>
            {/* <div className={classes.divSelect}>
              <div style={{ position: 'relative' }}>
                <input 
                  type="text" 
                  placeholder="Search employee name" 
                  className={classes.sBAr} 
                  // onChange={(e) => handleSearch(e.target.value)} 
                />
                <img src={search} alt='search' className={classes.imgSearch} />
              </div>
              <select className={classes.selectTag}>
                <option value=''>Select department</option>
                <option value='tech'>Technology</option>
              </select>
              <button className={classes.searchBtn}>Search</button>
            </div> */}

            <table className={classes.tableDiv}>
              <thead>
                <tr className={classes.tableHeadDiv}>
                  <th className={classes.tableHeadRow}>S/N</th>
                  {/* <th className={classes.tableHeadRow}>Employee ID</th> */}
                  <th className={classes.tableHeadRow}>Leave Type</th>
                  <th className={classes.tableHeadRow}>Description</th>
                  <th className={classes.tableHeadRow}>Duration</th>
                  {/* <th className={classes.tableHeadRow}>End Date</th> */}
                  {/* <th className={classes.tableHeadRow}>View</th> */}
                </tr>
              </thead>
              <tbody>
              {
              !isLoading && tableData?.length === 0 && <p>No Data Available</p>
            }
                {
                  tableData.map((items,index) => (
                    <tr key={items?.id} className={classes.tableRowBody}>
                      <td style={{padding:10}}>{index + 1}</td>
                      {/* <td className={classes.pflDiv}>
                        <img src="" alt="" />
                        {3 || 'Employee ID'}
                      </td> */}
                      <td>{items?.type || 'Leave Type'}</td>
                      <td>{items?.description || 'Description'}</td>
                      <td>{items?.duration || 'Duration'}</td>
                      {/* <td>{'leave.end_date' || 'End Date'}</td> */}
                      {/* <td>
                        <img src={view} alt="view" style={{ height: '25px', width: '25px' }}  />
                      </td> */}
                    </tr>
                  ))
                }
                    
              </tbody>
            </table>

            <div className={classes.gotoNext}>
              <button className={classes.prev}>
                <img src={less} alt="less" />
                Previous
              </button>
              <p className={classes.pagination}>Page 1 of 2</p>
              <button className={classes.next} >
                Next
                <img src={next} alt="next" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeavePolicy;
