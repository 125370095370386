import React, { useState, useEffect } from 'react';
import classes from "./AttendanceReportModal.module.css";
import closeX from "./CloseX.svg";
import line from "../../Assets/line.svg"

const AttendanceReportModal =  ({ isOpen, onClose }) => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (isOpen) {
            // Delay to give a smooth entrance when the modal opens
            const timer = setTimeout(() => {
                setAnimate(true);
            }, 500); // Adjust delay as needed

            return () => clearTimeout(timer);
        } else {
            setAnimate(false); // Reset animation when the modal is closed
        }
    }, [isOpen]);

    if (!isOpen) return null;
  return (
    <div>
       <div className={classes.modalBackdrop}>
        <div className={classes.modalRequest}>
        <div className={classes.imgrightAlign}>
          <img
              src={closeX}
              alt="cancel"
            onClick={onClose}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className={classes.divModalTop}>
            <h4 className={classes.requet}>
             Attendance Report
            </h4>
          </div>

          <div className={classes.emyInDiv}>
            <h4 className={classes.empyInfo}>Employee Information</h4>
            <div className={classes.divDesc}>
              <div>
                <p className={classes.name}>Name</p>
                <h4 className={classes.nameVal}>
                Lauren Annabelle
                </h4>
              </div>
              <div>
                <p className={classes.name}>Department</p>
                <h4 className={classes.nameVal}>
                Print Media
                </h4>
              </div>
            </div>

            <div className={classes.divDesc}>
              <div>
                <p className={classes.name}>Designation</p>
                <h4 className={classes.nameVal}>
                  Art Director
                </h4>
              </div>
              <div>
                <p className={classes.name}>Email Address</p>
                <h4 className={classes.nameVal}>
                laurena@gmail.com
                </h4>
              </div>
            </div>
          </div>

          <div className={classes.emyInDiv}>
            <h4 className={classes.empyInfo}>Attendance</h4>
            <div className={classes.divDesc}>
              <div>
                <p className={classes.name}>Entry Time</p>
                <h4 className={classes.nameVal}>
                08:00am
                </h4>
              </div>
              <div>
                <p className={classes.name}>Exit Time</p>
                <h4 className={classes.nameVal}>
                05:25pm
                </h4>
              </div>
            </div>
          </div>

          <div className={classes.emyInDiv}>
            <h4 className={classes.empyInfo}>Punch In and Out Timeline</h4>
            {/* <div className={classes.divDescFlexClmn}> */}
            
            <div className={classes.divDesc}>
                <div className={classes.divDescFlexClmn}>
            <img src={line} className={classes.imgmoving} alt=''/>
            <div>
              <div className={classes.clmnfiy}>
                <p className={classes.name}>Punch In</p>
                <h4 className={classes.nameVal}>
                    08:00am
                </h4>
              </div>
              <div className={classes.clmnfiy}>
                <p className={classes.name}>Punch Out</p>
                <h4 className={classes.nameVal}>
                11:50am
                </h4>
              </div>
              <div className={classes.clmnfiy}>
                <p className={classes.name}>Punch In</p>
                <h4 className={classes.nameVal}>
                    12:35pm
                </h4>
              </div>
              <div className={classes.clmnfiy}>
                <p className={classes.name}>Punch In</p>
                <h4 className={classes.nameVal}>
                    05:25pm
                </h4>
              </div>
              </div>
              </div>
              <div>
                <p className={classes.name}>Date</p>
                <h4 className={classes.nameValSpec}>
                25/08/2024
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default AttendanceReportModal
