import React, { useEffect, useState } from 'react';
import classes from './Dashboard.module.css';
import avart from '../Dashboard/avat.png';
import first from '../Dashboard/first.png';
import second from '../Dashboard/second.png';
import third from '../Dashboard/third.png';
import fifth from '../Dashboard/fifth.png';
import firsts from '../Dashboard/first1.png';
import seconds from '../Dashboard/second2.png';
import thirds from '../Dashboard/third3.png';
import fourths from '../Dashboard/fourth4.png';
import fifths from '../Dashboard/fifth5.png';
import firstss from '../Dashboard/first11.png';
import secondss from '../Dashboard/second22.png';
import thirdss from '../Dashboard/third33.png';
import fourthss from '../Dashboard/fourth44.png'
import sixths from '../Dashboard/sixth56.png';
import seventh from '../Dashboard/seventh12.png'
import TopNavBar from '../../Components/TopNavBar/TopNavBar';
import vectorec from './vectorec.svg'
import { Link } from 'react-router-dom';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';


const Dashboard = () => {

    
    const [companyProfile, setCompanyProfile] = useState(null);
    const [bearer, setBearer] = useState('');
    const [error, setError] = useState(false);

    // Fetch token from AsyncStorage
    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('tokens');
            if (value !== null) {
                setBearer(value);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    // Fetch employee details from API
    const fetchEmployeeProfile = async () => {
        if (!bearer ) return;

        try {
            const response = await axios.get(`https://hr-api.emas.ng/api/company/fetch_by_id`, {
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${bearer}`,
                }
            });

            setCompanyProfile(response.data.data); // Store employee data
            console.log(response.data)
        } catch (error) {
            console.log('Error fetching Employee Profile', error);
            setError(true);
        }
    };

    useEffect(() => {
        readData();
    }, []);

    useEffect(() => {
        if (bearer) {
            fetchEmployeeProfile();
        }
    }, [bearer]);


    return (
        <div>
            <TopNavBar />
            <div className={classes.top}>
                <img src={avart} alt="Avatar" />
                <div className={classes.text}>
                    <h6>Welcome</h6>
                    <h3>{companyProfile?.first_name} {companyProfile?.last_name}</h3>
                    <p>Simplified HR solutions for you and your organization</p>
                </div>
            </div>
            <div className={classes.general}>
            <div className={classes.tabs}>
            <Link to='/employee'>
                <div className={classes.first}>
                    <img src={fifth} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Employees</p>
                        <p>Add and manage your company employees to your board</p>
                    </div>
                </div>
                </Link>
                <Link to='/designation'>
                <div className={classes.second}>
                    <img src={first} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Employee Designations</p>
                        <p>Add your company employees to your board</p>
                    </div>
                </div>
                </Link>
                <Link to='/appraisal'>
                <div className={classes.third}>
                    <img src={second} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Appraisals</p>
                        <p>Conduct appraisals for your employees</p>
                    </div>
                </div>
                </Link>
                <Link to='/attendance_report'>
                <div className={classes.fourth}>
                    <img src={third} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Attendance Report</p>
                        <p>Monitor employees daily/weekly attendance</p>
                    </div>
                </div>
                </Link>
                <Link to='/manage_profile'>
                <div className={classes.fifth}>
                    <img src={seventh} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Manage Profile</p>
                        <p>View and edit your personal information</p>
                    </div>
                </div>
                </Link>

              
            </div>
            
            <div className={classes.tabs2}>
            <Link to='/leave_management'>
            <div className={classes.first}>
                    <img src={firsts} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Leave Management</p>
                        <p>Manage your employee leave requests</p>
                    </div>
                </div>
                </Link>
                <Link to='/employee_payroll'>
                <div className={classes.second}>
                    <img src={seconds} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Payroll Management</p>
                        <p>Manage your employee leave requests</p>
                    </div>
                </div>
                </Link>
                <Link to='/taskmanagement'>
                <div className={classes.third}>
                    <img src={thirds} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Task Assignment</p>
                        <p>Assign tasks to your employees</p>
                    </div>
                </div>
                </Link>
                <Link to='/hbm'>
                <div className={classes.fourth}>
                    <img src={fourths} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Holiday/Benefits Management</p>
                        <p>Manage employees going on holidays and other benefits</p>
                    </div>
                </div>
                </Link>
                <Link to='/services'>
                <div className={classes.fourth}>
                    <img src={vectorec} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Other Services</p>
                        <p>Manage other services</p>
                    </div>
                </div>
                </Link>


            </div>
            <div className={classes.tabs3}>
            <Link to='/add_dept'>
            <div className={classes.first}>
                    <img src={firstss} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Add Departments</p>
                        <p>Add your company departments to your dashboard</p>
                    </div>
                </div>
                </Link>
                <Link to='/job_application'>
                <div className={classes.second}>
                    <img src={secondss} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Job Applications</p>
                        <p>Add your company departments to your dashboard</p>
                    </div>
                </div>
                </Link>

                <Link to='/exit_management'>
                <div className={classes.third}>
                    <img src={thirdss} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Exit Management</p>
                        <p>Manage exit of employees from your company</p>
                    </div>
                </div>
                </Link>
                <Link to='/shifts_schedule'>
                <div className={classes.fourth}>
                    <img src={fourthss} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Shifts and Schedules</p>
                        <p>Prepare and assign shifts to employees in your company</p>
                    </div>
                </div>
                </Link>
                <Link to='/dashboard'>
                <div className={classes.fifth}>
                    <img src={fifths} alt="First Tab" />
                    <div className={classes.tabtext}>
                        <p>Logout</p>
                        <p>Exit your portal</p>
                    </div>
                </div>
                </Link>
                
            </div>
            </div>

        </div>
    );
}

export default Dashboard;
