import React, { useEffect, useState } from "react";
import classes from "./CreatePolicy.module.css";
import image1 from "../../Assets/Ellipse 1 (1).png";
import image2 from "../../Assets/Ellipse 1.png";
import approve from "../../Assets/approve.svg";
import decline from "../../Assets/decline.svg";
import view from "../../Assets/view.svg";
import less from "../../Assets/less.svg";
import next from "../../Assets/next.svg";
import plus from "../../Assets/plus.svg";
// import TopNavBar from "../../Components/ErrorModal/TopNavBar/TopNavBar";
import cancel from "../../Assets/cancel.svg";
import search from "../../Assets/search.svg";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import { BASE_URL } from "../api/api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const CreatePolicy = ({ onShow }) => {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [approved, setApproved] = useState("");
  const [type, setType] = useState("");
  const [duration, setDuration] = useState("");
  const [description,setDescription] = useState('')

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("tokens");
      // console.log(value)
      if (value !== null) {
        setBearer(value);
      }
    } catch (e) {
      alert("Failed to fetch the token from storage");
    }
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${bearer}`,
  };

  //fetch records
  const fetchBeneficiaries = async () => {
    console.log("Submitted to creat");
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/leaveRequest/fetchAll`, {
        headers,
      });
      const results = response.data?.data;
      setTableData(results.leave);
      console.log(results?.leave);
      setUserData(results.employees);
      // console.log(results.leave)
      // console.log(results);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getApprovedHandler = async (id) => {
    const data = {
      id: id,
    };

    try {
      const response = await axios.put(`${BASE_URL}/leave/disapprove`, data, {
        headers,
      });
      const results = response.data?.data;
      setApproved(results);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setApproved([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPolicyHandler = async () => {
    setIsLoading(true);

    const data = {
      type: type,
      duration: duration,
      description: description,
    };

    try {
      const response = await axios.post(`${BASE_URL}/leave/create`, data, {
        headers,
      });
      const results = response.data?.data;
      setApproved(results);
      // console.log(response?.data?.message)
      toast.success(response?.data?.message);
      onShow(false);
    } catch (error) {
      // console.log(error?.response?.data?.errors)
      Object.entries(error?.response?.data?.errors).forEach(([key, value]) =>
        toast.error(value[0])
      );
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate("/login");
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setApproved([]);
      }
    } finally {
      setIsLoading(false);

      // fetchBeneficiaries()
    }
  };

  useEffect(() => {
    readData();
  }, []);

  useEffect(() => {
    if (bearer) {
      // fetchBeneficiaries();
    }
  }, [bearer]);
  return (
    <>
      <div className={classes.modalBackdrop}>
        <div className={classes.modalRequest}>
          <div className={classes.divModalTop}>
            <h4 className={classes.requet}>Leave Policy</h4>
            <img src={cancel} alt="cancel" onClick={() => onShow(false)} />
          </div>
          <div className={classes.divInfo}>
            {/* <div className={classes.teamDiv}>
              <label htmlFor="name" className={classes.textText}>Enter name of Team member</label>
              <input type ='text' id='name' placeholder='Awolowo Tunde' className={classes.stDivT}/>
            </div> */}
            <div className={classes.teamDiv}>
              <label htmlFor="department" className={classes.textText}>
                Leave Type
              </label>
              <select
                for="type"
                id="type"
                className={classes.stDivT}
                required
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="" className={classes.stDivT}>
                  Select Leave Type
                </option>
                <option value="Maternity" className={classes.stDivT}>
                  Marternity Leave
                </option>
                <option value="Sick" className={classes.stDivT}>
                  Sick Leave
                </option>
                <option value="Parental" className={classes.stDivT}>
                  Parental leave
                </option>
                <option value="Paternity" className={classes.stDivT}>
                  Paternity leave
                </option>
                <option value="Annual" className={classes.stDivT}>
                  Annual leave
                </option>
                <option value="Jury" className={classes.stDivT}>
                  Jury duty
                </option>
                <option value="Unpaid" className={classes.stDivT}>
                  Unpaid leave
                </option>
                <option value="Marriage" className={classes.stDivT}>
                  Marriage leave
                </option>
              </select>
            </div>
            {/* <div className={classes.teamDiv}>
              <label htmlFor="date" className={classes.textText}>Date of Joining</label>
              <input type ='date' id='date' className={classes.stDivT} />
            </div> */}
            <div className={classes.teamDiv}>
              <label htmlFor="policy" className={classes.textText}>
                Leave Policy
              </label>
              <input
                type="number"
                id="policy"
                className={classes.stDivT}
                placeholder="15"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
              />
            </div>
          </div>
          <div className={classes.desDIv}>
          <label for="decript" className={classes.textText}>Leave Type Description</label>
            <textarea cols={12} rows={8} className={classes.descriptDiv} id="descript" value={description} onChange={(e) => setDescription(e.target.value)}>

            </textarea>
          </div>
          <div className={classes.btnBtn}>
            <button className={classes.appe} onClick={fetchPolicyHandler}>
              {isLoading ? "Loading..." : "Create Leave Policy"}
            </button>
          </div>
        </div>
      </div>
      {/* <TopNavBar /> */}
      <div className={classes.toTUpper}>
        <h4 className={classes.topperText}>Leave Management</h4>
        <div className={classes.firstBtn}>
          <button className={classes.policyBtn}>Create Leave Policy</button>
          <button className={classes.addBtn}>
            <img src={plus} alt="plus" />
            Add Leave
          </button>
        </div>
        <div className={classes.topt}>
          <Link to={"/leave_management"}>
            {" "}
            <p>Leave Request</p>
          </Link>
          <Link to={"/leave_approved"}>
            <p>Approved</p>
          </Link>
          <Link to={"/leave_decline"}>
            <p>Declined</p>
          </Link>
          <Link to={"/leave_policy"}>
            <p>Leave Policy</p>
          </Link>
        </div>
        <div className={classes.topLeave}>
          <div>
            <div className={classes.topTextDiv}>
              <h4 className={classes.topText}>Leave Request</h4>
            </div>
            <div className={classes.divSelect}>
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  placeholder="Search employee name"
                  className={classes.sBAr}
                />
                <img src={search} alt="search" className={classes.imgSearch} />
              </div>

              <select for="department" required className={classes.selectTag}>
                <option value="">Select department</option>
                <option value="tech">Technology</option>
              </select>
              <button className={classes.searchBtn}>Search</button>
            </div>

            <table className={classes.tableDiv}>
              <tr className={classes.tableHeadDiv}>
                <th className={classes.tableHeadRow}>S/N</th>
                <th className={classes.tableHeadRow}>Employee Name</th>
                <th className={classes.tableHeadRow}>Designation</th>
                <th className={classes.tableHeadRow}>Department</th>
                <th className={classes.tableHeadRow}>Date of Joining</th>
                <th className={classes.tableHeadRow}>Leave Policy</th>
                <th className={classes.tableHeadRow}>View</th>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>1</td>
                <td className={classes.pflDiv}>
                  <img src={image1} alt="one" />
                  Matthew Olowade
                </td>
                <td>Media Project Manager </td>
                <td>Digital Media </td>
                <td>25/08/2024</td>
                <td>19 days</td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>2</td>
                <td className={classes.pflDiv}>
                  <img src={image2} alt="two" />
                  Lauren Annabelle
                </td>
                <td>Art Director </td>
                <td>Print Media</td>
                <td>28/08/2024</td>
                <td>13 days</td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>1</td>
                <td className={classes.pflDiv}>
                  <img src={image1} alt="one" />
                  Matthew Olowade
                </td>
                <td>Media Project Manager </td>
                <td>Digital Media </td>
                <td>25/08/2024</td>
                <td>19 days</td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>2</td>
                <td className={classes.pflDiv}>
                  <img src={image2} alt="two" />
                  Lauren Annabelle
                </td>
                <td>Art Director </td>
                <td>Print Media</td>
                <td>28/08/2024</td>
                <td className={classes.btnSet}>13 days</td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
            </table>
            <div className={classes.gotoNext}>
              <button className={classes.prev}>
                <img src={less} alt="less" />
                Previous
              </button>
              <p className={classes.pagination}>Page 1 of 5</p>
              <button className={classes.next}>
                Next
                <img src={next} alt="next" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePolicy;
