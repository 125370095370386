import React, { useState } from 'react';
import styles from './TaskManage.module.css';
import TopNavBar from '../../Components/TopNavBar/TopNavBar';
import TaskManagmentSideNav from '../../Components/TaskManagmentSideNav/TaskManagmentSideNav';
import face from './face.png';
import person from '../../Assets/person.svg';
import attachment from '../../Assets/attachment.svg';
import Select from 'react-select';
import close from './close-circle.svg'
import ViewTask from '../../Components/ViewTask/ViewTask';
import { Link } from 'react-router-dom';

const TaskManage = () => {
    const [activeView, setActiveView] = useState('Board');
    const [showModal, setShowModal] = useState(false);
    const [selectedPriority, setSelectedPriority] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);



    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        const imagesArray = files.map(file => URL.createObjectURL(file)); // Create object URLs for the images
        setSelectedImages((prevImages) => prevImages.concat(imagesArray));
        e.target.value = null; // Reset the input value to allow re-uploading the same file
    };

    const handleTaskCreation = (e) => {
        e.preventDefault();
        // Logic for creating a new task goes here
        console.log("New task created!"); // Placeholder for task creation logic
        setShowModal(false); // Close modal after task creation
    };
    const handlePriorityChange = (selected) => {
        setSelectedPriority(selected);
    };
    const priorityOptions = [
        { value: 'low', label: 'Low' },
        { value: 'medium', label: 'Medium' },
        { value: 'high', label: 'High' },
    ];


    const options = [
        { value: 'task1', label: 'Task 1' },
        { value: 'task2', label: 'Task 2' },
        { value: 'task3', label: 'Task 3' },
    ];

    const handleChange = (option) => {
        setSelectedOption(option);
    };

   
    const [selectedValue, setSelectedValue] = useState(null); // No selection initially

    const handleRadioChange = (value) => {
      if (selectedValue === value) {
        setSelectedValue(null); // Unselect if already selected
      } else {
        setSelectedValue(value); // Select the clicked option
      }
    };
    const customStyles = {
        control: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',    
          height: '50px',          
          width: '300px',
          border: '1px solid #6C757D',
          borderRadius: '4px',
          padding: '0',
          boxSizing: 'border-box', 
          fontSize: '14px',
          cursor: 'pointer',
          marginBottom: '10px'
        }),
        input: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          alignSelf: 'center',     
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: '0 15px',      
          height: '100%',     
        }),
        singleValue: (provided) => ({
          ...provided,
          margin: '0',            
          padding: '0',         
        }),
        placeholder: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          lineHeight: 'normal',
          fontSize: '14px',
          fontWeight: '400',
        }),
      };
      
      const [isModalOpen, setIsModalOpen] = useState(false);

      const openModal = () => setIsModalOpen(true);
      const closeModal = () => setIsModalOpen(false);
    return (
        <div>
            <TopNavBar />
            <ViewTask isOpen={isModalOpen} onClose={closeModal} />
            <div className={styles.firsttop}>
                <div className={styles.topper}>
                    <h3>Task Management</h3>
                    <button className={styles.policyBtn} onClick={() => setShowModal(true)}>
                        Create New Task
                    </button>
                </div>
                <div className={styles.secondtop}>
                    <p>Groups</p>
                    <div className={styles.topt}>
          <Link to={'/tasks'}><p>Board View</p></Link>
          <Link to={'/tasks_grid'}><p>Grid View</p></Link>
        </div>
                </div>
                {/* Flex container to align sidenav and boardview side by side */}
                <div className={styles.container}>
                    <TaskManagmentSideNav />
                    {activeView === 'Board' && (
                        <div className={styles.generalsections}>
                            <div className={styles.boardview}>
                                <div className={styles.section}>
                                    <h4>To Do</h4>
                                    <div className={styles.firstcolumn}>
                                        {/* Example task */}
                                        <div className={styles.task}>
                                            <div className={styles.flex}>
                                                <p>Meeting with Farmcrowdy</p>
                                                <input 
                                                  type="radio"
                                                  value="option"
                                                  checked={selectedValue === "option"}
                                                  onClick={() => handleRadioChange("option")} 
                                                  className={styles.completedRadio} 
                                                  disabled
                                                 />
                                            </div>
                                            <h6>Second meeting to discuss the partner...</h6>
                                            <hr />
                                            <div className={styles.date} onClick={openModal}  style={{ cursor: 'pointer'}}>
                                                <h6>Start: 31/08/2024</h6>
                                                <h6>End: 31/09/2024</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.section}>
                                    <h4>In Progress</h4>
                                    <div className={styles.seccolumn}>
                                        {/* Example task */}
                                        <div className={styles.task}>
                                            <div className={styles.flex}>
                                                <p>Meeting with Farmcrowdy</p>
                                             
                                                    <input
                                                       type="radio"
                                                       value="option1"
                                                       checked={selectedValue === "option1"}
                                                       onClick={() => handleRadioChange("option1")} 
                                                     className={styles.completedRadio} 
                                                     disabled
                                                        />
                                            </div>
                                            <h6>Second meeting to discuss the partner...</h6>
                                            <hr />
                                            <div className={styles.date} onClick={openModal}   style={{ cursor: 'pointer'}}>
                                                <h6>Start: 31/08/2024</h6>
                                                <h6>End: 31/09/2024</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.section}>
                                    <h4>Completed</h4>
                                    <div className={styles.lastcolumn}>
                                        <div className={styles.task}>
                                            <div className={styles.taskcov}>
                                                <p>Meeting with Farmcrowdy</p>
                                                {/* Checked radio button for completed tasks */}
                                                <input type="radio" checked className={styles.completedRadio} />
                                            </div>
                                            <h6>Second meeting to discuss the partner...</h6>
                                            <hr />
                                            <div className={styles.date}>
                                                <h6>Start: 31/08/2024</h6>
                                                <h6>End: 31/09/2024</h6>
                                            </div>
                                        </div>

                                        <div className={styles.task}>
                                            <div className={styles.taskcov}>
                                                <p>Meeting with Farmcrowdy</p>
                                                {/* Checked radio button for completed tasks */}
                                                <input type="radio" checked className={styles.completedRadio} />
                                            </div>
                                            <h6>Second meeting to discuss the partner...</h6>
                                            <hr />
                                            <div className={styles.date}>
                                                <h6>Start: 31/08/2024</h6>
                                                <h6>End: 31/09/2024</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.section}>
                                    <button className={styles.policyBtn1}>Add new Bucket</button>
                                </div>
                            </div>
                        </div>
                    )}
                     {showModal && (
    <div className={styles.modal}>
        <div className={styles.modalContent}>
            <span className={styles.close} onClick={() => setShowModal(false)}><img src={close}></img></span>
            <h3>Create Task</h3>
            <div style={{ height: '1px', width: '100%', border: '1px solid #E9ECEF',}}></div>
            <div className={styles.formmodal}>
                <p>Title of Task</p>
                <input type="text" placeholder='Enter a Task Title...' />
            </div>
            <div className={styles.tasks}>
                <h4><img src={person} alt='' /> Assign Task</h4>
                <div className={styles.imagestask}>
                    <div className={styles.firstimage}>
                        <img src={face} alt="" />
                        <p>Amanda Marcel</p>
                    </div>

                    <div className={styles.secondimage}>
                        <img src={face} alt="" />
                        <p>John Doe</p>
                    </div>
                </div>
            </div>
            <div className={styles.describe}>
                <p>Description of Task</p>
                <div className={styles.describefields}>
                    <textarea className={styles.textarea} placeholder="Enter task details..." />
                    <div className={styles.dropdown}>
                        <p>Select Bucket</p>
                        <Select
                            value={selectedOption}
                            onChange={handleChange}
                            options={options}
                            placeholder="Choose a task..."
                            styles={customStyles}
                        />
                        <p>Priority</p>
                        <Select
                            value={selectedPriority}
                            onChange={handlePriorityChange}
                            options={priorityOptions}
                            placeholder="Choose priority..."
                            styles={customStyles}
                        />
                        {/* <div className={styles.dateFields}> */}
                            <div className={styles.dateField}>
                                <p>Start Date</p>
                                <input
                                    type="date"
                                    className={styles.dateInput}
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </div>
                            <div className={styles.dateField}>
                                <p>End Date</p>
                                <input
                                    type="date"
                                    className={styles.dateInput}
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                        {/* </div> */}
                    </div>

                </div>
            </div>
      
                                <div 
        className={styles.addattachment} 
        onClick={() => document.getElementById('file-upload').click()}
        >
        <p>
            <img src={attachment} alt='' />Add Attachment
        </p>
        <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
            style={{ display: 'none' }} 
            id="file-upload" 
        />
        </div>


            <div className={styles.imagePreview}>
                {selectedImages.map((image, index) => (
                    <img key={index} src={image} alt={`Uploaded preview ${index}`} className={styles.image} />
                ))}
            </div>
            <div className={styles.comments}>
                <div className={styles.commentsstyle}>
                    <p>Comment</p>
                    <textarea className={styles.textarea2} placeholder="write a comment here ..." />
                </div>
            </div>
            <button className={styles.policyBtn2} onClick={() => setShowModal(true)}>
                Send
            </button>

        </div>
    </div>
)}

                    
                   
                </div>
            </div>
        </div>
    );
};

export default TaskManage;








// {activeView === 'Grid' && (
//     <div className={styles.generalsections}>
//         <div className={styles.boardview}>
//             <div className={styles.gridView}>
//                 <div className={styles.gridContainer}>
//                     {/* Table Headers */}
//                     <div className={styles.gridHeader}>
//                         <div className={`${styles.gridCell} ${styles.gridCellTitle}`}>Title</div>
//                         <div className={`${styles.gridCell} ${styles.gridCellAssigned}`}>Assigned to</div>
//                         <div className={`${styles.gridCell} ${styles.gridCellDate}`}>Start Date</div>
//                         <div className={`${styles.gridCell} ${styles.gridCellDate}`}>End Date</div>
//                         <div className={`${styles.gridCell} ${styles.gridCellBucket}`}>Bucket</div>
//                         <div className={`${styles.gridCell} ${styles.gridCellPriority}`}>Priority</div>
//                     </div>
//                     {/* Example Task Row */}
//                     <div className={styles.gridRow} onClick={openModal}   style={{ cursor: 'pointer'}}>
//                         <div className={styles.newnew}>
//                         <input
//                                    type="radio"
//                                    value="option1"
//                                    checked={selectedValue === "option1"}
//                                    onClick={() => handleRadioChange("option1")} 
//                                  className={styles.completedRadio} 
//                                  disabled
//                                     />
//                             <div className={`${styles.gridCell} ${styles.gridCellTitle}`}>Marketing Campaign for new product</div>
//                         </div>
//                         <div className={`${styles.gridCell} ${styles.gridCellAssigned}`}>
//                             <img src={face} alt="Assigned" />John Doe
//                         </div>
//                         <div className={`${styles.gridCell} ${styles.gridCellDate}`}>31/08/2024</div>
//                         <div className={`${styles.gridCell} ${styles.gridCellDate}`}>31/09/2024</div>
//                         <div className={`${styles.gridCell} ${styles.gridCellBucket}`}>Marketing</div>
//                         <div className={`${styles.gridCell} ${styles.gridCellPriority}`}>High</div>
//                     </div>
//                     <div className={styles.gridRow} onClick={openModal}   style={{ cursor: 'pointer'}}>
//                         <div className={styles.newnew}>
//                         <input
//                                    type="radio"
//                                    value="option"
//                                    checked={selectedValue === "option"}
//                                    onClick={() => handleRadioChange("option")} 
//                                  className={styles.completedRadio} 
//                                  disabled
                           
//                                     />
//                             <div className={`${styles.gridCell} ${styles.gridCellTitle}`}>Marketing Campaign for new product</div>
//                         </div>
//                         <div className={`${styles.gridCell} ${styles.gridCellAssigned}`}>
//                             <img src={face} alt="Assigned" />John Doe
//                         </div>
//                         <div className={`${styles.gridCell} ${styles.gridCellDate}`}>31/08/2024</div>
//                         <div className={`${styles.gridCell} ${styles.gridCellDate}`}>31/09/2024</div>
//                         <div className={`${styles.gridCell} ${styles.gridCellBucket}`}>Marketing</div>
//                         <div className={`${styles.gridCell} ${styles.gridCellPriority}`}>High</div>
//                     </div>                                    </div>
//             </div>
//         </div>
//     </div>
// )}





