import React, { useState, useEffect } from 'react';
import classes from './sendInterviewInvite.module.css';
import cancel from "../../Assets/cancel.svg";
import { BASE_URL } from '../api/../../Pages/api/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const SendInterviewInvite = ({ isOpen, onClose,userId }) => {
    const [animate, setAnimate] = useState(false);
    const [date,setDate] = useState('')

    const navigate = useNavigate();
    const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);

    
  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('tokens');
      // console.log(value)
      if (value !== null) {
        setBearer(value);
      }
    } catch (e) {
      alert('Failed to fetch the token from storage');
    }
  };



  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };


    const fetchBeneficiaries = async (e) => {
        e.preventDefault()
        setIsLoading(true);
        try {
          const response = await axios.get(`${BASE_URL}/job/schedule-interview/${userId}`, {
            params: {"interview_date": date
               },
            headers: {
              Authorization: `Bearer ${bearer}`,
              'Content-Type': 'application/json'
            },
          }
          );
          const results = response.data?.data;
          toast.success(response?.data?.message)
          onClose()
      
        } catch (error) {
          if (error.response && error.response.status === 401) {
            // Redirect to login page if unauthorized
            navigate('/login');
          } else {
          const errorStatus = error.response?.data?.message;
         
        }
        } finally {
          setIsLoading(false);
        }
      };

      
  useEffect(() => {

    readData();
  }, []);


    useEffect(() => {
        if (isOpen) {
            // Delay to give a smooth entrance when the modal opens
            const timer = setTimeout(() => {
                setAnimate(true);
            }, 500); // Adjust delay as needed

            return () => clearTimeout(timer);
        } else {
            setAnimate(false); // Reset animation when the modal is closed
        }
    }, [isOpen]);

    if (!isOpen) return null;
  return (
    <div className={classes.modalOverlayWhole11}>
    <div className={classes.modalErrYes11}>
        <button className={classes.closeButtonModally11} onClick={onClose}>
            <img src={cancel} alt='' />
        </button>
        <form className={classes.modalContainer2211} onSubmit={fetchBeneficiaries}>
            <h6>Send Interview Invite</h6>
            {/* Deductions Section */}
            <div className={classes.classGroupContExe11}>
                <div className={classes.contentRewper11}>
                    <h4>Set the interview date for the  candidate</h4>
                    <div className={classes.divInputT}>
                    <label htmlFor='salary' style={{color:'#6c757d',fontSize:'12'}}>Interview Date:</label>
                        <input type='date' id='salary' className={classes.inputSalary} value={date} onChange={(e) => setDate(e.target.value)}/>
                    </div>
                    {/* <textarea rows='10' className={classes.pTextParagraph11} placeholder='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam 
voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione 
voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci 
illum qui dolorem eum fugiat quo voluptas nulla pariatur'>
                    </textarea> */}
                </div>
            </div>
            <button type='submit' className={classes.addTeamBtn11}>
              {isLoading ? 'Sending...' : 'Send'}
            </button>
        </form>
    </div>
</div>

  )
}

export default SendInterviewInvite
