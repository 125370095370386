import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from "./Pages/Login/login";
import Employees from "./Pages/Employees/employee";
import LeaveManagement from "./Pages/LeaveManagement/LeaveManagement";
import LeaveRequest from "./Pages/LeaveRequest/LeaveRequest";
import EmployeeProfile from "./Pages/EmployeeProfile/employee_profile";
import Sign_Up from "./Pages/SignUp/signUp";
import SignUp_Admin from "./Pages/SignUp_Admin/signAdmin";
import Org_Details from "./Pages/Organization Details/org_details";
import LeaveManagementApproved from "./Pages/LeaveManagementApproved/LeaveManagement";
import LeaveManagementModal from "./Pages/LeaveManagementModal/LeaveManagementModal";
import LeaveManagementDecline from "./Pages/LeaveManagementDecline/LeaveManagementDecline";
import LeaveDeclineModal from "./Pages/LeaveDeclineModal/LeaveDeclineModal";
import LeavePolicy from "./Pages/LeavePolicy/LeavePolicy";
import LeavePolicyModal from "./Pages/LeavePolicyModal/LeavePolicyModal";
import Org_Address from "./Pages/Organization Address/org_address";
import Personal from "./Pages/Personal Details/personalDetails";
import Welcomes from "./Pages/WelcomeHRM/welcome";
import AddEmployeeModal from "./Components/Add Employee Modal/AddEmpModal";
import LeavePolicyEdit from "./Pages/LeavePolicyEdit/LeavePolicyEdit";
import CreatePolicy from "./Pages/CreatePolicy/CreatePolicy";
import AddLeave from "./Pages/AddLeave/AddLeave";
import Dashboard from "./Pages/Dashboard/Dashboard";
import AddDept from "./Pages/AddDept/AddDept";
import PayrolModal from "./Pages/PayrollModal/PayrollModal";
import Designation from "./Pages/Designations/Designation";
import PayrollModalReceipt from "./Pages/PayrollModalReceipt/PayrollModalReceipt";
import EmployeePayroll from "./Pages/EmployeePayroll/EmployeePayroll";
import JobApplication from "./Pages/JobApplication/JobApplication"
import ViewCandidate from "./Pages/ViewCandidate.js/ViewCandidate";
import Application from "./Pages/Application/Application"
import StopSubmission from "./Pages/StopSubmission.js/StopSubmission";
import ShortListCandidate from "./Pages/ShortListCandidate/ShortListCandidate";
import RejectCandidate from "./Pages/RejectCandidate/RejectCandidate";
import ShortlistedCandidates from "./Pages/shortlistedCandidates/shortlistedCandidates"
import { AuthProvider } from "./Pages/utils/AuthContext";
import { FormProvider } from "./Components/FormContext";
import EmployCandidate from "./Pages/EmployedCandidates/EmployedCandidates";
import Archives from "./Pages/Archives/Archives";
import Analytics from "./Pages/Analytics/Analytics";
import Modal from 'react-modal';
import EditEmpSalaryModal from "./Components/EditEmpSalaryModal/EditEmpSalaryModal";
import EditEmployee from "./Components/EditEmployee/EditEmployee";
import Appraisal from "./Pages/Appraisal/Appraisal";
import CompletedAppraisal from "./Pages/completedAppraisal/completedAppraisal";
import ExitManagement from "./Pages/ExitManagement/ExitManagement";
import AttendanceReport from "./Pages/AttendanceReport/AttendanceReport";
import Holiday from "./Pages/HBM/Holiday";
import Services from "./Pages/Services/Services";
import ProfileInformation from "./Pages/ProfileInformation/ProfileInformation";
import ManageProfile from "./Pages/ManageProfile/ManageProfile";
import EditProfile from "./Pages/EditProfile/EditProfile";
import ShiftsandSchedule from "./Pages/ShiftsandSchedule/ShiftsandSchedule";
import Shifts from "./Pages/Shifts/shifts";
import AddMeeting from "./Pages/AddMeeting/AddMeeting";
import EditMeeting from "./Pages/EditMeeting/EditMeeting";
import TaskManage from "./Pages/TaskManage/TaskManage";
import TaskManageGrid from "./Pages/TaskManageGrid/TaskManageGrid";


Modal.setAppElement('#root');

function App() {
  return (
    <FormProvider>
    <Router>
      <AuthProvider>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/sign_up' element={<Sign_Up />} />
        <Route path='/sign_up_admin' element={<SignUp_Admin />} />
        <Route path='/organization' element={<Org_Details />} />
        <Route path='/address' element={<Org_Address />} />
        <Route path='/personal' element={<Personal />} />
        <Route path='/welcomes' element={<Welcomes />} />
        <Route path='/employee' element={<Employees />} />
        <Route path='/leave_management' element={<LeaveManagement />} />
        <Route path='/leave_request' element={<LeaveRequest />} />
        <Route path='/leave_approved' element={<LeaveManagementApproved />} />
        <Route path='/leave_management_modal' element={<LeaveManagementModal />} />
        <Route path='/leave_decline' element={<LeaveManagementDecline />} />
        <Route path='/leave_decline_modal' element={<LeaveDeclineModal />} />
        <Route path='/leave_policy' element={<LeavePolicy />} />
        <Route path='/add_emp' element={<AddEmployeeModal />} />
        <Route path='/leave_policy_modal' element={<LeavePolicyModal />} />
        <Route path='/leave_policy_edit' element={<LeavePolicyEdit />} />
        <Route path='/create_policy' element={<CreatePolicy />} />
        <Route path='/add_leave' element={<AddLeave />} />
        <Route path='/employee_profile/:employeeId' element={<EmployeeProfile />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/add_dept' element={<AddDept />} />
        <Route path='/payroll_modal/:employeeId' element={<PayrolModal />} />
        <Route path='/payroll_receipt' element={<PayrollModalReceipt />} />
        <Route path='/designation' element={<Designation />} />
        <Route path='/employee_payroll' element={<EmployeePayroll />} />
        <Route path='/job_application' element={<JobApplication />} />
        <Route path='/view_candidate' element={<ViewCandidate />} />
        <Route path='/stop_submission' element={<StopSubmission />} />
        <Route path='/shortlist_candidate' element={<ShortListCandidate />} />
        <Route path='/reject_candidate' element={<RejectCandidate />} />
        <Route path='/application' element={<Application />} />
        <Route path='/shortlisted_candidates' element={<ShortlistedCandidates />} />
        <Route path='/employed_candidates' element={<EmployCandidate />} />
        <Route path='/archives' element={<Archives />} />
        <Route path='/analytics' element={<Analytics />} />
        <Route path='/editempsalary' element={<EditEmpSalaryModal />} />
        <Route path='/appraisal' element={<Appraisal />} />
        <Route path='/completed_appraisal' element={<CompletedAppraisal />} />
        <Route path='/exit_management' element={<ExitManagement />} />
        <Route path='/attendance_report' element={<AttendanceReport />} />
        <Route path='/hbm' element={<Holiday />} />
        <Route path='/services' element={<Services />} />
        <Route path='/profile_information' element={<ProfileInformation />} />
        <Route path='/shifts_schedule' element={<ShiftsandSchedule />} />
        <Route path='/shifts' element={<Shifts />} />
        <Route path='/manage_profile' element={<ManageProfile />} />
        <Route path='/edit_profile' element={<EditProfile />} />
        <Route path='/add_meeting' element={<AddMeeting />} />
        <Route path='/edit_meeting' element={<EditMeeting />} />
        <Route path='/tasks' element={<TaskManage />} />
        <Route path='/tasks_grid' element={<TaskManageGrid />} />
       
        </Routes>
      </AuthProvider>
    </Router>
    </FormProvider>
  );
}
export default App;
