import React, { useState, useEffect } from 'react';
import classes from './EditAppraisal.module.css';
import closeX from './CloseX.svg';
import addNew from '../../Assets/addNew.svg';
import attach from '../../Assets/attachfile.svg'
import axios from 'axios';
import Select from 'react-select';
import plus from '../../Assets/plus.svg';
import AsyncStorage from '@react-native-async-storage/async-storage';

const EditAppraisal = ({ isOpen, onClose }) => {

    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (isOpen) {
            // Delay to give a smooth entrance when the modal opens
            const timer = setTimeout(() => {
                setAnimate(true);
            }, 500); // Adjust delay as needed

            return () => clearTimeout(timer);
        } else {
            setAnimate(false); // Reset animation when the modal is closed
        }
    }, [isOpen]);

    if (!isOpen) return null;
  return (
 
       <div className={classes.modalOverlayWhole}>
            <div className={classes.modalErrYes}>
                <button className={classes.closeButtonModally} onClick={onClose}>
                    <img src={closeX} alt='' />
                </button>
                <form  className={classes.modalContainer22}>
                    <h6>Edit Appraisal</h6>

                    {/* Employee Information */}
                    <h5>Assign Goal to employee</h5>
                    <div className={classes.classGroupCont}>
                    <div className={classes.labelandInput}>
                            <label>Select Department</label>
                            <Select
                                name='department'
                                required
                            />
                        </div>
                        <div className={classes.labelandInput}>
                            <label>Select Department</label>
                            <Select
                                name='department'
                                required
                            />
                        </div>
                        <div className={classes.labelandInput}>
                            <label>End Date</label>
                            <input
                                type='date'
                                name="end_date"
                                required
                                placeholder='Accountant'
                            />
                        </div>
                        

                    </div>

                    {/* Payslip Date Range */}
                    <h5>Appraisal reviewers</h5>
                    <div className={classes.classGroupCont}>
                    <div className={classes.labelandInput}>
                            <label>Select name</label>
                            <Select
                                name='department'
                                required
                            />
                        </div>

                        <div className={classes.labelandInput}>
                            <label>Select name</label>
                            <Select
                                name='department'
                                required
                            />
                        </div>
                            
                    </div>

                    {/* Earnings Section */}
                    <h5>Goal 1</h5>
                    <div className={classes.classGroupContSect}>
                    <div className={classes.labelandInputSec}>
                            <label>Select type of Goal</label>
                            <Select
                                name='department'
                                required
                            />
                        </div>
                      
                        <div className={classes.labelandInputSec}>
                            <label>Name of Goal</label>
                          <input type='text' placeholder='Enter Name of Goal...'/>
                        </div>
                       
                        <div className={classes.contentRewper}>
                            <h4>Description</h4>
                            <textarea rows='13' className={classes.pTextParagraph}
                                name='description'
                               
                                placeholder='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.'>

                            </textarea>
                        </div>

                        <div className={classes.keyResult}>
                            <label className={classes.flexColumnKey}>
                        <h5>KEY RESULT</h5>
                        <p>Enter Key results you hope to achieve</p>
                        </label>
                    <div className={classes.classGroupContExeKeyResult}>
                    <label>Key result 1</label>
                        <div className={classes.contentRewperInput}>
                            <input className={classes.InputFieldDesfgn} placeholder='Enter Key Result...'/>
                        </div>
                    </div>
                    </div>
                    <div className={classes.navigateBack}>
                            <img src={plus} alt='' />
                            <p className={classes.nameGroupType}>Add new</p>
                        </div>
                        </div>
                        <div className={classes.navigateBack12}>
                            <img src={plus} alt='' />
                            <p className={classes.nameGroupType}>Add another goal</p>
                        </div>
                        <div className={classes.BtnRightMove}>
                    <button type='submit' className={classes.addTeamBtn}>
                       Save Changes
                    </button>
                    </div>
                </form>
            </div>
    </div>
  )
}

export default EditAppraisal
