import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import classes from "./shifts.module.css";
import less from "../../Assets/less.svg";
import next from "../../Assets/next.svg";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import EmployeeImg from "../../Assets/EmployeeImg.png"
import eye from "../../Assets/eye.svg"
import edit from "../../Assets/edit.svg"
import Select from 'react-select';
import deleteimg from "../../Assets/deleteimg.svg"
import { Link } from "react-router-dom";
import search from '../../Assets/search.svg';
import AddTermination from "../../Components/AddTermination/AddTermination";
import TerminatedEmployees from "../../Components/TerminatedEmployees/TerminatedEmployees";
import AttendanceReportModal from "../../Components/AttendanceReportModal/AttendanceReportModal";
import assign from './assign.svg'
import CloseX from './CloseX.svg';
import CreateShift from "../../Components/createShift/createShift";
import AssignShift from "../../Components/AssignShift/AssignShift";
import EditShift from "../../Components/EditShift/EditShift";
import ShiftEdit from "../../Components/ShiftEdit/ShiftEdit";



const Shifts = () => {
    
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);


    const [isModalOpen1, setIsModalOpen1] = useState(false);

    const openModal1 = () => setIsModalOpen1(true);
    const closeModal1 = () => setIsModalOpen1(false);


    const [isModalOpen2, setIsModalOpen2] = useState(false);

    const openModal2 = () => setIsModalOpen2(true);
    const closeModal2 = () => setIsModalOpen2(false);


    

    const openDeleteModal = (index) => {
        // setDeleteIndex(index);
        setIsDeleteModalOpen(true); 
    };

    const closeDeleteModal = () => {
        // setDeleteIndex(null);
        setIsDeleteModalOpen(false);
    };
   

    const customStyles = {
        control: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',    
          height: '40px',          
          width: '140px',
          border: '1px solid #6C757D',
          borderRadius: '4px',
          padding: '0',
          boxSizing: 'border-box', 
          fontSize: '14px',
          cursor: 'pointer',
        }),
        input: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          alignSelf: 'center',     
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: '0 15px',      
          height: '100%',     
        }),
        singleValue: (provided) => ({
          ...provided,
          margin: '0',            
          padding: '0',         
        }),
        placeholder: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          lineHeight: 'normal',
        }),
      };
      
  return (
    <div> <>
    <TopNavBar />
    <CreateShift isOpen={isModalOpen} onClose={closeModal} />
    <AssignShift isOpen={isModalOpen1} onClose={closeModal1} />
    <ShiftEdit isOpen={isModalOpen2} onClose={closeModal2} />
    <div className={classes.toTUpper}>
        {/* <AppraisalCreation isOpen={isModalOpen} onClose={closeModal} /> */}
        {/* <EditAppraisal isOpen={isModalOpen1} onClose={closeModal1} /> */}
        {/* <ViewCompletedAppraisals isOpen={isEditModalOpen} onClose={closeEditModal} /> */}
        <div className={classes.topperText}>
            <h4>Shifts/Schedules</h4>
            <div className={classes.groupBtns}>
                        <button className={classes.assignBtn} onClick={openModal1}><img src={assign} />  Assign Shift</button>
                        <button onClick={openModal}>Create Shift</button>
                    </div>
           
        </div>
        <div className={classes.topt}>
                    <Link to={'/shifts_schedule'}><p>Employees</p></Link>
                    {/* <Link to={'/appraisal'}><p>Appraisals Submissions</p></Link> */}
                    {/* <Link to={'/appraisal'}><p>Appraisals Meeting</p></Link> */}
                    <Link to={'/shifts'}><p>Shifts</p></Link>
                </div>
       
        <div className={classes.topLeave}>
            <div>
                {/* <div className={classes.topTextDiv}> */}
                    {/* <h4 className={classes.topText}>Attendance</h4> */}
                    <div className={classes.topTextDiv}>
                            <div>
                            <h4 className={classes.topText}>All Shifts</h4>
                            </div>
                          
                        </div>
                {/* </div> */}

                <div style={{ height: '1px', width: '107%', border: '1px solid #E9ECEF', marginLeft: '-39px' }}></div>

                <div className={classes.tableContainer}>
                    <table className={classes.tableDiv}>
                        <tr className={classes.tableHeadDiv}>
                            <th className={classes.tableHeadRow}>S/N</th>
                            <th className={classes.tableHeadpflDiv}>Shift Title</th>
                            <th className={classes.tableHeadpflDiv}>Start Time</th>
                            <th className={classes.tableHeadpflDiv}>End Time</th>
                            <th className={classes.tableHeadRowAction}>Actions</th>
                        </tr>
                        <tr className={classes.tableRowBody}>
                            <td>1</td>
                            <td className={classes.pflDiv1}>Full day shift</td>
                            <td className={classes.pflDiv1}>08:00am</td>
                            <td className={classes.pflDiv1}>05:00pm</td>
                            <td className={classes.formEye}>
                                {/* <img src={eye} alt="approve" onClick={openModal} /> */}
                                <img src={edit} onClick={openModal2} alt="approve" />
                                <img src={deleteimg} alt="Delete" onClick={openDeleteModal} />
                            </td>
                        </tr>
                        <tr className={classes.tableRowBody}>
                            <td>2</td>
                            <td className={classes.pflDiv1}>Morning shift</td>
                            <td className={classes.pflDiv1}>06:00am</td>
                            <td className={classes.pflDiv1}>02:00pm</td>
                            <td className={classes.formEye}>
                                {/* <img src={eye} alt="approve" onClick={openModal} /> */}
                                <img src={edit} onClick={openModal2} alt="approve" />
                                <img src={deleteimg} alt="Delete" onClick={openDeleteModal} />
                            </td>
                        </tr>
                        <tr className={classes.tableRowBody}>
                            <td>3</td>
                            <td className={classes.pflDiv1}>Afternoon shift</td>
                            <td className={classes.pflDiv1}>02:00pm</td>
                            <td className={classes.pflDiv1}>08:00pm</td>
                            <td className={classes.formEye}>
                                {/* <img src={eye} alt="approve" onClick={openModal} /> */}
                                <img src={edit} onClick={openModal2} alt="approve" />
                                <img src={deleteimg} alt="Delete" onClick={openDeleteModal} />
                            </td>
                        </tr>
                        <tr className={classes.tableRowBody}>
                            <td>4</td>
                            <td className={classes.pflDiv1}>Night shift</td>
                            <td className={classes.pflDiv1}>08:00pm</td>
                            <td className={classes.pflDiv1}>07:00am</td>
                            <td className={classes.formEye}>
                                {/* <img src={eye} alt="approve" onClick={openModal} /> */}
                                <img src={edit} onClick={openModal2} alt="approve" />
                                <img src={deleteimg} alt="Delete" onClick={openDeleteModal} />
                            </td>
                        </tr>
                    </table>
                </div>
                <div className={classes.gotoNext}>
                    <button className={classes.prev}>
                        <img src={less} alt="less" />
                        Previous
                    </button>
                    <p className={classes.pagination}>Page 1 of 5</p>
                    <button className={classes.next}>
                        Next
                        <img src={next} alt="next" />
                    </button>
                </div>


                <Modal
                            isOpen={isDeleteModalOpen}
                        >
                            <div className={classes.modalOverlayWhole}>
                                <div className={classes.modalErrYester}>
                                    <button className={classes.closeButtonModally} onClick={closeDeleteModal}>
                                        <img src={CloseX} alt='' />
                                    </button>
                                    <div className={classes.modalContainerSend}>
                                        <h6>
                                            Delete Shift
                                        </h6>
                                     
                                                <p>
                                                You are about to delete this shift, this action cannot be reversed, 
                                                are you sure you want to continue?
                                                </p>
                                                <button
                                                    type="submit"
                                                    className={classes.addTeamBtn}
                                                >
                                                    Delete
                                                </button>
                                          
                                    </div>
                                </div>
                            </div>
                        </Modal>
               
            </div>
        </div>
    </div>
</>

    </div>
  )
}

export default Shifts;
