import React, { useEffect,useState } from 'react'
import classes from './PayrollConfirm.module.css'
import cancel from "../../Assets/cancel.svg";
import { BASE_URL } from '../api/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const PayrollConfirm = ({onShow,getId}) => {
  console.log(getId)
    const navigate = useNavigate(); 
    const [bearer, setBearer] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [approved,setApproved] = useState('')

    useEffect(() => {
        function escapeHandler(event) {
            if(event.code === 'Escape') {
                onShow(false)
            }
        }
        window.addEventListener('keydown',escapeHandler)
    })

    useEffect(() => {

        readData();
        }, []);
      
      
    //   useEffect(() => {
      
    //     if (bearer) {
    //       fetchBeneficiaries();
      
    //     }
    //   }, [bearer,showLeave,approved]);

    
  const readData = async () => {
    try {
        const value = await AsyncStorage.getItem('tokens');
        // console.log(value)
        if (value !== null) {
            setBearer(value);
        }
    } catch (e) {
        alert('Failed to fetch the token from storage');
    }
};



 const headers = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${bearer}`
};


    const getApprovedHandler = async () => {
        setIsLoading(true)
      
        try {
          const response = await axios.get('https://hr-api.emas.ng/api/payroll/send-payslips', {
            params: { "employee_id": getId },
            headers: {
                Authorization: `Bearer ${bearer}`,
            },
        })
        toast.success(response?.data?.message)
          // await axios.get(`${BASE_URL}/payroll/send-payslips`,params, { headers });
          const results = response.data?.data
          setApproved(results)
      
        } catch (error) {
          
          if (error.response && error.response.status === 401) {
            // Redirect to login page if unauthorized
            navigate('/login');
          } else {
          const errorStatus = error.response?.data?.message;
          console.log(errorStatus);
          setApproved([]);
        }
      } finally {
        setIsLoading(false);
        onShow(false)
       
      }
        
      }

  return (
    <div>
        <div className={classes.modalBackdrop}>
            <div className={classes.modalRequest}>
                <div className={classes.cancelBtn}>
            <img src={cancel} alt="cancel" onClick={() => onShow(false)}/>
            </div>
                <div>
                <h4 className={classes.hederTExt}>Send Payroll</h4>
                <p className={classes.prgText}>You are about to send this payroll slip, this action cannot be reversed, are you sure you want to continue?</p>
                </div>
                <button className={classes.approveBtn} onClick={() => getApprovedHandler()}>{isLoading ? 'Loading...' : 'Confirm'}</button>
            </div>
        </div>
    </div>
  )
}

export default PayrollConfirm