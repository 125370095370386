import React, { useState, useContext } from 'react';
import classes from '../Organization Address/org_address.module.css';
import Sidebar from '../../Components/SideBar/SideBar';
import { BiRightArrowAlt } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { Navbar, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { FormContext } from '../../Components/FormContext';
import Select from 'react-select'; // React Select imported

const Org_Address = () => {
    const { formData, setFormData } = useContext(FormContext);
    const navigate = useNavigate();

    // State for Nigerian states select
    const [state, setState] = useState(null); // Single state value

    const handleNext = () => {
        // Navigate to the next page
        navigate('/personal');
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Nigerian states options for react-select
    const nigerianStates = [
        { value: 'abia', label: 'Abia' },
        { value: 'adamawa', label: 'Adamawa' },
        { value: 'akwa_ibom', label: 'Akwa Ibom' },
        { value: 'anambra', label: 'Anambra' },
        { value: 'bauchi', label: 'Bauchi' },
        { value: 'bayelsa', label: 'Bayelsa' },
        { value: 'benue', label: 'Benue' },
        { value: 'borno', label: 'Borno' },
        { value: 'cross_river', label: 'Cross River' },
        { value: 'delta', label: 'Delta' },
        { value: 'ebonyi', label: 'Ebonyi' },
        { value: 'edo', label: 'Edo' },
        { value: 'ekiti', label: 'Ekiti' },
        { value: 'enugu', label: 'Enugu' },
        { value: 'gombe', label: 'Gombe' },
        { value: 'imo', label: 'Imo' },
        { value: 'jigawa', label: 'Jigawa' },
        { value: 'kaduna', label: 'Kaduna' },
        { value: 'kano', label: 'Kano' },
        { value: 'katsina', label: 'Katsina' },
        { value: 'kebbi', label: 'Kebbi' },
        { value: 'kogi', label: 'Kogi' },
        { value: 'kwara', label: 'Kwara' },
        { value: 'lagos', label: 'Lagos' },
        { value: 'nasarawa', label: 'Nasarawa' },
        { value: 'niger', label: 'Niger' },
        { value: 'ogun', label: 'Ogun' },
        { value: 'ondo', label: 'Ondo' },
        { value: 'osun', label: 'Osun' },
        { value: 'oyo', label: 'Oyo' },
        { value: 'plateau', label: 'Plateau' },
        { value: 'rivers', label: 'Rivers' },
        { value: 'sokoto', label: 'Sokoto' },
        { value: 'taraba', label: 'Taraba' },
        { value: 'yobe', label: 'Yobe' },
        { value: 'zamfara', label: 'Zamfara' },
        { value: 'fct', label: 'Federal Capital Territory (FCT)' }
    ];

    // Function to handle select change
    const handleStateChange = (selectedOption) => {
        setState(selectedOption);
        // Update form data with selected state
        setFormData({ ...formData, state: selectedOption.value });
    };

    return (
        <div className={classes.containers}>
            <div className={classes.sidenav}>
                <Navbar expand="lg" className={`d-none d-md-block ${classes.navbar}`}>
                    <Container fluid>
                    </Container>
                </Navbar>
                <Navbar bg="light" expand={false} className="d-md-none">
                    <Container fluid>
                        <Button variant="success" onClick={handleShow}>
                            ☰
                        </Button>
                    </Container>
                </Navbar>
            </div>

            <Sidebar show={show} handleClose={handleClose} />
            <section className={classes.skiped}>
                <Link to='/'><a href=''><HiOutlineArrowNarrowLeft className={classes.arrowIconss} />Back</a></Link>
            </section>

            <div className={classes.contentSpace101}>
                <div className={classes.heading}>
                    <h3 >Organization Address</h3>
                    <p>Please enter your organization address</p>
                </div>

                <div className={classes.formGroupCnt}>
                    <div className={classes.rowLabelColumn}>
                        <label>Street Address</label>
                        <input
                            type='text'
                            value={formData.street_address || ''}
                            onChange={(e) => setFormData({ ...formData, street_address: e.target.value })}
                            placeholder='Lekki Phase 1'
                        />
                    </div>
                    <div className={classes.rowLabelColumn}>
                        <label>City</label>
                        <input
                            type='text'
                            value={formData.city || ''}
                            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                            placeholder='Lagos, Island'
                        />
                    </div>
                    <div className={classes.rowLabelColumn}>
                        <label>State</label>
                        <Select
                            value={state}
                            onChange={handleStateChange}
                            options={nigerianStates} // Nigerian states options
                            placeholder='Select State'
                        />
                    </div>
                </div>

                <div className={classes.newDivs20}>
                    <div className={`btn btn-success ${classes.btn}`} style={{ textAlign: 'center' }} onClick={handleNext}>
                        Next
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Org_Address;
