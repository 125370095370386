import React, { useState, useEffect } from 'react';
import classes from './OpenArchivedJob.module.css';
import closeX from './CloseX.svg';
import addNew from '../../Assets/addNew.svg';
import attach from '../../Assets/attachfile.svg'
import briefcase from '../../Assets/briefcase.svg'
import facebook from '../../Assets/facebook.svg'
import linked from '../../Assets/linkedIn.svg'
import twitter from '../../Assets/twitter.svg'
import ActivateJobApplication from '../ActivateJobApplication/ActivateJobApplication';

const OpenArchivedJob = ({ isOpen, onClose }) => {
    const [animate, setAnimate] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const [allowances, setAllowances] = useState([]);
    const [deductions, setDeductions] = useState([]);
    const [filename, setFileName] = useState("Attach your resume/CV")
    const [filename1, setFileName1] = useState("Attach your cover letter")

    useEffect(() => {
        if (isOpen) {
            // Delay to give a smooth entrance when the modal opens
            const timer = setTimeout(() => {
                setAnimate(true);
            }, 500); // Adjust delay as needed

            return () => clearTimeout(timer);
        } else {
            setAnimate(false); // Reset animation when the modal is closed
        }
    }, [isOpen]);

    const handleAddAllowance = () => {
        setAllowances([...allowances, { key: Date.now(), value: '' }]);
    };

    const handleDeleteLastAllowance = () => {
        if (allowances.length > 0) {
            setAllowances(allowances.slice(0, -1));
        }
    };

    const handleAddDeduction = () => {
        setDeductions([...deductions, { key: Date.now(), value: '' }]);
    };

    const handleDeleteLastDeduction = () => {
        if (deductions.length > 0) {
            setDeductions(deductions.slice(0, -1));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission with allowances and deductions included
        const formData = {
            allowances,
            deductions,
        };
        console.log(formData);
        // Further processing
    };

    if (!isOpen) return null;

    const getFileHandler = (e) => {
        const file = e.target.value.slice(12);
        if (file) {
            setFileName(file)
        }
        else {
            setFileName("Attach your resume/CV")
        }
    }
    const getFileHandler1 = (e) => {
        const file = e.target.value.slice(12);
        if (file) {
            setFileName1(file)
        }
        else {
            setFileName1("Attach your resume/CV")
        }
    }

    return (
        <div className={classes.modalOverlayWhole}>
            <div className={classes.modalErrYes}>
            <ActivateJobApplication isOpen={isModalOpen} onClose={closeModal} />
                <div className={classes.BtnflexRow}>
                <button onClick={openModal} className={classes.openModallyBtn}>Activate Job Application</button>
                <button className={classes.closeButtonModally} onClick={onClose}>
                    <img src={closeX} alt='' />
                </button>
                </div>
                <div className={classes.bodilyBody}>
                    <h4>COMPANY LOGO</h4>

                    <div className={classes.secondBodily}>
                        <h6>Accountant</h6>
                        <p>Lancaster Trust Limited</p>
                        <pre>Lekki Phase 1, Lagos Island, Lagos, Nigeria</pre>
                    </div>
                    <div className={classes.thirdBodily}>
                        <h6>Job Information</h6>
                        <div className={classes.rowImgFlex}>
                            <img src={briefcase} alt='' />
                            <p>Full-time . On-site</p>
                        </div>
                        <div className={classes.rowImgFlex}>
                            <img src={briefcase} alt='' />
                            <p>3 years of experience required</p>
                        </div>
                        <div className={classes.rowImgFlex}>
                            <img src={briefcase} alt='' />
                            <p>Estimated pay for the role - N250,000 to N350,000</p>
                        </div>
                    </div>
                    <div className={classes.thirdBodily}>
                        <h6>Why Lancaster Trust Limited</h6>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                            eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                            voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                            voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                            velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut
                            enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
                            consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel
                            illum qui dolorem eum fugiat quo voluptas nulla pariatur</p>
                    </div>
                    <div className={classes.thirdBodily}>
                        <h6>Responsibilities</h6>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                            eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                            voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                            voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                            velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut
                            enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
                            consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel
                            illum qui dolorem eum fugiat quo voluptas nulla pariatur</p>
                    </div>
                    <div className={classes.thirdBodily}>
                        <h6>Requirements</h6>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                            eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                            voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                            voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                            velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut
                            enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
                            consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel
                            illum qui dolorem eum fugiat quo voluptas nulla pariatur</p>
                    </div>
                    <div className={classes.theFormThings}>
                        <h6>Apply for this Job</h6>
                        <form>
                            <div className={classes.labelandInputsDiv}>
                                <label htmlFor='startDate'>First Name</label>
                                <input type='text' id='first Name' required placeholder='Enter Your First Name' />
                            </div>
                            <div className={classes.labelandInputsDiv}>
                                <label htmlFor='startDate'>Last Name</label>
                                <input type='text' id='Last Name' required placeholder='Enter Your Last Name' />
                            </div>
                            <div className={classes.labelandInputsDiv}>
                                <label htmlFor='startDate'>Email Address</label>
                                <input type='email' id='email' required placeholder='Enter Your Email Address' />
                            </div>
                            <div className={classes.labelandInputsDiv}>
                                <label htmlFor='startDate'>Phone Number</label>
                                <input type='text' id='phone number' required placeholder='Enter Your Phone Number' />
                            </div>
                            <div className={classes.labelandInputsDiv}>

                                <label htmlFor='startDate'>Resume/CV</label>
                                <div className={classes.hiddenDiv}><p>{filename}</p> <img src={attach} alt='' /></div>
                                <input type='file' required placeholder='Attach your Resume or CV' className={classes.hidenInput} onChange={getFileHandler} />
                            </div>
                            <div className={classes.labelandInputsDiv}>
                                <label htmlFor='startDate'>Cover Letter</label>
                                <div className={classes.hiddenDiv}><p>{filename1}</p> <img src={attach} alt='' /> </div>
                                <input type='file' id='first Name' required placeholder='Attach your cover letter' className={classes.hidenInput} onChange={getFileHandler1} />
                            </div>

                        </form>

                    </div>
                    <button type='submit' className={classes.addTeamBtn101}>
                        Submit Application
                    </button>
                    <div className={classes.socialsThings}>
                        <h5>Share Job on social media</h5>
                        <div className={classes.socialities}>
                            <div className={classes.sicialgroup}>
                                <img src={facebook} alt='' />
                                <p>Share on Facebook</p>
                            </div>
                            <div className={classes.sicialgroup}>
                                <img src={linked} alt='' />
                                <p>Share on LinkedIn</p>
                            </div>
                            <div className={classes.sicialgroup}>
                                <img src={twitter} alt='' />
                                <p>Share on Twitter</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OpenArchivedJob;
