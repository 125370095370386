import React, { useState, useEffect } from "react";
import classes from "./ListEmplpoyedCandidates.module.css";
// import TopNavBar from "../../Components/ErrorModal/TopNavBar/TopNavBar";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import cancel from "../../Assets/cancel.svg";
import job from "../../Assets/job.svg";
import download from "../../Assets/download.svg";
import closeX from "./CloseX.svg"

const ListEmplpoyedCandidates = ({ isOpen, onClose,userData}) => {
    const [animate, setAnimate] = useState(false);

    const downloadCV = () => {
        if (!userData?.resume) {
          console.log("CV not available for download.");
          return;
        }
        const fileName = `${userData.first_name}_${userData.other_name}_CV.pdf`;
    
        const completeURL = userData.resume.startsWith("http") 
        ? userData.resume 
        : `https://hr-api.emas.ng${userData.resume}`;
    
        const link = document.createElement("a");
        link.href = completeURL;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    const downloadCover= () => {
        if (!userData?.cover_letter) {
          console.log("CV not available for download.");
          return;
        }
        const fileName = `${userData.first_name}_${userData.other_name}_CV.pdf`;
    
        const completeURL = userData.resume.startsWith("http") 
        ? userData.resume 
        : `https://hr-api.emas.ng${userData.cover_letter

        }`;
    
        const link = document.createElement("a");
        link.href = completeURL;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    useEffect(() => {
        if (isOpen) {
            // Delay to give a smooth entrance when the modal opens
            const timer = setTimeout(() => {
                setAnimate(true);
            }, 500); // Adjust delay as needed

            return () => clearTimeout(timer);
        } else {
            setAnimate(false); // Reset animation when the modal is closed
        }
    }, [isOpen]);

    if (!isOpen) return null;
    return (
        <>
            <div className={classes.modalBackdrop}>
                <div className={classes.modalRequest}>
                    <div className={classes.divModalTop}>
                        <h4 className={classes.requet}>Employed Candidates</h4>
                        <img src={cancel} alt="cancel" onClick={onClose} />
                    </div>

                    <h4 className={classes.jobText}>Job Summary</h4>
                    <div className={classes.divInfo}>
                        <div className={classes.sub1}>
                            <div className={classes.jDiv}>
                                <img src={job} alt="job" />
                                <p className={classes.textAcct}>{userData?.job?.title}</p>
                            </div>
                            <div className={classes.jDiv}>
                                <img src={job} alt="job" />
                                <p className={classes.textAcct}>{userData?.job?.job_type}</p>
                            </div>
                            <div className={classes.jDiv}>
                                <img src={job} alt="job" />
                                <p className={classes.textAcct}>{userData?.job?.experience} years of experience required</p>
                            </div>
                            <div className={classes.jDiv}>
                                <img src={job} alt="job" />
                                <p className={classes.textAcct}>Estimated pay for the role - N{userData?.job?.salary_range_from} to N{userData?.job?.salary_range_to}</p>
                            </div>
                            <div className={classes.jDiv}>
                                <img src={job} alt="job" />
                                <p className={classes.textAcct}>Start date: {userData?.job?.start_date} - End date: {userData?.job?.end_date}</p>
                            </div>
                            <div className={classes.jDiv}>
                                <img src={job} alt="job" />
                                <p className={classes.textAcct}>Number to be hired:{userData?.job?.vacancies}</p>
                            </div>
                        </div>
                    </div>

                    <h4 className={classes.candOne}>Candidate</h4>
                    <div className={classes.secondDiv}>
                        <div className={classes.secDiv}>
                            <div>
                                <p className={classes.firstN}>First Name</p>
                                <h4 className={classes.firstPc}>{userData?.first_name}</h4>
                            </div>
                            <div>
                                <p className={classes.firstN}>Last Name</p>
                                <h4 className={classes.firstPc}>{userData?.other_name}</h4>
                            </div>
                            <div>
                                <p className={classes.firstN}>Email address</p>
                                <h4 className={classes.firstPc}>{userData?.email}</h4>
                            </div>
                            <div>
                                <p className={classes.firstN}>Phone number</p>
                                <h4 className={classes.firstPc}>{userData?.phone_number}</h4>
                            </div>

                        </div>


                        <div className={classes.btnStyT}>
                            <div className={classes.shrtTop}>
                                <p className={classes.firstN}>Status</p>
                                <p className={classes.firstEmployed}>Employed</p>
                            </div>
                            <div className={classes.syDwn}>
                            <p className={classes.downloadTx} onClick={downloadCV} style={{ cursor: 'pointer' }}>
            Download CV <img src={download} alt="download" />
          </p>
                        <p className={classes.downloadTx} onClick={downloadCover} style={{ cursor: 'pointer' }}>
                        Download Cover Letter <img src={download} alt="download" />
          </p>
                
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default ListEmplpoyedCandidates;
