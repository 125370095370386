import React, { useState, useEffect } from "react";
import classes from "./ProfileInformation.module.css";

import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import { Link, useNavigate } from "react-router-dom";

import edit from '../../Assets/pedit.svg'


const ProfileInformation = () => {


  return (
    <>

      <TopNavBar />
      <div className={classes.toTUpper}>
        <h4 className={classes.topperText}>Profile</h4>
        
      <div className={classes.topLeave}>
        <div>
          <div className={classes.topTextDiv}>
            <h4 className={classes.topText}>Profile Information</h4>
            <button  className={classes.editBtn}><img src={edit} alt='edit profile' style={{marginRight:10}}/>Edit Profile</button>
          </div>

          <div style={{height:'1px',width:'107%',border:'1px solid #E9ECEF',marginLeft:'-39px',marginBottom:60}}></div>

          <div>
            <div >
              <h4 className={classes.topTitle}>Organization Information</h4>
              <div className={classes.divText}>
                <div style={{width:'40%'}}>
                  <p className={classes.orgzText}>Organization Name</p>
                  <h4 className={classes.orgzContent}>Lancaster Trust Limited</h4>
                </div>
                <div style={{width:'40%'}}>
                  <p className={classes.orgzText}>Organization Size</p>
                  <h4 className={classes.orgzContent}>21 to 50 members</h4>
                </div>
                <div style={{width:'20%'}}>
                  <p className={classes.orgzText}></p>
                  <h4 className={classes.orgzContent}></h4>
                </div>
                <div style={{width:'40%'}}>
                  <p className={classes.orgzText}>Organization Address</p>
                  <h4 className={classes.orgzContent1}>Lekki Phase 1, Lagos Island, Lagos</h4>
                </div>
              </div>
            </div>
            <div >
              <h4 className={classes.topTitle}>Personal Information</h4>
              <div className={classes.divText}>
                <div style={{width:'40%'}}>
                  <p className={classes.orgzText}>Name</p>
                  <h4 className={classes.orgzContent}>Stephen Ibukun</h4>
                </div>
                <div style={{width:'40%'}}>
                  <p className={classes.orgzText}>Phone number</p>
                  <h4 className={classes.orgzContent}>09010090002</h4>
                </div>
                <div style={{width:'20%'}}>
                  <p className={classes.orgzText}></p>
                  <h4 className={classes.orgzContent}></h4>
                </div>
                <div style={{width:'40%'}}>
                  <p className={classes.orgzText}>Date of birth</p>
                  <h4 className={classes.orgzContent}>21/01/1990</h4>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default ProfileInformation;
