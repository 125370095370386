import React, { createContext, useState } from 'react';

// Create a context for the form data
export const FormContext = createContext();

// Create a provider component
export const FormProvider = ({ children }) => {
    const [formData, setFormData] = useState({
        name: '',
        organization_size: '',
        street_address: '',
        city: '',
        state: '',
        first_name: '',
        last_name: '',
        date_of_establishment: '',
        phone_number: ''
    });
    console.log(FormData);

    return (
        <FormContext.Provider value={{ formData, setFormData }}>
            {children}
        </FormContext.Provider>
    );
};
