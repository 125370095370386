import React, { useState, useEffect } from 'react';
import classes from "./TerminatedEmployees.module.css";
import closeX from "./CloseX.svg";
import axios from 'axios';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useParams } from "react-router-dom";

const TerminatedEmployees = ({ isOpen, onClose, employeeId }) => {
  const [animate, setAnimate] = useState(false);
  const [employeeProfile, setEmployeeProfile] = useState([]);
  const [bearer, setBearer] = useState("");
  const [error, setError] = useState(false);

  // Fetch token from AsyncStorage
  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("tokens");
      if (value !== null) {
        setBearer(value);
      }
    } catch (e) {
      alert("Failed to fetch the input from storage");
    }
  };

  // Fetch employee details from API
  const fetchEmployeeProfile = async () => {
    if (!bearer || !employeeId) return;

    try {
      const response = await axios.get(
        `https://hr-api.emas.ng/api/exit-management/show-exit-management/${employeeId}`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${bearer}`,
          }
        }
      );
      setEmployeeProfile(response.data.data[0]); // Store employee data
      console.log('Employee data', response.data);
    } catch (error) {
      console.log("Error fetching Employee Profile", error);
      setError(true);
    }
  };

  useEffect(() => {
    readData();
  }, []);

  useEffect(() => {
    if (bearer && employeeId) {
      fetchEmployeeProfile();
    }
  }, [bearer, employeeId]);

  useEffect(() => {
    if (isOpen) {
      // Delay to give a smooth entrance when the modal opens
      const timer = setTimeout(() => {
        setAnimate(true);
      }, 500); // Adjust delay as needed

      return () => clearTimeout(timer);
    } else {
      setAnimate(false); // Reset animation when the modal is closed
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div>
      <div className={classes.modalBackdrop}>
        <div className={classes.modalRequest}>
          <div className={classes.divModalTop}>
            <h4 className={classes.requet}>
              Terminated Employees
            </h4>
            <img
              src={closeX}
              alt="cancel"
              onClick={onClose}
              style={{ cursor: 'pointer' }}
            />
          </div>

          <div className={classes.emyInDiv}>
            <h4 className={classes.empyInfo}>Employee Information</h4>
            <div className={classes.divDesc}>
              <div>
                <p className={classes.name}>Name</p>
                <h4 className={classes.nameVal}>
                  {employeeProfile.employee?.first_name} {employeeProfile.employee?.last_name}
                </h4>
              </div>
              <div>
                <p className={classes.name}>Department</p>
                <h4 className={classes.nameVal}>
                {employeeProfile.employee?.department?.name || 'N/A'}

                </h4>
              </div>
            </div>

            <div className={classes.divDesc}>
              <div>
                <p className={classes.name}>Designation</p>
                <h4 className={classes.nameVal}>
                  {employeeProfile.employee?.designation_id}
                </h4>
              </div>
              <div>
                <p className={classes.name}>Email Address</p>
                <h4 className={classes.nameVal}>
                  {employeeProfile.employee?.email}
                </h4>
              </div>
            </div>
            <div className={classes.divDesc}>
              <div>
                <p className={classes.name}>Phone Number</p>
                <h4 className={classes.nameVal}>
                  {employeeProfile.employee?.phone_number}
                </h4>
              </div>
            </div>
          </div>

          <div className={classes.emyInDiv}>
            <h4 className={classes.empyInfo}>Exit Date</h4>
            <div className={classes.divDesc}>
              <div>
                <p className={classes.name}>Termination date</p>
                <h4 className={classes.nameVal}>
                  {employeeProfile?.exit_date}
                </h4>
              </div>
              <div>
                <p className={classes.name}>Notice date</p>
                <h4 className={classes.nameVal}>
                {employeeProfile?.notice_date}
                </h4>
              </div>
            </div>
          </div>

          <div className={classes.emyInDiv}>
            <h4 className={classes.empyInfo}>Reason</h4>
            <div className={classes.divDescFlexClmn}>
              <div>
                <p className={classes.name}>Termination Type</p>
                <h4 className={classes.nameVal}>
                {employeeProfile?.termination_type}
                </h4>
              </div>
              <div>
                <p className={classes.name}>Reason For Termination</p>
                <h4 className={classes.nameValSpec}>
                {employeeProfile?.reason_for_termination}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default TerminatedEmployees
