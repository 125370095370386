import React from "react";
import classes from "./PayrollModalReceipt.module.css";
import image1 from "../../Assets/Ellipse 1 (1).png";
import image2 from "../../Assets/Ellipse 1.png";
import approve from "../../Assets/approve.svg";
import decline from "../../Assets/decline.svg";
import view from "../../Assets/view.svg";
import less from "../../Assets/less.svg";
import next from "../../Assets/next.svg";
import plus from "../../Assets/plus.svg";
// import TopNavBar from "../../Components/ErrorModal/TopNavBar/TopNavBar";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import cancel from "../../Assets/cancel.svg";
import promix from "../../Assets/promix.svg";

const PayrollModalReceipt = () => {
  return (
    <>
      <div className={classes.modalBackdrop}>
        <div className={classes.modalRequest}>
          <div className={classes.divModalTop}>
            {/* <h4 className={classes.requet}>Lauren Annabelle</h4> */}
            <img src={cancel} alt="cancel" />
          </div>
          <div>
            <div className={classes.promixImg}>
              <img src={promix} alt="promix" />
            </div>
            <div className={classes.topDivt}>
              <h2 className={classes.payText}>PAYSLIP</h2>
              <h4 className={classes.pTop}>PROMIX ACCOUNTING LIMITED</h4>
              <p className={classes.prg}>
                No 54, Olusegun Osoba Way, Oke-Ilewo, Abeokuta, Ogun State,
                Nigeria.
              </p>
            </div>

            <div>
              <h4 className={classes.lName}>LAUREN ANNABELLE</h4>
              <p className={classes.pN}>Art Director</p>
              <p className={classes.pN}>Print Media</p>
              <p className={classes.pN}>Joined 1st January, 2024</p>
              <p className={classes.pN}>
                Pay period: 1st July to 31st of July, 2024
              </p>
            </div>
          </div>
          <div className={classes.flexTable}>
            <div className={classes.tableDivOne}>
              <table className={classes.tableOne}>
                <tr className={classes.topHeadOne}>
                  <th className={classes.topSubOne}>Earnings</th>
                  <th className={classes.topSubOne}> </th>
                </tr>
                <tr className={classes.topBodyOne}>
                  <td>Basic Salary</td>
                  <td>N500,000</td>
                </tr>
                <tr className={classes.topBodyOne}>
                  <td>House Allowance</td>
                  <td>N100,000</td>
                </tr>
                <tr className={classes.topBodyOne}>
                  <td>Other Allowance</td>
                  <td>N100,000</td>
                </tr>
                <tr className={classes.topBodyOne}>
                  <td>Total Earnings</td>
                  <td>N800,000</td>
                </tr>
              </table>
            </div>
            <div className={classes.tableDivOne}>
              <table className={classes.tableOne}>
                <tr className={classes.topHeadOne}>
                  <th className={classes.topSubOne}>Deductions</th>
                  <th className={classes.topSubOne}> </th>
                </tr>
                <tr className={classes.topBodyOne}>
                  <td>Pension</td>
                  <td>N10,000</td>
                </tr>
                <tr className={classes.topBodyOne}>
                  <td>PAYE Tax</td>
                  <td>N50,000</td>
                </tr>
                <tr className={classes.topBodyOne}>
                  <td>Loan</td>
                  <td>0</td>
                </tr>
                <tr className={classes.topBodyOne}>
                  <td>Total deductions</td>
                  <td>N60,000</td>
                </tr>
              </table>
            </div>
          </div>
            <h4 className={classes.netSal}>Net Salary: N740,000 <span className={classes.netSpan}>(Seven hundred and forty thousand naira)</span></h4>
        </div>
      </div>
      {/* <TopNavBar /> */}
      <div className={classes.toTUpper}>
        <h4 className={classes.topperText}>Leave Management</h4>
        <div className={classes.firstBtn}>
          <button className={classes.policyBtn}>Create Leave Policy</button>
          <button className={classes.addBtn}>
            <img src={plus} alt="plus" />
            Add Leave
          </button>
        </div>
        <div className={classes.topt}>
          <p>Leave Request</p>
          <p>Approved</p>
          <p>Declined</p>
          <p>Leave Policy</p>
        </div>
        <div className={classes.topLeave}>
          <div>
            <div className={classes.topTextDiv}>
              <h4 className={classes.topText}>Leave Request</h4>
            </div>

            <table className={classes.tableDiv}>
              <tr className={classes.tableHeadDiv}>
                <th className={classes.tableHeadRow}>S/N</th>
                <th className={classes.tableHeadRow}>Employee Name</th>
                <th className={classes.tableHeadRow}>Designation</th>
                <th className={classes.tableHeadRow}>Start date</th>
                <th className={classes.tableHeadRow}>End date</th>
                <th className={classes.tableHeadRow}>Status</th>
                <th className={classes.tableHeadRow}>View</th>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>1</td>
                <td className={classes.pflDiv}>
                  <img src={image1} alt="one" />
                  Matthew Olowade
                </td>
                <td>Media Project Manager </td>
                <td>21/08/2024</td>
                <td>25/08/2024</td>
                <td>
                  <p className={classes.approvedDiv}>Appproved</p>
                </td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>2</td>
                <td className={classes.pflDiv}>
                  <img src={image2} alt="two" />
                  Lauren Annabelle
                </td>
                <td>Art Director </td>
                <td>27/08/2024</td>
                <td>28/08/2024</td>
                <td>
                  <p className={classes.approvedDiv}>Appproved</p>
                </td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>1</td>
                <td className={classes.pflDiv}>
                  <img src={image1} alt="one" />
                  Matthew Olowade
                </td>
                <td>Media Project Manager </td>
                <td>21/08/2024</td>
                <td>25/08/2024</td>
                <td>
                  <p className={classes.approvedDiv}>Appproved</p>
                </td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>2</td>
                <td className={classes.pflDiv}>
                  <img src={image2} alt="two" />
                  Lauren Annabelle
                </td>
                <td>Art Director </td>
                <td>27/08/2024</td>
                <td>28/08/2024</td>
                <td className={classes.btnSet}>
                  <p className={classes.approvedDiv}>Appproved</p>
                </td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
            </table>
            <div className={classes.gotoNext}>
              <button className={classes.prev}>
                <img src={less} alt="less" />
                Previous
              </button>
              <p className={classes.pagination}>Page 1 of 5</p>
              <button className={classes.next}>
                Next
                <img src={next} alt="next" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayrollModalReceipt;
