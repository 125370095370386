import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import classes from "./ManageProfile.module.css";
import back from "../../Assets/back.svg";
import employeeimg from "../../Assets/EmployeeImg.png";
import { Link } from "react-router-dom";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import plus from '../../Assets/editingg.svg'

const ManageProfile = () => {

  return (
    <div className={classes.cover_contain}>
      <TopNavBar />
      <div className={classes.covering_container}>
        <p className={classes.paraMargWeight}>Profile</p>
        <div className={classes.containerCovercontain}>
         
            <div className={classes.navigateBack}>
              <p className={classes.nameGroupType}>
                Profile Information
              </p>
              < Link to='/edit_profile'>
              <button className={classes.stylbtntransparent}><img src={plus} alt=""/><p>Edit Profile</p></button>
              </Link>
            </div>

            <div style={{ width: '100%', border: '1px solid #E9ECEF', }}></div>
          
          
            <div className={classes.adjust_Width}>
                <div className={classes.movetoghdiv}>
              <p className={classes.myProfileprofile}>Organization Information</p>
              <div className={classes.headingPersonal}>
                <div className={classes.headingPersonalDetails}>
                  <div className={classes.designOne}>
                    <p className={classes.para_Desgn}>Organization Name</p>
                    <p className={classes.para_design}>
                    Lancaster Trust Limited
                    </p>
                  </div>
                  <div className={classes.designTwo}>
                    <p className={classes.para_Desgn}>Organization Size</p>
                    <p className={classes.para_design}>
                    21 to 50 members
                    </p>
                  </div>
                  <div className={classes.designThree}>
                    <p className={classes.para_Desgn}>Organization Address</p>
                    <p className={classes.para_design123}>
                    Lekki Phase 1, Lagos Island, Lagos
                    </p>
                  </div>
                </div>
              </div>
            </div>


                <div className={classes.movetoghdiv}>
              <p className={classes.myProfileprofile}>Personal Information</p>
              <div className={classes.headingPersonal}>
                <div className={classes.headingPersonalDetails}>
                  <div className={classes.designOne}>
                    <p className={classes.para_Desgn}>Name</p>
                    <p className={classes.para_design}>
                    Stephen Ibukun
                    </p>
                  </div>
                  <div className={classes.designTwo}>
                    <p className={classes.para_Desgn}>Phone Number</p>
                    <p className={classes.para_design}>
                    09010090002
                    </p>
                  </div>
                  <div className={classes.designThree}>
                    <p className={classes.para_Desgn}>Date of birth</p>
                    <p className={classes.para_design}>
                    21/01/1990
                    </p>
                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
            </div>
            </div>
         
  );
};

export default ManageProfile;
