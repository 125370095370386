import React, { useState, useEffect } from 'react';
import classes from '../ErrorModal/ErrorModal.module.css';
import { IoWarningOutline } from "react-icons/io5";

const ErrorModal = ({ isOpen, onClose }) => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (isOpen) {
            // Delay to give a smooth entrance when the modal opens
            const timer = setTimeout(() => {
                setAnimate(true);
            }, 500); // Adjust delay as needed

            return () => clearTimeout(timer);
        } else {
            setAnimate(false); // Reset animation when the modal is closed
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className={`${classes.modalOverlay} ${classes.slideInRight}`}>
            <div className={classes.modalErr}>
                <button className={classes.closeButtonModal} onClick={onClose}>
                    &times;
                </button>
                <div className={classes.modalContent}>
                    <IoWarningOutline style={{fontSize: 35}} />
                    <p>Login Unsuccessful <br />Check your details and try again
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ErrorModal;
