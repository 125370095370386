import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ToastContainer, toast } from 'react-toastify';
import classes from './EditEmpSalaryModal.module.css';
import closeX from './CloseX.svg';
import addNew from '../../Assets/addNew.svg';
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

const EditEmpSalaryModal = ({ isOpen, onClose, payrollId, onPayrollUpdated }) => {
    const [bearer, setBearer] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        employee: null,
        department: null,
        designation: null,
        salary: '',
        startDate: '',
        endDate: '',
        payDate: '',
        houseAllowance: '',
        transportAllowance: '',
        tax: '',
        pension: '',
        loan: '',
        bonus: '',
        otherDeductions: '',
    });

    const [departments, setDepartments] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [allowances, setAllowances] = useState([]);
    const [deductions, setDeductions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [startDate,setStartDate] = useState('');
    const [endDate,setEndDate] = useState('');
    const [payDate,setPayDate] = useState('');
    const [basicSalary,setBasicSalary] = useState('');
    const [houseAllce,setHouseAllce] = useState('');
    const [transAllce,settransAllce] = useState('');
    const [pension,setPension] = useState('');
    const [tax,setTax] = useState('');
    const [loan,setLoan] = useState('');
    const [employeeId,setEmployeeId] = useState('')
    const [departmentID,setDepartmentID] = useState('')
    const [designationId,setDesignationID] = useState('')

    // useEffect(() => {
    //     if (isOpen) {
    //         // Delay to give a smooth entrance when the modal opens
    //         const timer = setTimeout(() => {
    //             setAnimate(true);
    //         }, 500); // Adjust delay as needed

    //         return () => clearTimeout(timer);
    //     } else {
    //         setAnimate(false); // Reset animation when the modal is closed
    //     }
    // }, [isOpen]);

    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('tokens');
            if (value) {
                setBearer(value);
            } else {
                throw new Error('Failed to fetch token. Please login again.');
            }
        } catch (e) {
            setError(e.message);
        }
    };

    
 const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

//   const fetchBeneficiaries = async () => {
//     setIsLoading(true);
//     try {
//       const response = await axios.get(`${BASE_URL}/payroll/fetchByID?id=${payId}`, { headers });
//       const results = response.data?.data[0];
//     //   setTableData(results.leave);
//       console.log(results)
//       setUserData(results)
//       setStartDate(results?.start_date)
//       setEndDate(results?.end_date)
//       setBasicSalary(results?.salary)
//       setHouseAllce(results?.house_allowance)
//       settransAllce(results?.transport_allowance)
//       setPension(results?.pension)
//       setTax(results?.tax)
//       setLoan(results?.loan)
//       setEmployeeId(results?.employee_id)
//       setDepartmentID(results?.department_id)
//       setDesignationID(results?.designation_id)
//       setPayDate(results?.pay_date)
      
      // console.log(results.leave)
      // console.log(results);
    // } catch (error) {
    //   if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
//         navigate('/login');
//       } else {
//       const errorStatus = error.response?.data?.message;
//       console.log(errorStatus);
//       setUserData([]);
//     }
//     } finally {
//       setIsLoading(false);
//     }
//   };

  const fetchPayroolId = async () => {
    console.log('submited')

    setIsLoading(true);

    // const data = {
    //         "id": `${payId}`,
    //         "employee_id": `${employeeId}`,
    //         "department_id": `${departmentID}`,
    //         "designation_id": `${designationId}`,
    //         "salary": basicSalary,
    //         "start_date": startDate,
    //         "end_date": endDate,
    //         "pay_date": payDate,
    //         "house_allowance":houseAllce,
    //         "transport_allowance": transAllce,
    //         "tax": tax,
    //         "pension": pension,
    //         "loan": loan
    // };



//     try {
//       const response = await axios.post(
//         `${BASE_URL}/payroll/update`,
//         data,
//         { headers }
//       );
//       const results = response.data?.data;
//       onClose()
//     //   navigate('/education')
//     } catch (error) {
//         // console.log(error)
//       console.log(error?.response?.data?.message)
//       Object.entries(error?.response?.data?.message).forEach(([key,value]) => toast.error(value[0]) )
//       if (error.response && error.response.status === 401) {
//         // Redirect to login page if unauthorized
//         navigate("/login");
//       } else {
//         const errorStatus = error.response?.data?.message;
//        toast.error(errorStatus);
//         // setUserData([]);
//       }
//     } finally {
//       setIsLoading(false);
      
//     }
//   };

//   useEffect(() => {

//     readData();
//     }, []);
  
  
//   useEffect(() => {
  
//     if (bearer) {
//       fetchBeneficiaries();
  
//     }
//   }, [bearer,isOpen,onClose]);
    

    const handleAddAllowance = () => {
        const newAllowance = { key: Date.now(), value: '' }; // Unique key for each allowance
        setAllowances(prevAllowances => [...prevAllowances, newAllowance]);
    };
    
    const handleDeleteLastAllowance = () => {
        setAllowances(prevAllowances => prevAllowances.slice(0, -1)); // Remove the last allowance
    };
    
    const handleAddDeduction = () => {
        const newDeduction = { key: Date.now(), value: '' }; // Unique key for each deduction
        setDeductions(prevDeductions => [...prevDeductions, newDeduction]);
    };
    
    const handleDeleteLastDeduction = () => {
        setDeductions(prevDeductions => prevDeductions.slice(0, -1)); // Remove the last deduction
    };
    
    // const fetchBeneficiaries = async () => {
    //     setLoading(true);
    //     try {
    //         const response = await axios.get(`https://hr-api.emas.ng/api/payroll/fetchByID?id=${payrollId}`, {
    //             headers: { Authorization: `Bearer ${bearer}` }
    //         });
    //         const results = response.data?.data[0];
    //         setFormData(prevState => ({ ...prevState, ...results }));
    //     } catch (error) {
    //         handleError(error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    // const handleError = (error) => {
    //     if (error.response && error.response.status === 401) {
    //         navigate('/login');
    //     } else {
    //         const errorStatus = error.response?.data?.message;
    //         toast.error(errorStatus || 'An error occurred');
    //     }
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
        setError('');
    };

    const handleSelectChange = (selectedOption, { name }) => {
        setFormData(prevState => ({ ...prevState, [name]: selectedOption }));
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setLoading(true);

    //     try {
    //         await axios.post('https://hr-api.emas.ng/api/payroll/update', formData, {
    //             headers: {
    //                 Authorization: `Bearer ${bearer}`,
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    //         onPayrollUpdated();
    //         toast.success('Payroll updated successfully!');
    //         onClose();
    //     } catch (error) {
    //         handleError(error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    // useEffect(() => {
    //     if (isOpen && bearer) {
    //         fetchBeneficiaries();
    //     }
    // }, [isOpen, bearer]);

    if (!isOpen) return null;
    return (
        <div className={classes.modalOverlayWhole}>
            {
                isLoading ? 'Loading...' : (<div className={classes.modalErrYes}>
                    <button className={classes.closeButtonModally} onClick={onClose}>
                        <img src={closeX} alt='' />
                    </button>
                    {/* <form onSubmit={handleSubmit} className={classes.modalContainer22}> */}
                        <h6>Edit Employee Salary</h6>
                        
                        {/* Employee Information */}
    
                        <h5>Employee information</h5>
                        <div className={classes.classGroupCont}>
                            <div className={classes.labelandInput}>
                                <label>Select Employee</label>
                                {/* <select>
                                    <option>Kunle Afolayan</option>
                                </select> */}
                                <input value={`${userData?.employee?.first_name} ${userData?.employee?.last_name}` || ''}/>
                            </div>
                            <div className={classes.labelandInput}>
                                <label>Select Department</label>
                                {/* <select>
                                    <option>Digital Media</option>
                                </select> */}
                                <input value={userData?.department?.name}/>
                            </div>
                            <div className={classes.labelandInput}>
                                <label>Select Designation</label>
                                {/* <select>
                                    <option>Graphic Artist</option>
                                </select> */}
                                <input value={userData?.designation?.title}/>
                            </div>
                        </div>
                        
                        {/* Payslip Date Range */}
                        <h5>Payslip date range</h5>
                        <div className={classes.classGroupCont}>
                            <div className={classes.labelandInput}>
                                <label htmlFor='startDate'>Start date</label>
                                <input type='date' id='startDate' value ={startDate} onChange={(e) => setStartDate(e.target.value) }required />
                            </div>
                            <div className={classes.labelandInput}>
                                <label htmlFor='endDate'>End date</label>
                                <input type='date' id='endDate' value={endDate} onChange={(e) => setEndDate(e.target.value) } required />
                            </div>
                        </div>
                        
                        {/* Earnings Section */}
                        <h5>Earnings</h5>
                        <div className={classes.classGroupCont}>
                            <div className={classes.labelandInput}>
                                <label>Enter Basic Salary</label>
                                <input type='text' placeholder='500,000' value={basicSalary} onChange={(e) => setBasicSalary(e.target.value)} required />
                            </div>
                            <div className={classes.labelandInput}>
                                <label>House Allowance</label>
                                <input type='text' placeholder='100,000' value={houseAllce} onChange={(e) => setHouseAllce(e.target.value) } required />
                            </div>
                            <div className={classes.labelandInput}>
                                <label>Transport Allowance</label>
                                <input type='text' placeholder='100,000' value={transAllce} onChange={(e) => settransAllce(e.target.value) } required />
                            </div>
    
                            {/* Dynamically Added Allowances */}
                            {/* {allowances.map((allowance, index) => (
                                <div key={allowance.key} className={classes.labelandInput}>
                                    <label>Other Allowance {index + 1}</label>
                                    <input
                                        type='text'
                                        placeholder='100,000'
                                        required
                                        value={allowance.value}
                                        onChange={(e) =>
                                            setAllowances(
                                                allowances.map((a) =>
                                                    a.key === allowance.key
                                                        ? { ...a, value: e.target.value }
                                                        : a
                                                )
                                            )
                                        }
                                    />
                                </div>
                            ))}
                            <a className={classes.addNewdiv} onClick={handleAddAllowance}>
                                <img src={addNew} alt="Add New" /> Add New allowance
                            </a>
                            {allowances.length > 0 && (
                                <a className={classes.addNewdiv} onClick={handleDeleteLastAllowance}>
                                    <img src={addNew} alt="Delete Last" /> Delete Last allowance
                                </a>
                            )} */}
                        </div>
                        
                        {/* Deductions Section */}
                        <h5>Deductions</h5>
                        <div className={classes.classGroupCont}>
                            <div className={classes.labelandInput}>
                                <label>PAY tax</label>
                                <input type='text' placeholder='50,000' value={tax} onChange={(e) => setTax(e.target.value) } required />
                            </div>
                            <div className={classes.labelandInput}>
                                <label>Pension</label>
                                <input type='text' placeholder='10,000' value={pension} onChange={(e) => setPension(e.target.value) } required />
                            </div>
                            <div className={classes.labelandInput}>
                                <label>Loan</label>
                                <input type='text' placeholder='0' value={loan} onChange={(e) => setLoan(e.target.value) } required />
                            </div>
    
                            {/* Dynamically Added Deductions */}
                            {/* {deductions.map((deduction, index) => (
                                <div key={deduction.key} className={classes.labelandInput}>
                                    <label>Other Deduction {index + 1}</label>
                                    <input
                                        type='text'
                                        placeholder='0'
                                        required
                                        value={deduction.value}
                                        onChange={(e) =>
                                            setDeductions(
                                                deductions.map((d) =>
                                                    d.key === deduction.key
                                                        ? { ...d, value: e.target.value }
                                                        : d
                                                )
                                            )
                                        }
                                    />
                                </div>
                            ))}
                            <a className={classes.addNewdiv} onClick={handleAddDeduction}>
                                <img src={addNew} alt="Add New" /> Add New deduction
                            </a>
                            {deductions.length > 0 && (
                                <a className={classes.addNewdiv} onClick={handleDeleteLastDeduction}>
                                    <img src={addNew} alt="Delete Last" /> Delete Last deduction
                                </a>
                            )} */}
                        </div>
                        <h5>Pay Date</h5>
          <div className={classes.classGroupCont}>
            <div className={classes.labelandInput}>
              <label>Pay Date</label>
              <input
                type="date"
                name="payDate"
                value={payDate}
                onChange={(e) => setPayDate(e.target.value) }
                required
              />
            </div>
          </div>
                        
                        <button type='submit' className={classes.addTeamBtn} onClick={fetchPayroolId}>
                            {isLoading ? 'Uploading' : 'Save Changes'}
                        </button>
                    {/* </form> */}
                </div>)
            }
            
        </div>

    );
};
}

export default EditEmpSalaryModal;
