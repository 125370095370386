import React, { useState, useEffect } from 'react';
import classes from './JobApplicationModal.module.css';
import closeX from './CloseX.svg';
import addNew from '../../Assets/addNew.svg';
import attach from '../../Assets/attachfile.svg'
import axios from 'axios';
import Select from 'react-select';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL } from '../../Pages/api/api';
import { Link, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const JobApplicationModal = ({ isOpen, onClose }) => {
    const navigate = useNavigate();
    // const [animate, setAnimate] = useState(false);
    const [allowances, setAllowances] = useState([]);
    // const [deductions, setDeductions] = useState([]);
    const [filename, setFileName] = useState("Attach your resume/CV");
    const [filename1, setFileName1] = useState("Attach your cover letter");
    const [departments, setDepartments] = useState([]);
    // // const [options1, setOptions1] = useState([]);
    const [bearer, setBearer] = useState('');
    const [loading, setIsLoading] = useState(false);
    const [employeeDetails, setEmployeeDetails] = useState([])
    const [jobTitle,setJobTitle] = useState('')
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [paySlipDate,setPaySlipDate] = useState('')
    const [payEndDate,setPayEndDate] = useState('')
    const [workType,setWorkType] = useState('')
    const [jobType,setJobType] = useState('')
    const [vacancy,setVacancy] = useState('')
    const [experience,setExperience] = useState('')
    const [location,setLocation] = useState('')
    const [ageRange,setAgeRange] = useState('')
    // const [location,setLocation] = useState('')
    const [salaryFrom,setSalaryFrom] = useState('')
    const [salaryTo,setSalaryTo] = useState('')
    const [jobStart,setJobStart] = useState('')
    const [jobEnd,setJobEnd] = useState('')
    const [description,setDescription] = useState('')



    useEffect(() => {
        const fetchDepartments = async () => {
            if (!bearer) return; // Wait for the token to be set
            try {
                const response = await axios.get('https://hr-api.emas.ng/api/department/fetchAll', {
                    headers: {
                        Authorization: `Bearer ${bearer}`,
                    },
                });
                const list = response.data.data;
                setDepartments(list.map(item => ({
                    label: item.name,
                    value: item.id,
                })));

            } catch (error) {
                // console.error('Error fetching departments:', error);
            }
            finally{
                // console.log(departments)
            }
        };
        fetchDepartments();
    }, [bearer]);

    const handleApplicantChange = (e, index) => {
        const updatedApplicants = [...employeeDetails];
        
        if (e.target.type === 'file') {
            const file = e.target.files[0];
            if (file && file.type === 'application/pdf') {
                updatedApplicants[index][e.target.name] = file;
                // Also update the filename in the applicant object
                updatedApplicants[index][`${e.target.name}_filename`] = file.name;
            } else {
                toast.error('Please upload a PDF file.');
                return;
            }
        } else {
            updatedApplicants[index][e.target.name] = e.target.value;
        }
        
        setEmployeeDetails(updatedApplicants);
    };
   

    const handleFileChange = (e, index, name) => {
        const file = e.target.files[0];
        if (file && file.type === 'application/pdf') {
            const updatedApplicants = [...employeeDetails];
            updatedApplicants[index][name] = file;
            setEmployeeDetails(updatedApplicants);
            if (name === 'cv') setFileName(file.name);
            if (name === 'cover_letter') setFileName1(file.name);
        } else {
            alert('Please upload a PDF file.');
        }
    };

    const addNewApplicant = () => {
        setEmployeeDetails([...employeeDetails, {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            resume: null,
            resume_filename: 'Attach your resume/CV',
            cover_letter: null,
            cover_letter_filename: 'Attach your cover letter'
        }]);
    };

    const deleteLastApplicant = () => {
        if (employeeDetails.length > 1) {
            setEmployeeDetails(employeeDetails.slice(0, -1));
        }
    };


    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
      };
    
      const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('tokens');

            if (value !== null) {
                setBearer(value);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage')
        }
    };

    useEffect(() => {
        readData();
    }, []);
    
    useEffect(() => {
        if (employeeDetails.length === 0) {
            addNewApplicant();
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
    
        // Create a new FormData instance
        const formData = new FormData();
    
        // Append all the job details
        formData.append("title", jobTitle);
        formData.append("department_id", selectedDepartment);
        formData.append("start_date", jobStart);
        formData.append("end_date", jobEnd);
        formData.append("job_type", jobType);
        formData.append("vacancies", vacancy);
        formData.append("description", description);
        formData.append("location", location);
        formData.append("payslip_start_date", paySlipDate);
        formData.append("payslip_end_date", payEndDate);
        formData.append("work_type", workType);
        formData.append("experience", experience);
        formData.append("age_range", ageRange);
        formData.append("salary_range_from", salaryFrom);
        formData.append("salary_range_to", salaryTo);
    
        // Append all employee details including files
        employeeDetails.forEach((employee, index) => {
            formData.append(`first_name[${index}]`, employee.first_name);
            formData.append(`last_name[${index}]`, employee.last_name);
            formData.append(`email[${index}]`, employee.email);
            formData.append(`phone[${index}]`, employee.phone);
            
            // Handle file uploads
            if (employee.resume instanceof File) {
                formData.append(`resume[${index}]`, employee.resume);
            }
            if (employee.cover_letter instanceof File) {
                formData.append(`cover_letter[${index}]`, employee.cover_letter);
            }
        });
    
        try {
            const response = await axios.post(
                `${BASE_URL}/job/create`,
                formData,
                {
                    headers: {
                        ...headers,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            
            toast.success(response.data?.message || 'Job created successfully');
            onClose(); // Close the modal on success
            
        } catch (error) {
            if (error.response?.status === 401) {
                navigate("/login");
                return;
            }
            
            const errorMessage = error.response?.data?.message;
            if (typeof errorMessage === 'object') {
                // Handle nested error messages
                Object.entries(errorMessage).forEach(([key, value]) => {
                    if (Array.isArray(value)) {
                        value.forEach(msg => toast.error(msg));
                    } else {
                        toast.error(value);
                    }
                });
            } else {
                toast.error(errorMessage || 'An error occurred while creating the job');
            }
        } finally {
            setIsLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className={classes.modalOverlayWhole}>
            <div className={classes.modalErrYes}>
                <button className={classes.closeButtonModally} onClick={onClose}>
                    <img src={closeX} alt='' />
                </button>
                <form onSubmit={handleSubmit} className={classes.modalContainer22}>
                    <h6>Job Application</h6>

                    {/* Employee Information */}
                    <h5>Job Title</h5>
                    <div className={classes.classGroupCont}>
                        <div className={classes.labelandInput}>
                            <label>Enter Job Title</label>
                            <input
                                type='text'
                                name="title"
                                value={jobTitle}
                                onChange={(e) => setJobTitle(e.target.value)}
                                required
                                placeholder='Accountant'
                            />
                        </div>
                        <div className={classes.labelandInput}>
                            <label>Select Department</label>
                            <Select
                                name='department'
                                options={departments}
                                onChange={(selectedOption) => {
                                    setSelectedDepartment(selectedOption?.value);  
                                    // console.log(selectedOption.value);  
                                  }}
                                  value={selectedDepartment?.value}
                                required
                            />
                        </div>

                    </div>

                    {/* Payslip Date Range */}
                    <h5>Payslip date range</h5>
                    <div className={classes.classGroupCont}>
                        <div className={classes.labelandInput}>
                            <label htmlFor='startDate'>Start date</label>
                            <input type='date'
                                id='startDate'
                                name='payslip_start_date'
                                value={paySlipDate}
                                onChange={(e) => setPaySlipDate(e.target.value)}
                                required />
                        </div>
                        <div className={classes.labelandInput}>
                            <label htmlFor='endDate'>End date</label>
                            <input type='date'
                                id='endDate'
                                name='payslip_end_date'
                                value={payEndDate}
                                onChange={(e) => setPayEndDate(e.target.value)}
                                required />
                        </div>
                    </div>

                    {/* Earnings Section */}
                    <h5>Job Information</h5>
                    <div className={classes.classGroupCont}>
                        <div className={classes.labelandInput}>
                            <label>Work type</label>
                            <select
                                name="work_type"
                                value={workType}
                                onChange={(e) => setWorkType(e.target.value)}
                                required
                            >
                                <option value=''>
                                    Select work type
                                </option>
                                <option value='On-site'>
                                    On-site
                                </option>
                                <option value='Remote'>
                                    Remote
                                </option>
                                <option value='Hybrid'>
                                    Hybrid
                                </option>
                            </select>
                        </div>
                        <div className={classes.labelandInput}>
                            <label>Job type</label>
                            <select
                                name="job_type"
                                value={jobType}
                                onChange={(e) => setJobType(e.target.value)}
                                required
                            >
                                <option value=''>
                                    Select job type
                                </option>
                                <option value='Full_time'>
                                    Full-time
                                </option>
                                <option value='Part_time'>
                                    Part-time
                                </option>
                            </select>
                        </div>
                        <div className={classes.labelandInput}>
                            <label>No of Vacancies</label>
                            <input type='text' placeholder='3' required
                                name='vacancies'
                                value={vacancy}
                                onChange={(e) => setVacancy(e.target.value)} />
                        </div>
                        <div className={classes.labelandInput}>
                            <label>Years of Experiences</label>
                            <input type='text' placeholder='3'
                                value={experience}
                                onChange={(e) => setExperience(e.target.value)}
                                name='experience' required />
                        </div>
                        <div className={classes.labelandInput}>
                            <label>Job Location</label>
                            <input type='text' placeholder='Ikeja, Lagos' required
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                name='location' />
                        </div>
                        <div className={classes.labelandInput}>
                            <label>Preferred age Range</label>
                            <select value={ageRange || ''}
                                onChange={(e) => setAgeRange(e.target.value)}
                            >
                                <option value=''>
                                    Select age range
                                </option>
                                <option value='18-24'>
                                    18-24
                                </option>
                                <option value='25-34'>
                                    25-34
                                </option>
                                <option value='35-44'>
                                    35-44
                                </option>
                                <option value='45 or more'>
                                    45 or more
                                </option>

                            </select>
                        </div>
                        <div className={classes.labelandInput}>
                            <label>Salary range from</label>
                            <input type='text' placeholder='250, 000' required
                                value={salaryFrom}
                                onChange={(e)=> setSalaryFrom(e.target.value)}
                                name='salary_range_from' />
                        </div>
                        <div className={classes.labelandInput}>
                            <label>Salary range to</label>
                            <input type='text' placeholder='350, 000' required
                                value={salaryTo}
                                onChange={(e) => setSalaryTo(e.target.value)}
                                name='salary_range_to' />
                        </div>
                        <div className={classes.labelandInput}>
                            <label>Start date</label>
                            <input type='date' placeholder='' required
                                value={jobStart}
                                onChange={(e) => setJobStart(e.target.value)}
                                name='start_date' />
                        </div>
                        <div className={classes.labelandInput}>
                            <label>End date</label>
                            <input type='date' placeholder='' required
                                value={jobEnd}
                                onChange={(e) => setJobEnd(e.target.value)}
                                name='end_date' />
                        </div>


                        {/* <a className={classes.addNewdiv} >
                            <img src={addNew} alt="Add New" /> Add New
                        </a> */}
                        {/* {allowances.length > 0 && (
                            <a className={classes.addNewdiv}>
                                <img src={addNew} alt="Delete Last" /> Delete Last
                            </a>
                        )} */}
                    </div>

                    {/* Deductions Section */}
                    <h5>Job Decription</h5>
                    <div className={classes.classGroupContExe}>
                        <div className={classes.contentRewper}>
                            <h4>Enter Job Description</h4>
                            <textarea rows='13' className={classes.pTextParagraph}
                                name='description'
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder='Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur'>

                            </textarea>
                        </div>

                    </div>

                    <h5>Feilds to fill</h5>
                    {employeeDetails.map((applicant, index) => (
                        <div key={index}>
                            <h5>Field {index + 1}</h5>
                            <div className={classes.classGroupCont}>
                                <div className={classes.labelandInput}>
                                    <label htmlFor='first_name'>First Name</label>
                                    <input
                                        type='text'
                                        name="first_name"
                                        value={applicant.first_name}
                                        onChange={(e) => handleApplicantChange(e,index)}
                                        required
                                    />
                                </div>
                                <div className={classes.labelandInput}>
                                    <label htmlFor='last_name'>Last Name</label>
                                    <input
                                        type='text'
                                        name="last_name"
                                        value={applicant.last_name}
                                        onChange={(e) => handleApplicantChange(e,index)}
                                        required
                                    />
                                </div>
                                <div className={classes.labelandInput}>
                                    <label htmlFor='email'>Email</label>
                                    <input
                                        type='email'
                                        name="email"
                                        value={applicant.email}
                                        onChange={(e) => handleApplicantChange(e,index)}
                                        required
                                    />
                                </div>
                                <div className={classes.labelandInput}>
                                    <label htmlFor='phone'>Phone</label>
                                    <input
                                        type='text'
                                        name="phone"
                                        value={applicant.phone}
                                        onChange={(e) => handleApplicantChange(e,index)}
                                        required
                                    />
                                </div>
                                <div className={classes.labelandInput}>
                    <label htmlFor='cv'>CV</label>
                    <div className={classes.hiddenDiv}>
                        <p>{applicant.resume_filename || 'Attach your resume/CV'}</p>
                        <img src={attach} alt='' />
                    </div>
                    <input
                        type='file'
                        name="resume"
                        accept='application/pdf'
                        onChange={(e) => handleApplicantChange(e, index)}
                        className={classes.hidenInput}
                    />
                </div>
                <div className={classes.labelandInput}>
                    <label htmlFor='cover_letter'>Cover Letter</label>
                    <div className={classes.hiddenDiv}>
                        <p>{applicant.cover_letter_filename || 'Attach your cover letter'}</p>
                        <img src={attach} alt='' />
                    </div>
                    <input
                        type='file'
                        name="cover_letter"
                        accept='application/pdf'
                        onChange={(e) => handleApplicantChange(e, index)}
                        className={classes.hidenInput}
                    />
                </div>
                            </div>
                        </div>
                    ))}

                    {/* Add and Delete Buttons */}
                    <div className={classes.actions}>
                        <button className={classes.addNewBtnBtn} type='button' onClick={addNewApplicant}>
                            <img src={addNew} alt='Add New' /> Add New
                        </button>
                        {employeeDetails.length > 1 && (
                            <button className={classes.deleteNewBtnBtn} type='button' onClick={deleteLastApplicant}>
                                <img src={addNew} alt='Delete Last' /> Delete Last
                            </button>
                        )}
                    </div>


                    <button type='submit' className={classes.addTeamBtn} disabled={loading}>
                        {loading ? 'Creating...' : 'Create Job Application'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default JobApplicationModal;
