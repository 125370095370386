import React, { useState, useEffect } from 'react';
import classes from './candidateShortlist.module.css';
import { IoWarningOutline } from "react-icons/io5";
import closeX from './CloseX.svg'
import successPayslip from '../../Assets/payslipSuccess.svg'
import { BASE_URL } from '../../Pages/api/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';


const CandidateShortlist = ({ isOpen, onClose,userId }) => {
    const [animate, setAnimate] = useState(false);


    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
  
  
    const [isModalOpen1, setIsModalOpen1] = useState(false);
  
    const openModal1 = () => setIsModalOpen1(true);
    const closeModal1 = () => setIsModalOpen1(false);
    const navigate = useNavigate();
  
  
    const [bearer, setBearer] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    
  
  
  
  
    const readData = async () => {
      try {
        const value = await AsyncStorage.getItem('tokens');
        // console.log(value)
        if (value !== null) {
          setBearer(value);
        }
      } catch (e) {
        alert('Failed to fetch the token from storage');
      }
    };
  
  
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
    };
  
  
  //fetch records
  const fetchBeneficiaries = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/job/shortlistedCandidate`, {
        params: {"applicant_id": userId},
        headers: {
          Authorization: `Bearer ${bearer}`,
          'Content-Type': 'application/json'
        },
      }
      );
      const results = response.data?.data;
      toast.success(response?.data?.message)
      onClose()
    
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
    //   setTableData([]);
    }
    } finally {
      setIsLoading(false);
    }
  };
  
  
  
  
    useEffect(() => {
  
      readData();
    }, []);
  
  

    useEffect(() => {
        if (isOpen) {
            // Delay to give a smooth entrance when the modal opens
            const timer = setTimeout(() => {
                setAnimate(true);
            }, 500); // Adjust delay as needed

            return () => clearTimeout(timer);
        } else {
            setAnimate(false); // Reset animation when the modal is closed
        }
    }, [isOpen]);

    if (!isOpen) return null;


    return (
        <div className={classes.modalOverlayWhole}>
            <div className={classes.modalErrYester}>
                <button className={classes.closeButtonModally} onClick={onClose}>
                    <img src={closeX} alt='' />
                </button>
                <div className={classes.modalContainerSend}>

                    <h6>
                       Shortlist Candidate
                    </h6>
                    <p>You are about to shortlist this candidate for the job position, are you sure you want to continue?</p>
                    <button type='submit' className={classes.addTeamBtn} onClick={fetchBeneficiaries}>
                     {isLoading ? 'Shortlisting...' : 'Shortlist'}
                    </button>
                </div>

            </div>
        </div>
    );
};

export default CandidateShortlist;
