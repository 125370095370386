import React, { useState, useEffect } from "react";
import axios from "axios";
import classes from "./ScreenCandidate.module.css";
import job from "../../Assets/job.svg";
import download from '../../Assets/download.svg';
import closeX from './CloseX.svg';
import StopSubmission from "../stopSubmission/stopSubmission";
import CandidateShortlist from "../candidateShortlist/candidateShortlist";
import ApplicationRejection from "../ApplicationRejection/ApplicationRejection";

const ScreenCandidate = ({ isOpen, onClose, userId, userData }) => {
  // console.log(userData)
  const [animate, setAnimate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [applicantData, setApplicantData] = useState(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openModal1 = () => setIsModalOpen1(true);
  const closeModal1 = () => setIsModalOpen1(false);
  const openModal2 = () => setIsModalOpen2(true);
  const closeModal2 = () => setIsModalOpen2(false);

  const downloadCV = () => {
    if (!userData?.resume) {
      console.log("CV not available for download.");
      return;
    }
    const fileName = `${userData.first_name}_${userData.other_name}_CV.pdf`;

    const completeURL = userData.resume.startsWith("http") 
    ? userData.resume 
    : `https://hr-api.emas.ng${userData.resume}`;

    const link = document.createElement("a");
    link.href = completeURL;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setAnimate(true);
      }, 500); 
      return () => clearTimeout(timer);
    } else {
      setAnimate(false);
    }
  }, [isOpen]);

  // Fetch job and applicant data from the API
  useEffect(() => {
    if (userId) {
      axios.get('https://hr-api.emas.ng/api/job/fetchAll')
        .then(response => {
          const job = response.data.data.job.find(job => job.id === userId);
          const applicant = job?.applicants?.find(applicant => applicant.id === userId);
          setApplicantData(applicant);
        })
        .catch(error => {
          console.error("Error fetching applicant data:", error);
        });
    }
  }, [userId]);

 
  

  if (!isOpen) return null;

  return (
    <>
      <StopSubmission isOpen={isModalOpen1} onClose={closeModal1} />
      <CandidateShortlist isOpen={isModalOpen} onClose={closeModal} userId={userId} />
      <ApplicationRejection isOpen={isModalOpen2} onClose={closeModal2} userId={userId} />
      <div className={classes.modalBackdrop}>
        <div className={classes.modalRequest}>
          <div className={classes.divModalTop}>
            <h4 className={classes.requet}>View Candidates</h4>
            <img src={closeX} alt="cancel" onClick={onClose} />
          </div>

          <h4 className={classes.jobText}>Job Summary</h4>
          <div className={classes.divInfo}>
            <div className={classes.sub1}>
              <div className={classes.jDiv}>
                <img src={job} alt="job" />
                <p className={classes.textAcct}>{userData?.job?.title}</p>
              </div>
              <div className={classes.jDiv}>
                <img src={job} alt="job" />
                <p className={classes.textAcct}>{userData?.job?.job_type}</p>
              </div>
              <div className={classes.jDiv}>
                <img src={job} alt="job" />
                <p className={classes.textAcct}>{userData?.job?.experience} years of experience required</p>
              </div>
              <div className={classes.jDiv}>
                <img src={job} alt="job" />
                <p className={classes.textAcct}>Estimated pay for the role - N{userData?.job?.salary_range_from} to N{userData?.job?.salary_range_to}</p>
              </div>
              <div className={classes.jDiv}>
                <img src={job} alt="job" />
                <p className={classes.textAcct}>Start date: {userData?.job?.start_date} - End date: {userData?.job?.end_date}</p>
              </div>
              <div className={classes.jDiv}>
                <img src={job} alt="job" />
                <p className={classes.textAcct}>Number to be hired: {userData?.job?.vacancies}</p>
              </div>
            </div>
            <div className={classes.btnSub}>
              <button onClick={openModal1} className={classes.btnB}>Stop accepting submissions</button>
            </div>
          </div>

          <h4 className={classes.candOne}>Candidate</h4>
          <div className={classes.secondDiv}>
            <div className={classes.secDiv}>
              <div>
                <p className={classes.firstN}>First Name</p>
                <h4 className={classes.firstPc}>{userData?.first_name}</h4>
              </div>
              <div>
                <p className={classes.firstN}>Last Name</p>
                <h4 className={classes.firstPc}>{userData?.other_name}</h4>
              </div>
              <div>
                <p className={classes.firstN}>Email address</p>
                <h4 className={classes.firstPc}>{userData?.email}</h4>
              </div>
              <div>
                <p className={classes.firstN}>Phone number</p>
                <h4 className={classes.firstPc}>{userData?.phone_number}</h4>
              </div>
            </div>

            <div className={classes.btnStyT}>
            <div className={classes.syDwn}>
          <p className={classes.downloadTx} onClick={downloadCV} style={{ cursor: 'pointer' }}>
            Download CV <img src={download} alt="download" />
          </p>
        </div>
              <div className={classes.shrtTop}>
                <button onClick={openModal} className={classes.shrBtn1}>Shortlist Candidate</button>
                <button onClick={openModal2} className={classes.shrBtn21}>Send rejection mail</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default ScreenCandidate;
