import React, { useState, useEffect } from 'react'
import classes from './AddTermination.module.css';
import closeX from './CloseX.svg';
import axios from 'axios';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';

const AddTermination = ({ isOpen, onClose, onEmployeeTerminated }) => {
  const [animate, setAnimate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    employee_id: null,
    exit_date: "",
    notice_date: "",
    termination_type: "",
    reason_for_termination: "",
    department: null,
  });
  const [options1, setOptions1] = useState([]);
  const [error, setError] = useState("");
  const [bearer, setBearer] = useState("");
  const [employees, setEmployees] = useState([]);

  const terminationOptions = [
    { label: 'Voluntary', value: 'voluntary' },
    { label: 'Involuntary', value: 'involuntary' }
  ];

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem("tokens");
      if (value !== null) {
        setBearer(value);
      }
    } catch (e) {
      toast.error("Failed to fetch the input from storage");
    }
  };

  useEffect(() => {
    readData();
  }, []);

  useEffect(() => {
    if (isOpen) {
      setFormData({
        employee_id: null,
        exit_date: "",
        notice_date: "",
        termination_type: "",
        reason_for_termination: "",
        department: null,
      });
      setError("");
    }
  }, [isOpen]);

  const fetchEmployees = async () => {
    try {
      const employeesResponse = await axios.get(
        'https://hr-api.emas.ng/api/employees/fetchAll',
        {
          headers: { Authorization: `Bearer ${bearer}` },
        }
      );
      setEmployees(
        employeesResponse.data.data.map((item) => ({
          label: item.first_name,
          value: item.id,
        }))
      );
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  useEffect(() => {
    if (bearer) {
      fetchEmployees();
    }
  }, [bearer]);

  useEffect(() => {
    const fetchDepartments = async () => {
      if (!bearer) return;

      try {
        const response = await axios.get(
          "https://hr-api.emas.ng/api/department/fetchAll",
          {
            headers: { Authorization: `Bearer ${bearer}` },
          }
        );

        const list = response.data.data;
        setOptions1(
          list.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, [bearer]);

  const handleChange = (e) => {
    setError("");
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({ ...formData, department: selectedOption });
  };

  const handleSelectEmpChange = (selectedOption) => {
    // Set the selected employee in formData
    setFormData({ ...formData, employee_id: selectedOption });
  };

  const handleSelectTerminationTypeChange = (selectedOption) => {
    // Set the termination type in formData
    setFormData({ ...formData, termination_type: selectedOption });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const {
      employee_id,
      exit_date,
      notice_date,
      termination_type,
      reason_for_termination,
      department,
    } = formData;

    if (
      !employee_id ||
      !exit_date ||
      !notice_date ||
      !termination_type ||
      !reason_for_termination ||
      !department
    ) {
      setError("All fields are required");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        "https://hr-api.emas.ng/api/exit-management/create-exit-management",
        {
          employee_id: employee_id.value,
          exit_date: exit_date,
          notice_date: notice_date,
          termination_type: termination_type.value,
          reason_for_termination: reason_for_termination,
          department: department.value,
        },
        {
          headers: { Authorization: `Bearer ${bearer}` },
        }
      );

      if (response.status === 200) {
        toast.success("Employee termination recorded successfully!");
        onEmployeeTerminated();
        onClose();
      }
    } catch (error) {
      console.error("Error adding employee:", error);

      if (error.response) {
        let errorMessage = "An error occurred. Please try again.";
        if (typeof error.response.data.message === "string") {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join("; ");
        } else if (typeof error.response.data.message === "object") {
          errorMessage = JSON.stringify(error.response.data.message);
        }
        setError(`${errorMessage}`);
        toast.error(errorMessage);
      } else {
        setError("Failed to add employee");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setAnimate(true);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setAnimate(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const customStyles = {
    control: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      width: '100%',
      border: '1px solid #6C757D',
      borderRadius: '4px',
      padding: '0',
      boxSizing: 'border-box',
      fontSize: '14px',
      cursor: 'pointer',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
      alignSelf: 'center',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 15px',
      height: '100%',
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
    }),
    placeholder: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
      lineHeight: 'normal',
    }),
  };

  return (
    <div>
      <div className={classes.modalOverlayWhole}>
        <div className={classes.modalErrYes}>
          <button className={classes.closeButtonModally} onClick={onClose}>
            <img src={closeX} alt='' />
          </button>
          <form className={classes.modalContainer22}>
            <h6>Add Termination</h6>
            <div>
              <div className={classes.labelandInput}>
                <label>Enter Name of Employee</label>
                <Select
                  className={classes.reactSelect}
                  placeholder="Select Employee"
                  value={formData.employee_id} // Bind value to employee_id
                  onChange={handleSelectEmpChange} // Update employee selection
                  options={employees}
                  styles={customStyles}
                />
              </div>
              <div className={classes.labelandInput}>
                <label>Select Department</label>
                <Select
                  name="department"
                  options={options1}
                  value={formData.department}
                  onChange={handleSelectChange}
                  styles={customStyles}
                  placeholder="Select department"
                  required
                />
              </div>
              <div className={classes.labelandInput}>
                <label>Termination Type</label>
                <Select
                  name='termination_type'
                  value={formData.termination_type} // Bind value to termination_type
                  options={terminationOptions}
                  onChange={handleSelectTerminationTypeChange} // Update termination type selection
                  styles={customStyles}
                  placeholder="Select termination type"
                />
              </div>
              <div className={classes.labelandInput}>
                <label>Reason for Termination</label>
                <textarea
                  name="reason_for_termination"
                  placeholder="Reason"
                  value={formData.reason_for_termination}
                  onChange={handleChange}
                  className={classes.bigInputTextArea}
                  rows="3"
                />
              </div>
              <div className={classes.labelandInput}>
                <label>Exit Date</label>
                <input
                  type="date"
                  name="exit_date"
                  placeholder="Exit Date"
                  value={formData.exit_date}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.labelandInput}>
                <label>Notice Date</label>
                <input
                  type="date"
                  name="notice_date"
                  placeholder="Notice Date"
                  value={formData.notice_date}
                  onChange={handleChange}
                />
              </div>
            </div>

            {error && (
              <div>
                <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
                  {error}
                </p>
              </div>
            )}
            <button
              className={classes.submitbtnDesgn}
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddTermination;
