import React, { useState, useEffect } from 'react';
import classes from "./EmployeePayroll.module.css";
import add from '../../Assets/add.svg';
import { useNavigate } from 'react-router-dom'; // Keep this line
import employeeimg2 from '../../Assets/Ellipse 1.png';
import eye from '../../Assets/eye.svg';
import search from '../../Assets/search.svg';
import edit from "../../Assets/edit.svg";
import closeX from './CloseX.svg';
import deleteimg from "../../Assets/deleteimg.svg";
import less from "../../Assets/less.svg";
import next from "../../Assets/next.svg";
import TopNavBar from '../../Components/TopNavBar/TopNavBar';
import SendPayslipModal from '../../Components/SendPayslipModal/SendPayslipModal';
import AddEmployeeSalaryModal from '../../Components/AddEmployeeSalaryModal/AddEmployeeSalaryModal';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Select from 'react-select';
import axios from 'axios';
import Modal from 'react-modal'; // Assuming you are using react-modal
import EditEmpSalaryModal from '../../Components/EditEmpSalaryModal/EditEmpSalaryModal';
import { BsFillSendArrowUpFill } from "react-icons/bs";
import PayrollConfirm from '../PayrollConfirm/PayrollConfirm';

const EmployeePayroll = () => {
  const navigate = useNavigate();
  const [bearerToken, setBearerToken] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [payrollData, setPayrollData] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedPayrollId, setSelectedPayrollId] = useState(null);
  const [getPayId,setPayID] = useState('');
  const [confrimModal, setConfirmModal] = useState(false)
  const [payrollId,setPayrollId] = useState('')

  const openModal2 = (payrollId) =>{

    setSelectedPayrollId(payrollId)
    setIsModalOpen2(true);
  } 
  const closeModal2 = () =>{

    setSelectedPayrollId(null)
    setIsModalOpen2(false);
  } 
  // Function to read token from AsyncStorage
  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('tokens');
      if (value !== null) {
        setBearerToken(value);
      } else {
        console.warn('No token found');
      }
    } catch (e) {
      console.error('Failed to fetch the token from storage:', e);
    }
  };

  const handleView = (employeeId) => {
    navigate(`/payroll_modal/${employeeId}`);
  };

  const customStyles = {
    control: (styles) => ({
      ...styles,
      width: '220px',
      minHeight: '28px',
      height: '40px',
      padding: '0',
      border: '1px solid #6C757D',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '14px',
      paddingLeft: '15px',
    }),
    input: (styles) => ({
      ...styles,
      margin: '0px',
      padding: '1px',
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      height: '40px',
    }),
    placeholder: (styles) => ({
      ...styles,
      paddingBottom: '5px',
    }),
  };

  // Function to fetch payroll data from API
  const fetchPayrollData = async () => {
    try {
      const response = await fetch('https://hr-api.emas.ng/api/payroll/fetchAll', {
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
        },
      });
      const data = await response.json();
      console.log('Payroll API response:', data); // Log the API response

      // Access the payroll data nested under data.payroll
      setPayrollData(Array.isArray(data.data.payroll) ? data.data.payroll : []);
    } catch (error) {
      console.error('Error fetching payroll data:', error);
    }
  };

  // Function to fetch departments from API
  const fetchDepartments = async () => {
    try {
      const response = await fetch('https://hr-api.emas.ng/api/department/fetchAll', {
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
        },
      });
      const data = await response.json();
      console.log('Departments API response:', data); // Log the API response

      // Convert the department data to the format required by react-select
      if (data.status && data.data && Array.isArray(data.data)) {
        const departmentOptions = data.data.map(department => ({
          value: department.id,
          label: department.name,
        }));
        setDepartments(departmentOptions);
      } else {
        console.error('Unexpected response format for departments:', data);
        setDepartments([]);
      }
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await readData();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (bearerToken) {
      fetchPayrollData();
      fetchDepartments();
    }
  }, [bearerToken]);

  const refreshData = () => {
    fetchPayrollData();
  };

  const openDeleteModal = (index) => {
    setDeleteIndex(index);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteIndex(null);
    setIsDeleteModalOpen(false);
  };

  const handleDeleteClick = async () => {
    try {
      if (deleteIndex !== null) {
        setLoading(true);
        await axios.get(`https://hr-api.emas.ng/api/payroll/delete`, {
          params: { id: payrollData[deleteIndex].id },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        const updatedPayrollData = payrollData.filter((_, index) => index !== deleteIndex);
        setPayrollData(updatedPayrollData);
        setDeleteIndex(null);
      }
    } catch (error) {
      console.error('Error deleting Employee:', error);
    } finally {
      closeDeleteModal();
      setLoading(false);
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openModal1 = () => setIsModalOpen1(true);
  const closeModal1 = () => setIsModalOpen1(false);
  

  // Pagination controls
  const handleNextPage = () => {
    if (currentPage < Math.ceil(payrollData.length / itemsPerPage)) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  // Paginate the data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPayrollData = Array.isArray(payrollData)
    ? payrollData.slice(indexOfFirstItem, indexOfLastItem)
    : [];


    const payrollEditHandler = (id) => {
      setPayID(id);
      openModal2()
    }

  const sendPayrolHandler = (item) => {
    setPayrollId(item)
    setConfirmModal(true)
  }

  return (
    <div>
      {confrimModal && <PayrollConfirm onShow={setConfirmModal} getId={payrollId}  />}
      
      <TopNavBar />
      <div className={classes.cover}>
        <SendPayslipModal isOpen={isModalOpen} onClose={closeModal} />
        <EditEmpSalaryModal isOpen={isModalOpen2} onClose={closeModal2} onAdd={refreshData} payrollId={selectedPayrollId} onPayrollUpdated={fetchPayrollData}/>
        <AddEmployeeSalaryModal isOpen={isModalOpen1} onClose={closeModal1} onAdd={refreshData} />
        <div className={classes.oneLineHeading}>
          <p className={classes.paraWeightfont}>Payroll Management</p>
          <div className={classes.BtnRight}>
            <button onClick={openModal} className={classes.decoratedBtn}>Send out Payslip</button>
            <button onClick={openModal1} className={classes.decorate}>
              <img src={add} alt='Add Salary' />
              <p>Add Salary</p>
            </button>
          </div>
        </div>
        <div className={classes.containerContain}>
          <p className={classes.paradesgnborder}>Payroll</p>
          <div className={classes.lineUnder}></div>
          <div className={classes.padSideBox}>
            <div className={classes.headerBar}>
              <div className={classes.align045}>

                <img src={search} alt='Search' />
                <input type='text' placeholder='Search employee name' />

                <Select
                  options={departments}
                  value={selectedDepartment}
                  onChange={setSelectedDepartment}
                  placeholder='Select department'
                  styles={customStyles}
                />
                <button>Search</button>
              </div>
            </div>

            <div className={classes.tableContainer}>
              <table className={classes.tableDiv}>
                <thead>
                  <tr className={classes.tableHeadDiv}>
                    <th className={classes.tableHeadCenter}>S/N</th>
                    <th className={classes.tableHeadRow}>Employee Name</th>
                    <th className={classes.tableHeadRow}>Designation</th>
                    <th className={classes.tableHeadRow}>Department</th>
                    <th className={classes.tableHeadRow}>Date of Joining</th>
                    <th className={classes.tableHeadRow}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPayrollData.length > 0 ? (
                    currentPayrollData.map((payroll, index) => (
                      <tr key={payroll.id}>
                        <td className={classes.tableRowBody}>{indexOfFirstItem + index + 1}</td>
                        <td className={classes.pflDiv}>
                          <img src={employeeimg2} alt="Employee" />
                          <span>{payroll.employee ? `${payroll.employee.first_name} ${payroll.employee.last_name}` : 'N/A'}</span>
                        </td>
                        <td className={classes.tableHeadLeft}>
                          {payroll.designation ? payroll.designation.title : 'N/A'}
                        </td>
                        <td className={classes.tableHeadLeft}>
                          {payroll.department ? payroll.department.name : 'N/A'}
                        </td>
                        <td className={classes.tableHeadLeft}>
                          {payroll.start_date ? payroll.start_date : 'N/A'}
                        </td>
                        <td className={classes.formEye}>
                          <img src={eye} alt='View' onClick={() => handleView(payroll.id)} />
                          <img
                            src={edit}
                            alt='Edit'
                            onClick={() => openModal2(payroll.id)}
                            // onClick={openModal2}
                            // onClick={() => payrollEditHandler(payroll.id)}
                            style={{ cursor: 'pointer' }}
                            
                          />
                          <img
                            src={deleteimg}
                            alt='Delete'
                            onClick={() => openDeleteModal(index)}
                            style={{ cursor: 'pointer' }}
                          />
                          <BsFillSendArrowUpFill size={24} style={{color:'#2D995F',cursor:'pointer',marginLeft:10}} onClick={() => sendPayrolHandler(payroll.employee_id)}/>
                        </td>

                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className={classes.noData}>No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className={classes.gotoNext}>
              <button className={classes.prev} onClick={handlePreviousPage} disabled={currentPage === 1}>
                <img src={less} alt='Previous' />
                Previous
              </button>
              <span className={classes.pagination}>Page {currentPage} of {Math.ceil(payrollData.length / itemsPerPage)}</span>
              <button className={classes.next} onClick={handleNextPage} disabled={currentPage === Math.ceil(payrollData.length / itemsPerPage)}>
                Next
                <img src={next} alt='Next' />
              </button>
            </div>
          </div>
        </div>
        <Modal isOpen={isDeleteModalOpen} onRequestClose={closeDeleteModal}>
          <div className={classes.modalOverlayWhole}>
            <div className={classes.modalErrYester}>
              <button className={classes.closeButtonModally} onClick={closeDeleteModal}>
                <img src={closeX} alt='' />
              </button>
              <div className={classes.modalContainerSend}>

                <h6>
                  Delete Payroll
                </h6>


                <p>You are about to delete this Payroll, this action cannot be reversed, are you sure you want to continue?</p>

                <button className={classes.addTeamBtn} onClick={handleDeleteClick} disabled={loading}>

                  {loading ? 'Deleting...' : 'Delete'}
                </button>



              </div>

            </div>
          </div>

        </Modal>
      </div>
    </div>
  );
};

export default EmployeePayroll;
