import React, { useState, useEffect } from 'react';
import classes from './EditJobApplicationModal.module.css';
import closeX from './CloseX.svg';
import addNew from '../../Assets/addNew.svg';
import attach from '../../Assets/attachfile.svg'
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import Select from 'react-select'

const EditJobApplicationModal = ({ isOpen, onClose, jobId, onJobUpdated }) => {
    const [animate, setAnimate] = useState(false);
    const [allowances, setAllowances] = useState([]);
    const [filename, setFileName] = useState("Attach your resume/CV")
    const [filename1, setFileName1] = useState("Attach your cover letter")

    const [loading, setLoading] = useState(false);
    const [jobData, setJobData] = useState({});
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [bearer, setBearer] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('tokens');
            if (value !== null) {
                setBearer(value);
            }
        } catch (e) {
            alert('Failed to fetch the token from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    useEffect(() => {
        if (isOpen && jobId && bearer) {
            const fetchJobDetails = async () => {
                setErrorMessage('')
                try {
                    const response = await axios.get('https://hr-api.emas.ng/api/job/update', {
                        params: { id: jobId },
                        headers: {
                            Authorization: `Bearer ${bearer}`,
                        },
                    });
                    setJobData(response.data.data);
                    // console.log(response.data.data)
                    setSelectedDepartment(departments.find(dept => dept.value === jobData.department_id) || null);
                } catch (error) {
                    console.error('Error fetching employee details:', error);
                    setErrorMessage('Failed to fetch employee details');
                }
            };
            fetchJobDetails();
        }
    }, [isOpen, jobId, bearer, departments]);

    useEffect(() => {
        if (departments.length > 0 && jobData.department_id) {
            setSelectedDepartment(departments.find(dept => dept.value === jobData.department_id) || null);
        }
    }, [departments, jobData.department_id]);

    useEffect(() => {
        if (bearer) {
            const fetchDepartments = async () => {
                try {
                    const response = await axios.get('https://hr-api.emas.ng/api/department/fetchAll', {
                        headers: {
                            Authorization: `Bearer ${bearer}`,
                        },
                    });
                    setDepartments(response.data.data.map(item => ({
                        label: item.name,
                        value: item.id,
                    })));
                } catch (error) {
                    console.error('Error fetching departments:', error);
                    setErrorMessage('Failed to fetch departments');
                }
            };
            fetchDepartments();
        }
    }, [bearer]);




    useEffect(() => {

        if (isOpen) {
            // Delay to give a smooth entrance when the modal opens
            const timer = setTimeout(() => {
                setAnimate(true);
            }, 500); // Adjust delay as needed

            return () => clearTimeout(timer);
        } else {
            setAnimate(false); // Reset animation when the modal is closed
        }
    }, [isOpen]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setErrorMessage('')
        try {
            await axios.get('https://hr-api.emas.ng/api/job/update', {
                params: {
                    id: jobId,
                    first_name: jobData.first_name,
                    last_name: jobData.last_name,
                    email: jobData.email,
                    phone: jobData.phone,
                    department_id: selectedDepartment.value,
                    job_type: jobData.job_type,
                    title: jobData.title,
                    closing_date: jobData.closing_date,
                    posting_date: jobData.posting_date,
                    work_type: jobData.work_type,
                    vacancies: jobData.vacancies,
                    experience: jobData.experience,
                    location: jobData.location,
                    age_range: jobData.age_range,
                    salary_range_from: jobData.salary_range_from,
                    salary_range_to: jobData.salary_range_to,
                    start_date: jobData.start_date,
                    end_date: jobData.end_date,
                    description: jobData.description,
                },
                headers: {
                    Authorization: `Bearer ${bearer}`,
                },
            });
            onJobUpdated();
            onClose();
            console.log(event);

        } catch (error) {
            console.error('Error adding employee:', error);
            console.log(error);

            if (error.response) {
                let errorMessage = 'An error occurred. Please try again.';
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
                setErrorMessage(`${errorMessage}`);
            } else {
                setErrorMessage('Failed to add employee');
            }
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    const getFileHandler = (e) => {
        const file = e.target.value.slice(12);
        if (file) {
            setFileName(file)
        }
        else {
            setFileName("Attach your resume/CV")
        }
    }
    const getFileHandler1 = (e) => {
        const file = e.target.value.slice(12);
        if (file) {
            setFileName1(file)
        }
        else {
            setFileName1("Attach your resume/CV")
        }
    }


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const { name } = e.target;
        if (file && file.type === 'application/pdf') {
            setJobData({
                ...jobData,
                [name]: file,
            });
            if (name === 'cv') {
                setFileName(file.name);
            } else if (name === 'cover_letter') {
                setFileName1(file.name);
            }
        } else {
            alert('Please upload a PDF file.');
        }
    };
    return (
        <div className={classes.modalOverlayWhole}>
            <div className={classes.modalErrYes}>
                <button className={classes.closeButtonModally} onClick={onClose}>
                    <img src={closeX} alt='' />
                </button>
                <form onSubmit={handleSubmit} className={classes.modalContainer22}>
                    <h6>Edit Job Application</h6>

                    {/* Employee Information */}
                    <h5>Job Title</h5>
                    <div className={classes.classGroupCont}>
                        <div className={classes.labelandInput}>
                            <label>Enter Job Title</label>
                            <input type='text' required
                                value={jobData.title || ''}
                                onChange={(e) => setJobData({ ...jobData, title: e.target.value })} />
                        </div>
                        <div className={classes.labelandInput}>
                            <label>Select Department</label>
                            <Select
                                options={departments}
                                value={selectedDepartment || ''}
                                onChange={(selectedOption) => setSelectedDepartment(selectedOption)}
                                required
                            />
                        </div>

                    </div>

                    {/* Payslip Date Range */}
                    <h5>Payslip date range</h5>
                    <div className={classes.classGroupCont}>
                        <div className={classes.labelandInput}>
                            <label htmlFor='startDate'>Start date</label>
                            <input type='date' id='startDate' required
                                value={jobData.posting_date || ''}
                                onChange={(e) => setJobData({ ...jobData, posting_date: e.target.value })} />
                        </div>
                        <div className={classes.labelandInput}>
                            <label htmlFor='endDate'>End date</label>
                            <input type='date' id='endDate' required
                                value={jobData.closing_date || ''}
                                onChange={(e) => setJobData({ ...jobData, closing_date: e.target.value })}
                            />
                        </div>
                    </div>

                    {/* Earnings Section */}
                    <h5>Job Information</h5>
                    <div className={classes.classGroupCont}>
                        <div className={classes.labelandInput}>
                            <label>Work type</label>
                            <select
                                name="work_type"
                                value={jobData.work_type || ''}
                                onChange={(e) => setJobData({ ...jobData, work_type: e.target.value })}
                                required
                            >
                                <option value=''>
                                    Select work type
                                </option>
                                <option value='On-site'>
                                    On-site
                                </option>
                                <option value='Remote'>
                                    Remote
                                </option>
                                <option value='Hybrid'>
                                    Hybrid
                                </option>
                            </select>
                        </div>
                        <div className={classes.labelandInput}>
                            <label>Job type</label>
                            <select
                                name="job_type"
                                value={jobData.job_type || ''}
                                onChange={(e) => setJobData({ ...jobData, job_type: e.target.value })}
                                required
                            >
                                <option value=''>
                                    Select job type
                                </option>
                                <option value='Full_time'>
                                    Full-time
                                </option>
                                <option value='Part_time'>
                                    Part-time
                                </option>
                            </select>
                        </div>
                        <div className={classes.labelandInput}>
                            <label>No of Vacancies</label>
                            <input type='text' placeholder='3' required
                                value={jobData.vacancies || ''}
                                onChange={(e) => setJobData({ ...jobData, vacancies: e.target.value })} />
                        </div>
                        <div className={classes.labelandInput}>
                            <label>Years of Experiences</label>
                            <input type='text' placeholder='3' required
                                value={jobData.experience || ''}
                                onChange={(e) => setJobData({ ...jobData, experience: e.target.value })} />
                        </div>
                        <div className={classes.labelandInput}>
                            <label>Job Location</label>
                            <input type='text' placeholder='Ikeja, Lagos' required
                                value={jobData.location || ''}
                                onChange={(e) => setJobData({ ...jobData, location: e.target.value })} />
                        </div>
                        <div className={classes.labelandInput}>
                            <label>Preferred age Range</label>
                            <select value={jobData.age_range || ''}
                                onChange={(e) => setJobData({ ...jobData, age_range: e.target.value })}
                            >
                                <option value=''>
                                    Select age range
                                </option>
                                <option value='18-24'>
                                    18-24
                                </option>
                                <option value='25-34'>
                                    25-34
                                </option>
                                <option value='35-44'>
                                    35-44
                                </option>
                                <option value='45 or more'>
                                    45 or more
                                </option>

                            </select>
                        </div>
                        <div className={classes.labelandInput}>
                            <label>Salary range from</label>
                            <input type='text' placeholder='250, 000' required
                                value={jobData.salary_range_from || ''}
                                onChange={(e) => setJobData({ ...jobData, salary_range_from: e.target.value })} />
                        </div>
                        <div className={classes.labelandInput}>
                            <label>Salary range to</label>
                            <input type='text' placeholder='350, 000' required
                                value={jobData.salary_range_to || ''}
                                onChange={(e) => setJobData({ ...jobData, salary_range_to: e.target.value })} />
                        </div>
                        <div className={classes.labelandInput}>
                            <label>Start date</label>
                            <input type='date' placeholder='' required
                                value={jobData.start_date || ''}
                                onChange={(e) => setJobData({ ...jobData, start_date: e.target.value })} />
                        </div>
                        <div className={classes.labelandInput}>
                            <label>End date</label>
                            <input type='date' placeholder='' required
                                value={jobData.end_date || ''}
                                onChange={(e) => setJobData({ ...jobData, end_date: e.target.value })} />
                        </div>

                        <a className={classes.addNewdiv} >
                            <img src={addNew} alt="Add New" /> Add New
                        </a>
                        {allowances.length > 0 && (
                            <a className={classes.addNewdiv}>
                                <img src={addNew} alt="Delete Last" /> Delete Last
                            </a>
                        )}
                    </div>

                    {/* Deductions Section */}
                    <h5>Job Decription</h5>
                    <div className={classes.classGroupContExe}>
                        <div className={classes.contentRewper}>
                            <h4>Enter Job Description</h4>
                            <textarea rows='13' className={classes.pTextParagraph} value={jobData.description || ''}
                            onChange={(e) => setJobData({ ...jobData, description: e.target.value })}>

                            </textarea>
                        </div>

                    </div>

                    <h5>Feilds to fill</h5>
                    <div className={classes.classGroupCont}>
                        <div className={classes.labelandInput}>
                            <label htmlFor='startDate'>First Name</label>
                            <input type='text' id='first Name' required value={jobData.first_name || ''}
                                onChange={(e) => setJobData({ ...jobData, first_name: e.target.value })} />
                        </div>
                        <div className={classes.labelandInput}>
                            <label htmlFor='startDate'>Last Name</label>
                            <input type='text' id='Last Name' required
                                value={jobData.last_name || ''}
                                onChange={(e) => setJobData({ ...jobData, last_name: e.target.value })} />
                        </div>
                        <div className={classes.labelandInput}>
                            <label htmlFor='startDate'>Email Address</label>
                            <input type='email' id='email' required
                                value={jobData.email || ''}
                                onChange={(e) => setJobData({ ...jobData, email: e.target.value })} />
                        </div>
                        <div className={classes.labelandInput}>
                            <label htmlFor='startDate'>Phone Number</label>
                            <input type='text' id='phone number' required
                                value={jobData.phone || ''}
                                onChange={(e) => setJobData({ ...jobData, phone: e.target.value })} />
                        </div>
                        {/* <div className={classes.labelandInput}>
                            <label htmlFor='cv'>Resume/CV</label>
                            <div className={classes.hiddenDiv}>
                                <p>{filename}</p>
                                <img src={attach} alt='' />
                            </div>
                            <input
                                type='file'
                                id='cv'
                                accept="application/pdf" 
                                name='cv'
                                required
                                onChange={getFileHandler}
                                className={classes.hidenInput}
                            />
                        </div>
                        <div className={classes.labelandInput}>
                            <label htmlFor='cover_letter'>Cover Letter</label>
                            <div className={classes.hiddenDiv}>
                                <p>{filename1}</p>
                                <img src={attach} alt='' />
                            </div>
                            <input
                                type='file'
                                id='cover_letter'
                                accept="application/pdf" 
                                name='cover_letter'
                                required
                                onChange={getFileHandler1}
                                className={classes.hidenInput}
                            />
                        </div> */}
                        <a className={classes.addNewdiv} >
                            <img src={addNew} alt="Add New" /> Add New
                        </a>
                        {allowances.length > 0 && (
                            <a className={classes.addNewdiv}>
                                <img src={addNew} alt="Delete Last" /> Delete Last
                            </a>
                        )}

                    </div>


                    {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
                    <button type='submit' className={classes.addTeamBtn} disabled={loading}>
                       {loading? 'Saving...' : 'Save Changes' }
                    </button>
                </form>
            </div>
        </div>
    );
};

export default EditJobApplicationModal;
