import React, { useState } from "react";
import styles from "./Services.module.css";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import edit from "../AddDept/edit.png";
import trash from "../AddDept/trash.png";
import close from "../TaskManage/close-circle.svg";
import add from "../../Components/TaskManagmentSideNav/add-circle.svg";
import Select from "react-select"; // or wherever your Select component is located

import pic1 from "../../Assets/MeetingPic1.svg";
import pic2 from "../../Assets/MeetingPic2.svg";
import pic3 from "../../Assets/MeetingPic3.svg";
import AddMeeting from "../AddMeeting/AddMeeting";

const Services = () => {
  const [activeTab, setActiveTab] = useState("publicHolidays");
  const [isModalOpen, setModalOpen] = useState(false);
  const [holidayName, setHolidayName] = useState("");
  const [dates, setDates] = useState([""]); // For public holidays
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employeeOptions, setEmployeeOptions] = useState([]); // Populate with employee options
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [designationOptions, setDesignationOptions] = useState([]); // Populate with designation options
  const [message, setMessage] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [joinDates, setJoinDates] = useState([""]); // For employees
  const [showMeeting, setShoweeting] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleAddPublicHoliday = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    // Clear the input states when closing the modal
    setHolidayName("");
    setDates([""]);
    setEmployeeName("");
    setJoinDates([""]);
  };

  const handleDateChange = (index, value) => {
    const updatedDates = [...dates];
    updatedDates[index] = value;
    setDates(updatedDates);
  };

  const handleAddAnotherDate = () => {
    setDates([...dates, ""]); // Add a new empty date input
  };

  const handleSubmitHoliday = (e) => {
    e.preventDefault();
    // Handle the form submission logic for holidays here
    console.log("Holiday Name:", holidayName);
    console.log("Dates:", dates);
    handleCloseModal(); // Close modal after submission
  };

  const handleAddEmployee = () => {
    setModalOpen(true);
  };

  const handleEmployeeNameChange = (e) => {
    setEmployeeName(e.target.value);
  };

  const handleJoinDateChange = (index, value) => {
    const updatedJoinDates = [...joinDates];
    updatedJoinDates[index] = value;
    setJoinDates(updatedJoinDates);
  };

  const handleAddAnotherJoinDate = () => {
    setJoinDates([...joinDates, ""]); // Add a new empty join date input
  };

  const handleSubmitEmployee = (e) => {
    e.preventDefault();
    // Handle the form submission logic for employees here
    console.log("Employee Name:", employeeName);
    console.log("Join Dates:", joinDates);
    handleCloseModal(); // Close modal after submission
  };

  const handleAddMeeting = () => {
    setShoweeting(true);
  };

  return (
    <div>
      <TopNavBar />

      <div className={styles.header}>
        <h4>Other Services</h4>
        <div className={styles.tabs}>
          <div
            className={`${styles.tab} ${
              activeTab === "publicHolidays" ? styles.active : ""
            }`}
            onClick={() => handleTabClick("publicHolidays")}
          >
            Public Holidays
          </div>
          <div
            className={`${styles.tab} ${
              activeTab === "employees" ? styles.active : ""
            }`}
            onClick={() => handleTabClick("employees")}
          >
            Employees
          </div>
          <div
            className={`${styles.tab} ${
              activeTab === "meetings" ? styles.active : ""
            }`}
            onClick={() => handleTabClick("meetings")}
          >
            Meetings
          </div>
        </div>

        {activeTab === "publicHolidays" && (
          <div className={styles.toppertop}>
            <p>Public Holiday</p>
            <button
              className={styles.policyBtn}
              onClick={handleAddPublicHoliday}
            >
              Add Public Holiday
            </button>
          </div>
        )}
        {activeTab === "employees" && (
          <div className={styles.toppertop}>
            <p>Employees of the month</p>
            <button className={styles.policyBtn} onClick={handleAddEmployee}>
              Add Employee of the month
            </button>
          </div>
        )}
        {activeTab === "meetings" && (
          <div className={styles.toppertop}>
            <p>Meetings</p>
            <button className={styles.policyBtn} onClick={handleAddMeeting}>
              Add Meetings
            </button>
          </div>
        )}
        {showMeeting && <AddMeeting onShow={setShoweeting} />}

        <div className={styles.bottomerbottom}>
          {activeTab === "publicHolidays" && (
            <>
              <div className={styles.border}>
                <div className={styles.texts}>
                  <p>Salah Holiday</p>
                  <h6>24th and 25th of June 2024</h6>
                </div>
                <div className={styles.buttons}>
                  <img src={edit} alt="" />
                  <img src={trash} alt="" />
                </div>
              </div>

              <div className={styles.border}>
                <div className={styles.texts}>
                  <p>Democracy Day</p>
                  <h6>12th of June 2024</h6>
                </div>
                <div className={styles.buttons}>
                  <img src={edit} alt="" />
                  <img src={trash} alt="" />
                </div>
              </div>
            </>
          )}
          {activeTab === "employees" && (
            <>
              <div className={styles.border}>
                <div className={styles.texts}>
                  <p>John Doe</p>
                  <h6>Joined on 1st of January 2024</h6>
                  <br />
                  <h6>
                    We value you and we appreciate your hardwork and dedication
                    to the progress of this company, thank you for your service.
                  </h6>
                </div>
                <div className={styles.buttons}>
                  <img src={edit} alt="" />
                  <img src={trash} alt="" />
                </div>
              </div>

              <div className={styles.border}>
                <div className={styles.texts}>
                  <p>Jane Smith</p>
                  <h6>Joined on 15th of March 2024</h6>
                  <br />
                  <h6>
                    We value you and we appreciate your hardwork and dedication
                    to the progress of this company, thank you for your service.
                  </h6>
                </div>
                <div className={styles.buttons}>
                  <img src={edit} alt="" />
                  <img src={trash} alt="" />
                </div>
              </div>
            </>
          )}
          {activeTab === "meetings" && (
            <>
              <div className={styles.meetingborder}>
                <div className={styles.meetingtexts}>
                  <p> Marketing team meeting </p>
                  <h5>
                    Daily stand up with the markerting team to review strategies
                    for the upcoming product activation
                  </h5>

                  <div className={styles.meetingPic}>
                    <img src={pic1} alt="" />
                    <h6>Lara Awodele</h6>

                    <img src={pic2} alt="" />
                    <h6>Matthew Alade</h6>

                    <img src={pic3} alt="" />
                    <h6>Mary Awoyaya</h6>
                  </div>

                  <div className={styles.meetingDuration}>
                    <h6>Date: 01/09/2024</h6>
                    <h6>Time: 08:00am</h6>
                  </div>
                </div>
                <div className={styles.buttons}>
                  <img src={edit} alt="" />
                  <img src={trash} alt="" />
                </div>
              </div>

              <div className={styles.meetingborder}>
                <div className={styles.meetingtexts}>
                  <p> Marketing team meeting </p>
                  <h5>
                    Daily stand up with the markerting team to review strategies
                    for the upcoming product activation
                  </h5>

                  <div className={styles.meetingPic}>
                    <img src={pic1} alt="" />
                    <h6>Lara Awodele</h6>

                    <img src={pic2} alt="" />
                    <h6>Matthew Alade</h6>

                    <img src={pic3} alt="" />
                    <h6>Mary Awoyaya</h6>
                  </div>

                  <div className={styles.meetingDuration}>
                    <h6>Date: 01/09/2024</h6>
                    <h6>Time: 08:00am</h6>
                  </div>
                </div>
                <div className={styles.buttons}>
                  <img src={edit} alt="" />
                  <img src={trash} alt="" />
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Public Holiday Modal */}
      {isModalOpen && activeTab === "publicHolidays" && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <span className={styles.closeButton} onClick={handleCloseModal}>
              <img src={close} alt="Close" />
            </span>
            <h3>Add Public Holiday</h3>
            <hr />
            <form className={styles.form} onSubmit={handleSubmitHoliday}>
              <label className={styles.label}>
                <p>Name of Public Holiday</p>
                <input
                  type="text"
                  name="holidayName"
                  required
                  value={holidayName}
                  onChange={(e) => setHolidayName(e.target.value)}
                />

                <p className={styles.holiday}>Date of Public Holiday</p>
                {dates.map((date, index) => (
                  <input
                    key={index}
                    type="date"
                    name={`holidayDate${index}`}
                    className={styles.inputDate}
                    value={date}
                    onChange={(e) => handleDateChange(index, e.target.value)}
                    required
                  />
                ))}
              </label>

              <div className={styles.newdate} onClick={handleAddAnotherDate}>
                <img src={add} alt="" />
                <p>Add another date</p>
              </div>
              <button type="submit" className={styles.policyBtn1}>
                Submit
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Employee Modal */}
      {isModalOpen && activeTab === "employees" && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <span className={styles.closeButton} onClick={handleCloseModal}>
              <img src={close} alt="Close" />
            </span>
            <h3>Add Employee of the month</h3>
            <hr />
            <form className={styles.form} onSubmit={handleSubmitEmployee}>
              <label className={styles.label}>
                <p>Select Employee</p>
                <Select
                  options={employeeOptions}
                  value={selectedEmployee}
                  onChange={setSelectedEmployee}
                  required
                />
              </label>

              <label className={styles.label}>
                <p>Select Designation</p>
                <Select
                  options={designationOptions}
                  value={selectedDesignation}
                  onChange={setSelectedDesignation}
                  required
                />
              </label>

              <label className={styles.label}>
                <p>Type Message for Employee of the Month</p>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  rows="4"
                  required
                  className={styles.textarea}
                />
              </label>

              <button type="submit" className={styles.policyBtn1}>
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;
