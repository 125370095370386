import React, { useState, useContext, useEffect } from "react";
import classes from "./AddMeeting.module.css";
import image1 from "../../Assets/Ellipse 1 (1).png";
import image2 from "../../Assets/Ellipse 1.png";
import approve from "../../Assets/approve.svg";
import decline from "../../Assets/decline.svg";
import view from "../../Assets/view.svg";
import less from "../../Assets/less.svg";
import next from "../../Assets/next.svg";
import plus from "../../Assets/plus.svg";
// import TopNavBar from "../Components/ErrorModal/TopNavBar/TopNavBar";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import cancel from "../../Assets/cancel.svg";
import { Link } from "react-router-dom";
import add from '../../Assets/addN.svg'

const AddMeeting = ({ onShow}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const closeModal = (event) => {
      if(event.code === 'Escape')
      onShow(false)
    }
    window.addEventListener('keydown',closeModal)

    return () => {
      window.removeEventListener('keydown',closeModal)
    }  
  },[]) 
 
  return (
    <>
      <div className={classes.modalBackdrop}>
        <div className={classes.modalRequest}>
          <div className={classes.divModalTop}>
            <h4 className={classes.requet}>Add Meeting</h4>
            <img src={cancel} alt="cancel" onClick={() => onShow(false)}/>
          </div>

          <div>
            <label htmlFor="title" className={classes.labelTitle}>Title of Meeting</label>
            <input
              type="text"
              id="title"
              placeholder="Marketing Team meeting"
              className={classes.titleInput}
            />
          </div>

          <div>
            <label htmlFor="titleDesc" className={classes.labelTitle}>Description of meeting</label>
            <textarea
              rows={9}
              cols={58}
              placeholder="We value you and we appreciate your hardwork and dedication to the 
progress of this company, thank you for your service."
              id="titleDesc"
              className={classes.titleInput1}
            ></textarea>
          </div>
          <div>
            <h4 className={classes.labelTitle} style={{fontSize:16,fontWeight:400}}>Add Participants</h4>
            <div className={classes.labelSelect}>
            <div className={classes.selectOpt}>
              <label className={classes.labelTitle} htmlFor="name1">Select employee</label>
              <select id="name1" placeholder="Marketing Team meeting" className={classes.selectSelect}>
                <option className={classes.selectOpt}>Lara Awodele</option>
              </select>
            </div>
            
            <div className={classes.selectOpt}>
              <label className={classes.labelTitle} htmlFor="name1">Select employee</label>
              <select id="name1" placeholder="Marketing Team meeting" className={classes.selectSelect}>
                <option className={classes.selectOpt}>Lara Awodele</option>
              </select>
            </div>
            
            <div className={classes.selectOpt}>
              <label className={classes.labelTitle} htmlFor="name1">Select employee</label>
              <select id="name1" placeholder="Marketing Team meeting" className={classes.selectSelect}>
                <option className={classes.selectOpt}>Lara Awodele</option>
              </select>
            </div>
            <div className={classes.addStyle}><img src={add} alt="add another participant"/>Add another participant</div>
            
            </div>
          </div>
          <div className={classes.DateDiv}>
            <label className={classes.labelTitle} htmlFor="title">Date of meeting</label>
            <input
              type="date"
              id="title"
              placeholder="Marketing Team meeting"
              className={classes.titleInput3}
            />
          </div>
          <div className={classes.selectOpt}>
              <label className={classes.labelTitle} htmlFor="name1">Select time</label>
              <select id="name1" placeholder="Marketing Team meeting" className={classes.selectSelect}>
                <option className={classes.selectOpt}>08:00am</option>
              </select>
            </div>
            <button className={classes.btnSubmit}>Submit</button>
        </div>
      </div>
      <TopNavBar />
      <div className={classes.toTUpper}>
        <h4 className={classes.topperText}>Leave Management</h4>
        <div className={classes.firstBtn}>
          <Link to="/create_policy">
            <button className={classes.policyBtn}>Create Leave Policy</button>
          </Link>
          <Link to="/add_leave">
            <button className={classes.addBtn}>
              <img src={plus} alt="plus" />
              Add Leave
            </button>
          </Link>
        </div>
        <div className={classes.topt}>
          <p>Leave Request</p>
          <p>Approved</p>
          <p>Declined</p>
          <p>Leave Policy</p>
        </div>
        <div className={classes.topLeave}>
          <div>
            <div className={classes.topTextDiv}>
              <h4 className={classes.topText}>Leave Request</h4>
            </div>

            <table className={classes.tableDiv}>
              <tr className={classes.tableHeadDiv}>
                <th className={classes.tableHeadRow}>S/N</th>
                <th className={classes.tableHeadRow}>Employee Name</th>
                <th className={classes.tableHeadRow}>Designation</th>
                <th className={classes.tableHeadRow}>Start date</th>
                <th className={classes.tableHeadRow}>End date</th>
                <th className={classes.tableHeadRow}>Status</th>
                <th className={classes.tableHeadRow}>View</th>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>1</td>
                <td className={classes.pflDiv}>
                  <img src={image1} alt="one" />
                  Matthew Olowade
                </td>
                <td>Media Project Manager </td>
                <td>21/08/2024</td>
                <td>25/08/2024</td>
                <td>
                  <p className={classes.approvedDiv}>Appproved</p>
                </td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>2</td>
                <td className={classes.pflDiv}>
                  <img src={image2} alt="two" />
                  Lauren Annabelle
                </td>
                <td>Art Director </td>
                <td>27/08/2024</td>
                <td>28/08/2024</td>
                <td>
                  <p className={classes.approvedDiv}>Appproved</p>
                </td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>1</td>
                <td className={classes.pflDiv}>
                  <img src={image1} alt="one" />
                  Matthew Olowade
                </td>
                <td>Media Project Manager </td>
                <td>21/08/2024</td>
                <td>25/08/2024</td>
                <td>
                  <p className={classes.approvedDiv}>Appproved</p>
                </td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
              <tr className={classes.tableRowBody}>
                <td>2</td>
                <td className={classes.pflDiv}>
                  <img src={image2} alt="two" />
                  Lauren Annabelle
                </td>
                <td>Art Director </td>
                <td>27/08/2024</td>
                <td>28/08/2024</td>
                <td className={classes.btnSet}>
                  <p className={classes.approvedDiv}>Appproved</p>
                </td>
                <td>
                  <img src={view} alt="approve" />
                </td>
              </tr>
            </table>
            <div className={classes.gotoNext}>
              <button className={classes.prev}>
                <img src={less} alt="less" />
                Previous
              </button>
              <p className={classes.pagination}>Page 1 of 5</p>
              <button className={classes.next}>
                Next
                <img src={next} alt="next" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMeeting;
