import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import classes from './AddEmployeeSalaryModal.module.css';
import closeX from './CloseX.svg';
import addNew from '../../Assets/addNew.svg';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';


const EditEmpSalaryModal = ({ isOpen, onClose }) => {
  const [animate, setAnimate] = useState(false);
  const [allowances, setAllowances] = useState([]);
  const [deductions, setDeductions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [bearer, setBearer] = useState('');
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    employee: null,
    department: null,
    designation: null,
    salary: '',
    startDate: '',
    endDate: '',
    payDate: '',
    houseAllowance: '',
    transportAllowance: '',
    tax: '',
    pension: '',
    loan: '',
    bonus: '',
    otherDeductions: '',
  });

  // Fetch token from storage
  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('tokens');
      if (value) {
        setBearer(value);
      } else {
        setError('Failed to fetch token. Please login again.');
      }
    } catch (e) {
      setError('Failed to fetch the token from storage');
    }
  };

  // Fetch all departments, designations, and employees
  const fetchData = async () => {
    if (!bearer) return;

    try {
      const [departmentsResponse, designationsResponse, employeesResponse] = await Promise.all([
        axios.get('https://hr-api.emas.ng/api/department/fetchAll', {
          headers: { Authorization: `Bearer ${bearer}` },
        }),
        axios.get('https://hr-api.emas.ng/api/designation/fetchAll', {
          headers: { Authorization: `Bearer ${bearer}` },
        }),
        axios.get('https://hr-api.emas.ng/api/employees/fetchAll', {
          headers: { Authorization: `Bearer ${bearer}` },
        }),
      ]);

      setDepartments(departmentsResponse.data.data.map(item => ({
        label: item.name,
        value: item.id,
      })));

      setDesignations(designationsResponse.data.data.map(item => ({
        label: item.title,
        value: item.id,
      })));

      setEmployees(employeesResponse.data.data.map(item => ({
        label: `${item.first_name} ${item.last_name}`,
        value: item.id,
      })));
    } catch (error) {
      setError('Error fetching data');
    }
  };

  // Token and data fetch management
  useEffect(() => {
    const loadData = async () => {
      await readData();
      fetchData();
    };

    if (isOpen) {
      const timer = setTimeout(() => {
        setAnimate(true);
        loadData();
      }, 500);

      return () => clearTimeout(timer);
    } else {
      setAnimate(false);
    }
  }, [isOpen, bearer]);

  // Handle adding allowance
  const handleAddAllowance = () => {
    setAllowances([...allowances, { key: Date.now(), value: '' }]);
  };

  // Handle removing the last allowance
  const handleDeleteLastAllowance = () => {
    setAllowances(allowances.slice(0, -1));
  };

  // Handle adding deduction
  const handleAddDeduction = () => {
    setDeductions([...deductions, { key: Date.now(), value: '' }]);
  };

  // Handle removing the last deduction
  const handleDeleteLastDeduction = () => {
    setDeductions(deductions.slice(0, -1));
  };

  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setError(''); // Clear error on input change
  };

  // Handle react-select input change
  const handleSelectChange = (selectedOption, { name }) => {
    setFormData({ ...formData, [name]: selectedOption });
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payrollData = {
      employee_id: formData.employee?.value,
      department_id: formData.department?.value,
      designation_id: formData.designation?.value,
      salary: formData.salary,
      start_date: formData.startDate,
      end_date: formData.endDate,
      pay_date: formData.payDate,
      bonus: formData.bonus,
      deductions: formData.otherDeductions,
      house_allowance: formData.houseAllowance,
      transport_allowance: formData.transportAllowance,
      tax: formData.tax,
      pension: formData.pension,
      loan: formData.loan,
    };

    try {
      await axios.post('https://hr-api.emas.ng/api/payroll/create', payrollData, {
        headers: {
          Authorization: `Bearer ${bearer}`,
          'Content-Type': 'application/json',
        },
      });
      toast.success('Payroll added successfully!');
      onClose();
    } catch (error) {
      console.error('Error creating payroll:', error);
      setError('Failed to create payroll');
    }
  };

  if (!isOpen) return null;

  return (
    <div className={classes.modalOverlayWhole}>
      <div className={classes.modalErrYes}>
        <button className={classes.closeButtonModally} onClick={onClose}>
          <img src={closeX} alt="Close" />
        </button>
        <form onSubmit={handleSubmit} className={classes.modalContainer22}>
          <h6>Add Employee Salary</h6>

          {/* Employee Information Section */}
          <h5>Employee Information</h5>
          <div className={classes.classGroupCont}>
            <div className={classes.labelandInput}>
              <label>Select Employee</label>
              <Select
                name="employee"
                options={employees}
                onChange={handleSelectChange}
                value={formData.employee}
                required
              />
            </div>
            <div className={classes.labelandInput}>
              <label>Select Department</label>
              <Select
                name="department"
                options={departments}
                onChange={handleSelectChange}
                value={formData.department}
                required
              />
            </div>
            <div className={classes.labelandInput}>
              <label>Select Designation</label>
              <Select
                name="designation"
                options={designations}
                onChange={handleSelectChange}
                value={formData.designation}
                required
              />
            </div>
          </div>

          {/* Payslip Date Range Section */}
          <h5>Payslip Date Range</h5>
          <div className={classes.classGroupCont}>
            <div className={classes.labelandInput}>
              <label htmlFor="startDate">Start Date</label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                required
              />
            </div>
            <div className={classes.labelandInput}>
              <label htmlFor="endDate">End Date</label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* Earnings Section */}
          <h5>Earnings</h5>
          <div className={classes.classGroupCont}>
            <div className={classes.labelandInput}>
              <label>Enter Basic Salary</label>
              <input
                type="text"
                name="salary"
                value={formData.salary}
                onChange={handleChange}
                placeholder="500,000"
                required
              />
            </div>
            <div className={classes.labelandInput}>
              <label>House Allowance</label>
              <input
                type="text"
                name="houseAllowance"
                value={formData.houseAllowance}
                onChange={handleChange}
                placeholder="100,000"
                required
              />
            </div>
            <div className={classes.labelandInput}>
              <label>Transport Allowance</label>
              <input
                type="text"
                name="transportAllowance"
                value={formData.transportAllowance}
                onChange={handleChange}
                placeholder="80,000"
                required
              />
            </div>
            <div className={classes.labelandInput}>
              <label>Bonus</label>
              <input
                type="text"
                name="bonus"
                value={formData.bonus}
                onChange={handleChange}
                placeholder="50,000"
              />
            </div>
          </div>

          {/* Deduction Section */}
          <h5>Deductions</h5>
          <div className={classes.classGroupCont}>
            <div className={classes.labelandInput}>
              <label>Tax</label>
              <input
                type="text"
                name="tax"
                value={formData.tax}
                onChange={handleChange}
                placeholder="20,000"
                required
              />
            </div>
            <div className={classes.labelandInput}>
              <label>Pension</label>
              <input
                type="text"
                name="pension"
                value={formData.pension}
                onChange={handleChange}
                placeholder="15,000"
                required
              />
            </div>
            <div className={classes.labelandInput}>
              <label>Loan</label>
              <input
                type="text"
                name="loan"
                value={formData.loan}
                onChange={handleChange}
                placeholder="30,000"
              />
            </div>
            <div className={classes.labelandInput}>
              <label>Other Deductions</label>
              <input
                type="text"
                name="otherDeductions"
                value={formData.otherDeductions}
                onChange={handleChange}
                placeholder="10,000"
              />
            </div>
          </div>

          {/* Pay Date Section */}
          <h5>Pay Date</h5>
          <div className={classes.classGroupCont}>
            <div className={classes.labelandInput}>
              <label>Pay Date</label>
              <input
                type="date"
                name="payDate"
                value={formData.payDate}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* Error message */}
          {error && <p className={classes.error}>{error}</p>}

          <div className={classes.submitContainer}>
    <button type="submit" className={classes.saveBtn}>
        Save
    </button>
</div>

        </form>
      </div>
    </div>
  );
};

export default EditEmpSalaryModal;
