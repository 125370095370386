import React, { useState, useEffect } from "react";
import classes from "./Application.module.css";
import less from "../../Assets/less.svg";
import next from "../../Assets/next.svg";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import JobApplicationModal from "../../Components/Job Application/JobApllicationModal";
import ScreenCandidate from "../../Components/ScreenCandidate/ScreenCandidate";
import { BASE_URL } from '../api/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';

const Application = () => {
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [userId,setUserId] = useState('')


  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);


  const [isModalOpen1, setIsModalOpen1] = useState(false);

  const openModal1 = (items) => {
    setIsModalOpen1(true)
    setUserId(items?.id)
    setUserData(items)
    // console.log(items)
  };
  const closeModal1 = () => setIsModalOpen1(false);
 
  




  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('tokens');
      // console.log(value)
      if (value !== null) {
        setBearer(value);
      }
    } catch (e) {
      alert('Failed to fetch the token from storage');
    }
  };



  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };


//fetch records
const fetchBeneficiaries = async () => {
  setIsLoading(true);
  try {
    const response = await axios.get(`${BASE_URL}/job/getCandidate`, {
      params: {"status": ["submitted"]},
      headers: {
        Authorization: `Bearer ${bearer}`,
        'Content-Type': 'application/json'
      },
    }
    );
    const results = response.data?.data;
    setTableData(results);
    // console.log(results)
    // console.log(results)
    // console.log(results .leave)
    // console.log(results);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Redirect to login page if unauthorized
      navigate('/login');
    } else {
    const errorStatus = error.response?.data?.message;
    // console.log(errorStatus);
    setTableData([]);
  }
  } finally {
    setIsLoading(false);
  }
};




  useEffect(() => {

    readData();
  }, []);


  useEffect(() => {
    if (bearer) {
      fetchBeneficiaries();
    }
  }, [bearer,isModalOpen1,isModalOpen]);


  

  return (
    <>
      <TopNavBar />
      <div className={classes.toTUpper}>
      <JobApplicationModal isOpen={isModalOpen} onClose={closeModal} />
      <ScreenCandidate isOpen={isModalOpen1} onClose={closeModal1} userId={userId} userData={userData}/>
        <div className={classes.topperText}>
        <h4>Job Applications</h4>
        <div className={classes.firstBtn}>
          <button onClick={openModal} className={classes.policyBtn}>Create New Job Application</button>
          </div>
        </div>
        <div className={classes.topt}>
          <Link to={'/job_application'}><p>New Application</p></Link>
          <Link to={'/application'}><p>Application Submission</p></Link>
          <Link to={'/shortlisted_candidates'}><p>Shortlisted Candidates</p></Link>
          <Link to={'/employed_candidates'}><p>Employed Candidates</p></Link>
          <Link to={'/archives'}><p>Archives</p></Link>
          <Link to={'/analytics'}><p>Analytics</p></Link>
        </div>
        <div className={classes.topLeave}>
          <div>
            <div className={classes.topTextDiv}>
              <h4 className={classes.topText}>Application Submission</h4>
            </div>

            <div style={{ height: '1px', width: '107%', border: '1px solid #E9ECEF', marginLeft: '-39px' }}></div>

            <div className={classes.tableContainer}>
            <table className={classes.tableDiv}>
    <tr className={classes.tableHeadDiv}>
              <th className={classes.tableHeadRow}>S/N</th>
              <th className={classes.tableHeadpflDiv}>Name</th>
              <th className={classes.tableHeadpflDiv}>Email</th>
              <th className={classes.tableHeadpflDiv}>phone Number</th>
              <th className={classes.tableHeadpflDiv}>Job Type</th>
              <th className={classes.tableHeadpflDiv}>Department</th>
              {/* <th className={classes.tableHeadRow}>Start date</th> */}
              {/* <th className={classes.tableHeadRow}>End Date</th> */}
              {/* <th className={classes.tableHeadRow}>Job Type</th> */}
              {/* <th className={classes.tableHeadRow}>No. of Applicants</th> */}
              <th className={classes.tableHeadRow}>Action</th>
            </tr>
            {
              isLoading && <p>Loading...</p>
            }
            {
              !isLoading && tableData.length === 0 && <p>No Data Found</p>
            }
   
      {
        tableData.map((items,index) => (
          <tr className={classes.tableRowBody}>
               <td>{index + 1}</td>
             
             <td className={classes.pflDiv}>{`${items?.first_name} ${items?.other_name}`}</td>
             <td className={classes.pflDiv}>{items?.email}</td>
             <td className={classes.pflDiv}>{items?.phone_number}</td>
             <td className={classes.pflDiv}>{items?.job?.job_type}</td>
             <td className={classes.pflDiv}>{items?.job?.title}</td>
             
             <td className={classes.tableHeadLeft}>
             <button onClick={() => openModal1(items)} className={classes.actionsBtnsSubmission} >View Details</button>
             </td>
           
            </tr>

        ))
      }
 
   
  </table>
        </div>
        <div className={classes.gotoNext}>
            <button className={classes.prev}>
              <img src={less} alt="less" />
              Previous
            </button>
            <p className={classes.pagination}>Page 1 of 5</p>
            <button className={classes.next}>
              Next
              <img src={next} alt="next" />
            </button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Application;
