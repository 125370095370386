import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Select from 'react-select';
import closeX from './CloseX.svg';
import AsyncStorage from '@react-native-async-storage/async-storage';
import classes from './EditEmployee.module.css';

const EditEmployee = ({ isOpen, onClose, employeeId, onEmployeeUpdated }) => {
    const [loading, setLoading] = useState(false);
    const [employeeData, setEmployeeData] = useState({});
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [bearer, setBearer] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // Fetch bearer token
    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('tokens');
            if (value !== null) {
                setBearer(value);
            }
        } catch (e) {
            alert('Failed to fetch the token from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    // Fetch employee details when modal opens
    useEffect(() => {
        if (isOpen && employeeId && bearer) {
            const fetchEmployeeDetails = async () => {
                setErrorMessage('')
                try {
                    const response = await axios.get('https://hr-api.emas.ng/api/employees/fetch-employees', {
                        params: { id: employeeId },
                        headers: {
                            Authorization: `Bearer ${bearer}`,
                        },
                    });
                    setEmployeeData(response.data.data);
                    // console.log(response.data.data)
                    setSelectedDepartment(departments.find(dept => dept.value === employeeData.department_id) || null);
                } catch (error) {
                    console.error('Error fetching employee details:', error);
                    setErrorMessage('Failed to fetch employee details');
                }
            };
            fetchEmployeeDetails();
        }
    }, [isOpen, employeeId, bearer, departments]);

    useEffect(() => {
        if (departments.length > 0 && employeeData.department_id) {
            setSelectedDepartment(departments.find(dept => dept.value === employeeData.department_id) || null);
        }
    }, [departments, employeeData.department_id]);

    // Fetch departments for Select
    useEffect(() => {
        if (bearer) {
            const fetchDepartments = async () => {
                try {
                    const response = await axios.get('https://hr-api.emas.ng/api/department/fetchAll', {
                        headers: {
                            Authorization: `Bearer ${bearer}`,
                        },
                    });
                    setDepartments(response.data.data.map(item => ({
                        label: item.name,
                        value: item.id,
                    })));
                } catch (error) {
                    console.error('Error fetching departments:', error);
                    setErrorMessage('Failed to fetch departments');
                }
            };
            fetchDepartments();
        }
    }, [bearer]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setErrorMessage('')
        try {
            await axios.get('https://hr-api.emas.ng/api/employees/update', {
                params: {
                    id: employeeId,
                    first_name: employeeData.first_name,
                    last_name: employeeData.last_name,
                    email: employeeData.email,
                    phone_number: employeeData.phone_number,
                    department_id: selectedDepartment.value,
                    join_date: employeeData.join_date,
                    position: employeeData.position,
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearer}`,
                },
            });
            onEmployeeUpdated();
            onClose();
            console.log(event);
        } catch (error) {
            console.error('Error adding employee:', error);

            if (error.response) {
                let errorMessage = 'An error occurred. Please try again.';
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
                setErrorMessage(`${errorMessage}`);
            } else {
                setErrorMessage('Failed to add employee');
            }        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className={classes.modalOverlayWhole}>
            <div className={classes.modalErrYesChii}>
                <button className={classes.closeButtonModally} onClick={onClose}>
                    <img src={closeX} alt='' />
                </button>
                <div className={classes.modalContainer2}>
                    <h6>Edit Employee</h6>
                    <form onSubmit={handleSubmit}>
                        <div className={classes.classGroupCont}>
                            <div className={classes.labelandInput}>
                                <label>Edit First Name</label>
                                <input
                                    type="text"
                                    value={employeeData.first_name || ''}
                                    onChange={(e) => setEmployeeData({ ...employeeData, first_name: e.target.value })}
                                />
                            </div>
                            <div className={classes.labelandInput}>
                                <label>Edit Surname</label>
                                <input
                                    type="text"
                                    value={employeeData.last_name || ''}
                                    onChange={(e) => setEmployeeData({ ...employeeData, last_name: e.target.value })}
                                />
                            </div>
                            <div className={classes.labelandInput}>
                                <label>Edit Email</label>
                                <input
                                    type="email"
                                    value={employeeData.email || ''}
                                    onChange={(e) => setEmployeeData({ ...employeeData, email: e.target.value })}
                                />
                            </div>
                            <div className={classes.labelandInput}>
                                <label> Edit Phone Number</label>
                                <input
                                    type="text"
                                    value={employeeData.phone_number || ''}
                                    onChange={(e) => setEmployeeData({ ...employeeData, phone_number: e.target.value })}
                                />
                            </div>
                        </div>

                        <div className={classes.classGroupCont}>
                            <div className={classes.labelandInput}>
                                <label>Edit Joining Date</label>
                                <input
                                    type='date'
                                    name='join_date'
                                    value={employeeData.join_date || ''}
                                    onChange={(e) => setEmployeeData({ ...employeeData, join_date: e.target.value })}
                                />
                            </div>
                            <div className={classes.labelandInput}>
                                <label>Edit Title</label>
                                <input
                                    type='text'
                                    name='position'
                                    placeholder='Lead Accountant'
                                    value={employeeData.position || ''}
                                    onChange={(e) => setEmployeeData({ ...employeeData, position: e.target.value })}
                                />
                            </div>
                            <div className={classes.labelandInput}>
                                <label>Edit Department</label>
                                <Select
                                    options={departments}
                                    value={selectedDepartment || ''}
                                    onChange={(selectedOption) => setSelectedDepartment(selectedOption)}
                                    required
                                />
                            </div>
                        </div>

                        {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}

                        <button type="submit" className={classes.addTeamBtn} style={{ backgroundColor: loading ? '#c4c4c4' : '#2D995F' }}
                            disabled={loading}>
                            {loading ? 'Saving...' : 'Save Changes'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditEmployee;
