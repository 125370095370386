import React, { useState } from "react";
import classes from "./Archives.module.css";
import less from "../../Assets/less.svg";
import next from "../../Assets/next.svg";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import { Link } from "react-router-dom";
import eye from '../../Assets/eye.svg';
import edit from "../../Assets/edit.svg";
import deleteimg from "../../Assets/deleteimg.svg";
import OpenArchivedJob from "../../Components/OpenArchivedJob/OpenArchivedJob";
import JobApplicationModal from "../../Components/Job Application/JobApllicationModal";

const Archives = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);


  const [isModalOpen1, setIsModalOpen1] = useState(false);

  const openModal1 = () => setIsModalOpen1(true);
  const closeModal1 = () => setIsModalOpen1(false);

  return (
    <>
      <TopNavBar />
      <div className={classes.toTUpper}>
      <OpenArchivedJob isOpen={isModalOpen} onClose={closeModal} />
      <JobApplicationModal isOpen={isModalOpen1} onClose={closeModal1} />
        <div className={classes.topperText}>
        <h4>Job Applications</h4>
        <div className={classes.firstBtn}>
          <button onClick={openModal1} className={classes.policyBtn}>Create New Job Application</button>
          </div>
        </div>
        <div className={classes.topt}>
          <Link to={'/job_application'}><p>New Application</p></Link>
          <Link to={'/application'}><p>Application Submission</p></Link>
          <Link to={'/shortlisted_candidates'}><p>Shortlisted Candidates</p></Link>
          <Link to={'/employed_candidates'}><p>Employed Candidates</p></Link>
          <Link to={'/archives'}><p>Archives</p></Link>
          <Link to={'/analytics'}><p>Analytics</p></Link>
        </div>
        <div className={classes.topLeave}>
          <div>
            <div className={classes.topTextDiv}>
              <h4 className={classes.topText}>Archives</h4>
            </div>

            <div style={{ height: '1px', width: '107%', border: '1px solid #E9ECEF', marginLeft: '-39px' }}></div>

            <div className={classes.tableContainer}>
            <table className={classes.tableDiv}>
    <tr className={classes.tableHeadDiv}>
              <th className={classes.tableHeadRow}>S/N</th>
              <th className={classes.tableHeadpflDiv}>Job Title</th>
              <th className={classes.tableHeadpflDiv}>Department</th>
              <th className={classes.tableHeadRow}>Start date</th>
              <th className={classes.tableHeadRow}>End Date</th>
              <th className={classes.tableHeadRow}>Job Type</th>
              <th className={classes.tableHeadRow}>No. of Applicants</th>
              <th className={classes.tableHeadRow}>Action</th>
            </tr>
    <tr className={classes.tableRowBody}>
    <td>1</td>
              <td className={classes.pflDiv}>
              Android Developer
              </td>
              <td className={classes.pflDiv}>Mobile Development</td>
              <td>21/08/2024</td>
              <td>29/08/2024</td>
              <td>Full-Time</td>
              <td>5</td>
              <td className={classes.formEye}>
                <img onClick={openModal} src={eye} alt="approve" />
                <img  src={deleteimg} alt="approve"  />
              </td>
            </tr>
    <tr className={classes.tableRowBody}>
    <td>2</td>
              <td className={classes.pflDiv}>
              ios Developer
              </td>
              <td className={classes.pflDiv}>Mobile Development</td>
              <td>21/08/2024</td>
              <td>29/08/2024</td>
              <td>Full-Time</td>
              <td>2</td>
              <td className={classes.formEye}>
                <img onClick={openModal} src={eye} alt="approve"   />
                <img src={deleteimg} alt="approve" />
              </td>
            </tr>
    <tr className={classes.tableRowBody}>
    <td>3</td>
              <td className={classes.pflDiv}>
                Frontend Developer
              </td>
              <td className={classes.pflDiv}>Web Development</td>
              <td>21/08/2024</td>
              <td>29/08/2024</td>
              <td>Full-time</td>
              <td>11</td>
              <td className={classes.formEye}>
                <img onClick={openModal} src={eye} alt="approve"  />
                <img src={deleteimg} alt="approve" />
              </td>
            </tr>
    <tr className={classes.tableRowBody}>
    <td>4</td>
              <td className={classes.pflDiv}>
                Backend Developer
              </td>
              <td className={classes.pflDiv}>Web Development</td>
              <td>21/08/2024</td>
              <td>29/08/2024</td>
              <td>Full-time</td>
              <td>7</td>
              <td className={classes.formEye}>
                <img onClick={openModal} src={eye} alt="approve"  />
                <img src={deleteimg} alt="approve" />
              </td>
            </tr>
    <tr className={classes.tableRowBody}>
    <td>5</td>
              <td className={classes.pflDiv}>
                Finance Lawyer
              </td>
              <td className={classes.pflDiv}>Legal</td>
              <td>21/08/2024</td>
              <td>29/08/2024</td>
              <td>Full-time</td>
              <td>32</td>
              <td className={classes.formEye}>
                <img onClick={openModal} src={eye} alt="approve" />
                <img src={deleteimg} alt="approve" />
              </td>
            </tr>
    <tr className={classes.tableRowBody}>
    <td>6</td>
              <td className={classes.pflDiv}>
               Accountant
              </td>
              <td className={classes.pflDiv}>Accounting</td>
              <td>21/08/2024</td>
              <td>29/08/2024</td>
              <td>Full-time</td>
              <td>16</td>
              <td className={classes.formEye}>
                <img onClick={openModal} src={eye} alt="approve"  />
                <img src={deleteimg} alt="approve" />
              </td>
            </tr>
    <tr className={classes.tableRowBody}>
    <td>7</td>
              <td className={classes.pflDiv}>
                Receptionist
              </td>
              <td className={classes.pflDiv}>Administrative</td>
              <td>21/08/2024</td>
              <td>29/08/2024</td>
              <td>Contract</td>
              <td>87</td>
              <td className={classes.formEye}>
                <img onClick={openModal} src={eye} alt="approve" />
                <img src={deleteimg} alt="approve" />
              </td>
            </tr>
    <tr className={classes.tableRowBody}>
    <td>8</td>
              <td className={classes.pflDiv}>
               Customer Care
              </td>
              <td className={classes.pflDiv}>Customer Service</td>
              <td>21/08/2024</td>
              <td>29/08/2024</td>
              <td>Contract</td>
              <td>106</td>
              <td className={classes.formEye}>
                <img onClick={openModal} src={eye} alt="approve" />
                <img src={deleteimg} alt="approve" />
              </td>
            </tr>
  </table>
        </div>
        <div className={classes.gotoNext}>
            <button className={classes.prev}>
              <img src={less} alt="less" />
              Previous
            </button>
            <p className={classes.pagination}>Page 1 of 5</p>
            <button className={classes.next}>
              Next
              <img src={next} alt="next" />
            </button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Archives;
