import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import classes from "./EditProfile.module.css";
import back from "../../Assets/back.svg";
import employeeimg from "../../Assets/EmployeeImg.png";
import { Link } from "react-router-dom";
import TopNavBar from "../../Components/TopNavBar/TopNavBar";
import Select from 'react-select'
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import plus from '../../Assets/editingg.svg'

const EditProfile = () => {
    const customStyles = {
        control: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',    
          height: '40px',          
          width: '400px',
          border: '1px solid #2D995F',
          borderRadius: '4px',
          padding: '0',
          boxSizing: 'border-box', 
          fontSize: '14px',
          cursor: 'pointer',
          marginBottom: '20px',
        }),
        input: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          alignSelf: 'center',     
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: '0 15px',      
          height: '100%',     
        }),
        singleValue: (provided) => ({
          ...provided,
          margin: '0',            
          padding: '0',         
        }),
        placeholder: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          lineHeight: 'normal',
        }),
      };

    return (
        <div className={classes.cover_contain}>
            <TopNavBar />
            <div className={classes.covering_container}>
                <p className={classes.paraMargWeight}>Profile</p>
                <div className={classes.containerCovercontain}>

                    <div className={classes.navigateBack}>
                        <p className={classes.nameGroupType}>
                            Edit Profile
                        </p>
                    </div>

                    <div style={{ width: '100%', border: '1px solid #E9ECEF', }}></div>


                    <div className={classes.adjust_Width}>
                        <div className={classes.movetoghdiv}>
                            <p className={classes.myProfileprofile}>Organization Information</p>
                            <div className={classes.TotalContdrow}>
                                <div className={classes.TotalContdcolumn}>
                                    <div className={classes.TotalContvalue}>
                                        <label>Organization Name</label>
                                        <input type="text" placeholder="Lancaster Trust Limited" />
                                    </div>
                                    <div className={classes.TotalContvalue}>
                                        <label>Organization street address</label>
                                        <input type="text" placeholder="Lekki Phase 1" />
                                    </div>
                                    <div className={classes.TotalContvalue}>
                                        <label>Organization Name</label>
                                        <Select
                                            placeholder='Lagos'
                                            styles={customStyles}
                                        />

                                    </div>
                                </div>

                                <div className={classes.TotalContdcolumn}>
                                    <div className={classes.TotalContvalue}>
                                        <label>Organization Name</label>
                                        <Select
                                            placeholder='21 to 50 members'
                                            styles={customStyles}
                                        />
                                    </div>
                                    <div className={classes.TotalContvalue}>
                                        <label>Organization Name</label>
                                        <Select
                                            placeholder='Lagos Island'
                                            styles={customStyles}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={classes.movetoghdiv}>
                            <div className={classes.movetoghdiv}>
                                <p className={classes.myProfileprofile}>Personal Information</p>
                                <div className={classes.TotalContdrow}>
                                    <div className={classes.TotalContdcolumn}>
                                        <div className={classes.TotalContvalue}>
                                            <label>First Name</label>
                                            <input type="text" placeholder="Stephen" />
                                        </div>
                                        <div className={classes.TotalContvalue}>
                                            <label>Phone Number</label>
                                            <input type="text" placeholder="07010090002" />
                                        </div>
                                    </div>

                                    <div className={classes.TotalContdcolumn}>
                                        <div className={classes.TotalContvalue}>
                                            <label>Last Name</label>
                                            <input
                                                type="text"
                                                placeholder='Ibukun'
                                            />
                                        </div>
                                        <div className={classes.TotalContvalue}>
                                            <label>Date Of Birth</label>
                                            <input
                                                type="date"
                                                placeholder='03/05/1992'
                                                className={classes.selectDesgnDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button className={classes.BtnEditProfileBtns}>Save Changes</button>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default EditProfile;
